import React, { useEffect, useState } from 'react'

// components
import { AdminRequestsCard } from '../../components/uikit/Cards'
import AdminLateralSidebar from '../components/AdminLateralSidebar'

// packages
import { RotatingLines } from 'react-loader-spinner'

// styles
import '../styles/globalAdmin.css'

export default function AdminEmailsRequests() {
  const [emailInquiries, setEmailInquiries] = useState()
  const [filterChoice, setFilterChoice] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [menuOpen, setMenuOpen] = useState(true)
  const [queryChoice, setQueryChoice] = useState('all')

  const getEmailsRequests = async (choice) => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const query = `https://www.back.tsf.konecton.com/email-inquiries/${choice}`

      fetch(query, options)
        .then(response => response.json())
        .then(data => {
          setEmailInquiries(data)
          setIsLoading(false)
        })
    } catch (e) {
      console.error(e)
    }
  }

  const handleFilters = (choice) => {
    const emailInquiriesFiltered = [...emailInquiries]

    if (choice === 'new') {
      emailInquiriesFiltered.sort((a, b) => new Date(b.submitted_date) - new Date(a.submitted_date))
    } else if (choice === 'old') {
      emailInquiriesFiltered.sort((a, b) => new Date(a.submitted_date) - new Date(b.submitted_date))
    }

    setEmailInquiries(emailInquiriesFiltered)
  }

  useEffect(() => {
    getEmailsRequests('all')
  }, [])

  useEffect(() => {
    if (queryChoice) {
      getEmailsRequests(queryChoice)
    }
  }, [queryChoice])

  useEffect(() => {
    if (emailInquiries) {
      handleFilters(filterChoice)
    }
  }, [filterChoice])

  return (
    <div className={`AdminEmailsRequests ${menuOpen ? 'menuOpen' : 'menuClose'}`}>
      <AdminLateralSidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {isLoading ?
        <div className='tsf-loader-container'>
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{ backgroundColor: 'black' }}
            wrapperClass=""
          />
        </div>
        :
        <div className='admin-requests-container'>
          <h1>Demandes reçues par emails</h1>
          <div className='admin-requests-controllers-container'>
            <button className={`admin-requests-controllers ${queryChoice === 'all' && 'admin-requests-controllers-active'}`} onClick={() => setQueryChoice('all')}>Tous</button>
            <button className={`admin-requests-controllers ${queryChoice === 'ongoing' && 'admin-requests-controllers-active'}`} onClick={() => setQueryChoice('ongoing')}>En cours</button>
            <button className={`admin-requests-controllers ${queryChoice === 'past' && 'admin-requests-controllers-active'}`} onClick={() => setQueryChoice('past')}>Traitées</button>
            <select className='admin-requests-controllers-select' onChange={(e) => setFilterChoice(e.target.value)}>
              <option defaultChecked>Filtre dates :</option>
              <option value='new'>Du plus récent</option>
              <option value='old'>Du plus ancien</option>
            </select>
          </div>
          <div className='admin-requests-lists'>
            {emailInquiries && emailInquiries.map((email, index) => (
              <AdminRequestsCard key={index} {...email} type='emails' />
            ))}
          </div>
        </div>
      }
    </div>
  )
}
