import React, { useEffect, useRef, useState } from 'react'

// icons
import { HiOutlineMail } from 'react-icons/hi';

// packages
import { RotatingLines } from 'react-loader-spinner'
import Swal from 'sweetalert2'

// styles
import '../styles/ContactForm.css'

export default function ContactForm() {

  const form = useRef();
  const [conditionsValue, setConditionsValue] = useState(false)
  const [emailInputValue, setEmailInputValue] = useState('')
  const [firstnameInputValue, setFirstnameInputValue] = useState('')
  const [lastnameInputValue, setLastnameInputValue] = useState('')
  const [messageInputValue, setMessageInputValue] = useState('')
  const [phoneInputValue, setPhoneInputValue] = useState('')
  const [titleInputValue, setTitleInputValue] = useState('')
  const [submitionLoading, setSubmitionLoading] = useState(false)

  // TEMPORARY SUMBITION FUNCTION
  const generateMailtoLink = () => {
    const mailtoLink = `mailto:tsf.direction@gmail.com?subject=${titleInputValue}
  &from=${emailInputValue}
  &from%20${firstnameInputValue}%20${lastnameInputValue}
  &body=${firstnameInputValue} ${lastnameInputValue}
Email: ${emailInputValue}
Téléphone: ${phoneInputValue}
  
${messageInputValue}`;
    return encodeURI(mailtoLink);
  };

  const handleSendEmail = (event) => {
    event.preventDefault();
    const mailtoURL = generateMailtoLink();
    window.location.href = mailtoURL;
    confirmationMessage()
  };

  const confirmationMessage = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2 tsf-text",
        cancelButton: "btn btn-secondary mx-2 tsf-text",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: 'CONFIRMER L\'ENVOI DE VOTRE DEMANDE',
      text: 'Notre équipe vous recontactera dans les plus brefs délais',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'JE N\'AI PAS ENVOYÉ',
      confirmButtonText: `JE CONFIRME`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close()
        window.location.reload()
      } else {
        Swal.close()
      }
    })
  }
  // END TEMPORARY SUMBITION FUNCTION


  // BACKEND SUMBITION FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitionLoading(true)
    const body = {
      firstname: firstnameInputValue,
      lastname: lastnameInputValue,
      title: titleInputValue,
      phone: phoneInputValue,
      email: emailInputValue,
      message: messageInputValue,
      conditions: conditionsValue,
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    };

    const bodyNotification = {
      email: emailInputValue,
    }
    const optionsNotification = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyNotification)
    };

    fetch(`https://www.back.tsf.konecton.com/email-inquiries/new-inquiry`, options)
      .then(response => {
        response.json()
        if (response.status === 200) {
          return fetch(`https://www.back.tsf.konecton.com/email-inquiries/mail/notifications`, optionsNotification)
        } else {
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Une erreur est survenue lors de l\'envoi de votre message',
            timer: 1500
          });
          return Promise.reject('Erreur lors de l\'envoi du message')
        }
      })
      .then(response2 => {
        response2.json()
        if (response2.status === 200) {
          setFirstnameInputValue('')
          setLastnameInputValue('')
          setTitleInputValue('')
          setPhoneInputValue('')
          setEmailInputValue('')
          setMessageInputValue('')
          setConditionsValue(false)
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Votre message a bien été envoyé',
            timer: 1500
          });
        } else {
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Une erreur est survenue lors de l\'envoi de votre message',
            timer: 1500
          });
        }
      })
      .catch(error => { console.error(error) })
  }
  // END BACKEND SUMBITION FUNCTION

  return (
    <div className='ContactForm'>
      <div className='contact-form-loading-modal'>
        {submitionLoading &&
          <div className='tsf-loader-container'>
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{ backgroundColor: 'black' }}
              wrapperClass=""
            />
          </div>
        }
      </div>
      <div className='contact-form-row'>
        <div className='contact-form-input-container'>
          <input
            type='text'
            name='firstname'
            id='firstname'
            placeholder='Prénom*'
            value={firstnameInputValue}
            onChange={(e) => setFirstnameInputValue(e.target.value)}
            required
          />
        </div>
        <div className='contact-form-input-container'>
          <input
            type='text'
            name='lastname'
            id='lastname'
            placeholder='Nom*'
            value={lastnameInputValue}
            onChange={(e) => setLastnameInputValue(e.target.value)}
            required
          />
        </div>
      </div>
      <div className='contact-form-row'>
        <div className='contact-form-input-container'>
          <input
            type='text'
            name='phone'
            id='phone'
            placeholder='Numéro de téléphone*'
            value={phoneInputValue}
            onChange={(e) => setPhoneInputValue(e.target.value)}
            required
          />
        </div>
        <div className='contact-form-input-container'>
          <input
            type='text'
            name='email'
            id='email'
            placeholder='Email*'
            value={emailInputValue}
            onChange={(e) => setEmailInputValue(e.target.value)}
            required
          />
        </div>
      </div>
      {/* TEMPORARY SUBJECT */}
      <div className='contact-form-input-container-subject'>
        <input
          type='text'
          name='subject'
          id='subject'
          placeholder='Objet de votre demande*'
          value={titleInputValue}
          onChange={(e) => setTitleInputValue(e.target.value)}
          required
        />
      </div>
      {/* END TEMPORARY SUBJECT */}
      <div className='contact-form-row-message'>
        <textarea
          name='message'
          id='message'
          placeholder='Votre message*'
          value={messageInputValue}
          onChange={(e) => setMessageInputValue(e.target.value)}
          required></textarea>
      </div>
      <div className='contact-form-row-conditions'>
        <input
          type='checkbox'
          name='conditions'
          id='conditions'
          checked={conditionsValue}
          onChange={() => setConditionsValue(!conditionsValue)}
        />
        <label htmlFor='conditions'>
          En cochant cette case, vous acceptez de recevoir dans votre boîte email la newsletter de l’association Therapy Sans Frontières.
        </label>
      </div>
      <p className='contact-form-info'>Les informations figurant sur ce formulaire sont détenues par l'association Therapy Sans Frontières et ne sont transmises à aucuns tiers.
        Elles sont uniquement destinées à l'étude de votre demande pour vous répondre dans les plus brefs délais.</p>
      {/* BACKEND SUBMITION BUTTON */}
      <button onClick={(e) => handleSubmit(e)} className='contact-form-button tsf-button-shadow tsf-text'>Envoyer le message <HiOutlineMail className='contact-form-button-icon' /></button>

      {/* TEMPORARY EMAIL SUBMITION */}
      {/* <button onClick={(e) => handleSendEmail(e)} className='contact-form-button tsf-button-shadow tsf-text'>Envoyer le message <HiOutlineMail className='contact-form-button-icon' /></button> */}
    </div>
  )
}
