import React, { useEffect, useState } from 'react'

// icons
import { IoMdArrowRoundBack } from "react-icons/io";

// packages
import { Link, useParams } from 'react-router-dom'
import { RotatingLines } from 'react-loader-spinner'
import Swal from 'sweetalert2';

// styles
import '../styles/AdminCompleteProfile.css'

export default function AdminProCompleteProfile() {
  const { id } = useParams()
  const [loader, setLoader] = useState(true)
  const [proRequest, setProRequest] = useState()
  const [loadedObjectives, setLoadedObjectives] = useState([])
  const [objectivesUpdated, setObjectivesUpdated] = useState([])
  const [otherObjectiveDetail, setOtherObjectiveDetail] = useState(null)

  const getProRequest = async (proRequestId) => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`https://www.back.tsf.konecton.com/inquiries/${proRequestId}`, options)
        .then(response => response.json())
        .then(data => {
          data.forEach(d => {
            if (d.details) {
              setOtherObjectiveDetail(d.details)
            }
          })
          const formatedData = data[0]

          if (formatedData.objective_id) {
            const objectives = []
            data.forEach(element => {
              const newObjective = {
                objectiveId: element?.objective_id,
                objectiveName: element?.name,
                objectiveDetails: element?.details,
              }
              objectives.push(newObjective)
              handleObjectives(element?.objective_id.toString())
            });
            formatedData['objectives'] = objectives
          }

          delete formatedData.objective_id
          delete formatedData.booking_date
          delete formatedData.booking_time
          delete formatedData.customer_id
          delete formatedData.id
          delete formatedData.is_member
          delete formatedData.on_request
          delete formatedData.status
          delete formatedData.done_date
          delete formatedData.details
          delete formatedData.details
          delete formatedData.name
          setProRequest(formatedData)
          setLoader(false)
        })
    } catch (e) {
      console.error(e)
    }
  }

  const loadObjectives = async () => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`https://www.back.tsf.konecton.com/objectives/all`, options)
        .then(response => response.json())
        .then(data => {
          setLoadedObjectives(data)
        })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getProRequest(id)
    loadObjectives()
  }, [id])

  const handleChanges = (key, value) => {
    setProRequest(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const handleObjectives = (objective) => {
    const isSelected = objectivesUpdated.includes(objective);

    if (isSelected) {
      setObjectivesUpdated(objectivesUpdated.filter((item) => item !== objective));
    } else {
      setObjectivesUpdated([...objectivesUpdated, objective]);
    }
  }

  const submitCompletedProfile = (id) => {
    proRequest['status'] = 1
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(proRequest)
    };

    fetch(`https://www.back.tsf.konecton.com/inquiries/complete-profile/${id}`, options)
      .then(response => response.json())
      .catch(error => console.error(error));
  }

  const submitCompleteObjectives = (id) => {
    const body = {
      objectives: objectivesUpdated,
      details: proRequest['other_objective']
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    };

    fetch(`https://www.back.tsf.konecton.com/pro-objectives/update/${id}`, options)
      .then(response => response.json())
      .then(data => {
        Swal.fire({
          icon: 'success',
          title: 'Profil mis à jour',
          timer: 1500
        });
        window.location.reload()
      })
      .catch(error => console.error(error));
  }

  const submitProfile = () => {
    submitCompletedProfile(id)
    submitCompleteObjectives(id)
  }

  return (
    <div className='AdminCompleteProfile'>
      <Link to={`/tsf-admin/pro`} className='admin-return-button'><IoMdArrowRoundBack /></Link>
      <h1>Compléter le profil lors du rendez-vous</h1>
      {loader ?
        <div className='tsf-loader-container'>
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{ backgroundColor: 'black' }}
            wrapperClass=""
          />
        </div>
        :
        <div className='admin-complete-profile-container'>

          {proRequest && Object.entries(proRequest).map((oKey, index) => (
            oKey[0] !== 'objectives' ?
              <div className='admin-complete-profile-detail-row'>
                <label htmlFor={oKey[0]}>{oKey[0]} :</label>
                {oKey[0] === 'message' ?
                  <textarea id={oKey[0]} className='admin-complete-profile-detail-textarea' onChange={(e) => handleChanges(oKey[0], e.target.value)} value={proRequest[oKey[0]] === null ? undefined : proRequest[oKey[0]]} />
                  :
                  oKey[0] === 'is_interested_subscription' ?
                    <p className='admin-complete-profile-detail-try'>{proRequest[oKey[0]] ? 'Intéressé.e par un essai gratuit' : 'Pas intéressé.e par un essai gratuit'}</p>
                    :
                    <input id={oKey[0]} className='admin-complete-profile-detail-input' onChange={(e) => handleChanges(oKey[0], e.target.value)} value={proRequest[oKey[0]] === null ? undefined : proRequest[oKey[0]]} />
                }
              </div>
              :
              null
          ))}
          <div className='admin-complete-profile-detail-row'>
            <div className='admin-complete-profile-all-objectives-container'>
              <p className='admin-complete-profile-all-objectives-title'>Liste des objectifs :</p>
              {loadedObjectives.length > 0 && loadedObjectives.map((o, index) => (
                <>
                  <div onClick={() => handleObjectives((o.id).toString())} className={`admin-complete-profile-objectives-input ${(objectivesUpdated.includes((o.id).toString())) && 'admin-complete-profile-active-objective'}`} key={index}>
                    {o.name}
                  </div>
                  {(objectivesUpdated.includes('7') && o.id === 7) &&
                    <>
                      <label htmlFor='otherObjectiveInput'>Précision du projet :</label>
                      <textarea id='otherObjectiveInput' className='admin-complete-profile-objectives-other' onChange={(e) => handleChanges('other_objective', e.target.value)} value={!proRequest['otherObjective'] ? otherObjectiveDetail !== null ? otherObjectiveDetail : undefined : proRequest['otherObjective']} />
                    </>
                  }
                </>
              ))}
            </div>

            <div className='admin-complete-profile-detail-row-note'>
              <label htmlFor='note'>Ajouter une note :</label>
              <textarea id='note' className='admin-complete-profile-detail-textarea' onChange={(e) => handleChanges('note', e.target.value)} value={proRequest['note'] === null ? undefined : proRequest['note']} />
            </div>
            <button className='admin-complete-profile-submit tsf-button-shadow' onClick={() => submitProfile()}>Enregistrer</button>
          </div>
        </div>
      }
    </div >
  )
}
