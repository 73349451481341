export const partnersList = [
  {
    id: 1,
    link: 'https://www.centraider.org/',
    image: require('../assets/images/partners/centraider-logo.jpg'),
    name: 'Centraider',
  },
  {
    id: 2,
    link: 'https://www.facebook.com/Naya37Association',
    image: require('../assets/images/partners/naya-logo.jpg'),
    name: 'Association Naya',
  },
  {
    id: 3,
    link: 'https://www.legba-asp.fr/',
    image: require('../assets/images/partners/legba-logo.png'),
    name: 'Legba A.S.P.',
  },
  // {
  //   id: 4,
  //   link: 'https://www.planning-familial.org/fr/le-planning-familial-dindre-et-loire-37',
  //   image: require('../assets/images/partners/planning-familial-37-logo.jpg'),
  //   name: 'Planning Familliale 37',
  // },
  {
    id: 5,
    link: 'https://www.helloasso.com/associations/association-nouveau-souffle-pour-l-insertion-sociale-et-professionnelle',
    image: require('../assets/images/partners/ansisp-logo.jpg'),
    name: 'ANSISP',
  },
  {
    id: 6,
    link: 'https://www.helloasso.com/associations/handistea',
    image: require('../assets/images/partners/handistea-logo.jpg'),
    name: 'Handistea',
  }
]