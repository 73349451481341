import React, { useEffect, useState } from 'react'

// components
import { NewsTSFCard } from '../uikit/Cards'

// packages
import { RotatingLines } from 'react-loader-spinner'

// styles
import '../../styles/home/HomeNews.css'

export default function HomeNews() {
  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState([])

  const getNews = async () => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      };

      const query = `https://www.back.tsf.konecton.com/news-tsf/all/limited`

      fetch(query, options)
        .then(response => response.json())
        .then(data => {
          if (data && data.length > 0) {
            setNews(data)
          }
          setIsLoading(false)
        })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getNews()
  }, [])

  return (
    <div className='tsf-home-page-wb HomeNews' id='HomeNews'>
      <div className='tsf-home-page-content home-news-container'>
        <h2>Nos actualités :</h2>
        {isLoading && news.length == 0 ?
          <div className='tsf-loader-container'>
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{ backgroundColor: 'black' }}
              wrapperClass=""
            />
          </div>
          :
          <div className='home-news-content'>
            {news.length > 0 && news.map(n => <NewsTSFCard {...n} isAdmin={false} key={n.id} />)}
          </div>
        }
        <a href='/news'>
          <button className='home-news-button tsf-button-shadow tsf-text' onClick={() => null}>Voir plus</button>
        </a>
      </div>
    </div>
  )
}
