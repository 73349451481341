import React, { useEffect, useState } from 'react'

// assets
import logo_tsf_benevolent from '../../assets/images/TSF/TSF-Texte-Logo-Benevole.png'
import logo_tsf_pro from '../../assets/images/TSF/TSF_Texte_Logo_Pro_transparent.png'
import thank_you_logo from '../../assets/images/TSF/TSF_thank_you_logo.gif'

// components
import Footer from '../../components/Footer'

// icons
import { ImInfo } from "react-icons/im";
import { IoMdArrowRoundBack, IoMdArrowRoundForward, IoMdCheckmark } from "react-icons/io";

// packages
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link, useParams } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner'
import Swal from 'sweetalert2'

// styles
import '../../styles/app/AppForm.css'

// utils
import { FormProgressbarList } from '../../utils/FormProgressbar';

export default function AppForm() {
  // TODO : Email and phonenumber validation

  const { role } = useParams()
  const { getDay, intlFormat } = require("date-fns");

  // FORM INPUTS STATES
  const [userAlreadyMember, setUserAlreadyMember] = useState()
  const [userDateChoice, setUserDateChoice] = useState()
  const [userDateTimeChoice, setUserDateTimeChoice] = useState()
  const [userEmail, setUserEmail] = useState()
  const [userFirstname, setUserFirstname] = useState()
  const [userHasSubscription, setUserHasSubscription] = useState()
  const [userLastname, setUserLastname] = useState()
  const [userMessage, setUserMessage] = useState()
  const [userObjectives, setUserObjectives] = useState([])
  const [userOccupation, setUserOccupation] = useState()
  const [userPhoneNumber, setUserPhoneNumber] = useState()
  const [userSubscription, setUserSubscription] = useState()
  const [userSubscriptionInterested, setUserSubscriptionInterested] = useState()

  // GENERAL STATES
  const [conditionsValue, setConditionsValue] = useState(false)
  const [currentFormPage, setCurrentFormPage] = useState(1)
  const [incorrectDate, setIncorrectDate] = useState(false)
  const [incorrectDateTime, setIncorrectDateTime] = useState(false)
  const [loadedObjectives, setLoadedObjectives] = useState([])
  const [missingFields, setMissingFields] = useState(false)
  const [submitionLoading, setSubmitionLoading] = useState(false)
  // const [emailValidation, setEmailValidation] = useState(false)
  // const [phoneNumberValidation, setPhoneNumberlValidation] = useState(false)

  const handlePage = (action) => {
    const nextPage = handleRequiredPage()
    if (action === "-") {
      if (currentFormPage > 1) {
        setCurrentFormPage((prev) => prev - 1)
      }
    } else {
      if (nextPage) {
        setMissingFields(false)
        setCurrentFormPage((prev) => prev + 1)
      } else {
        setMissingFields(true)
      }
    }
  }

  //////////////
  // TODO EMAIL AND PHONENUMBER FORMAT CHECKING
  // const emailChecking = () => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   const result = emailRegex.test(userEmail);
  //   if (result) {
  //     setEmailValidation(true)
  //   } else {
  //     setEmailValidation(false)
  //   }
  //   return result
  // }

  // const phoneNumberChecking = () => {
  //   const phoneRegex = /^0[0-9]{9,14}$/;
  //   const result = phoneRegex.test(userPhoneNumber)
  //   if (result) {
  //     setEmailValidation(true)
  //   } else {
  //     setEmailValidation(false)
  //   }
  //   return result
  // };
  /////////////////

  const loadObjectives = async () => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`https://www.back.tsf.konecton.com/objectives/all`, options)
        .then(response => response.json())
        .then(data => {
          setLoadedObjectives(data)
        })
    } catch (e) {
      console.error(e)
    }
  }

  const handleRequiredPage = () => {
    let filled = false
    if (currentFormPage === 1) {
      filled = true
    } else if (currentFormPage === 2) {
      filled = userObjectives.length > 0 && userMessage
      // filled = userMessage
    } else if (currentFormPage === 3) {
      filled = userDateChoice && userDateTimeChoice && userFirstname && userLastname && userEmail && userPhoneNumber && userOccupation
    } else if (currentFormPage === 4) {
      filled = userAlreadyMember !== undefined && userHasSubscription !== undefined
    }
    return filled
  }

  const handleObjectives = (objective) => {
    const isSelected = userObjectives.includes(objective);

    // Remove the selected objective if already selected
    if (isSelected) {
      setUserObjectives(userObjectives.filter((item) => item !== objective));
    } else {
      setUserObjectives([...userObjectives, objective]);
    }
  }

  const handleDates = (date) => {
    if (getDay(date) == 1 || getDay(date) == 3 || getDay(date) == 5) {
      setIncorrectDate(false)
      setUserDateChoice(date)
    } else {
      setIncorrectDate(true)
    }
  }

  const handleDateTime = (time) => {
    if (getDay(userDateChoice) == 3) {
      if (time === 'après-midi') {
        setIncorrectDateTime(true)
      } else {
        setIncorrectDateTime(false)
        setUserDateTimeChoice(time)
      }
    } else {
      setIncorrectDateTime(false)
      setUserDateTimeChoice(time)
    }
  }


  // BACKEND SUMBITION FUNCTION
  const handleSubmit = async () => {
    setSubmitionLoading(true)

    const body = {
      firstname: userFirstname,
      lastname: userLastname,
      email: userEmail,
      phone_number: userPhoneNumber,
      booking_date: userDateChoice,
      booking_time: userDateTimeChoice,
      message: userMessage,
      occupation: userOccupation,
      objectives: userObjectives,
      is_member: userAlreadyMember,
      subscription: userSubscription ? userSubscription : '',
      is_interested_subscription: userSubscriptionInterested,
      category: role === 'pro' ? 'pro' : 'benevolent'
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    };

    const bodyNotification = {
      email: userEmail,
    }
    const optionsNotification = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyNotification)
    };

    console.log('options', options)
    console.log('optionsNotification', optionsNotification)
    fetch(`https://www.back.tsf.konecton.com/inquiries/new-inquiry`, options)
      .then(response => {
        response.json()
        console.log('response', response)
        if (response.status === 200 || response.status === 201) {
          return fetch(`https://www.back.tsf.konecton.com/inquiries/mail/notifications/${role}`, optionsNotification)
        } else {
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Une erreur est survenue lors de l\'envoi de votre demande',
            timer: 1500
          });
          return Promise.reject('Erreur lors de l\'envoi de la demande')
        }
      })
      .then(response2 => {
        response2.json()
        if (response2.status === 200) {
          setCurrentFormPage((prev) => prev + 1)
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Votre demande a bien été envoyée',
            timer: 1500
          });
        } else {
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Une erreur est survenue lors de l\'envoi de votre demande',
            timer: 1500
          });
        }
      })
      .catch(error => console.error(error));
  }


  // TEMPORARY SUMBITION FUNCTION
  //   const generateMailtoLink = () => {
  //     const mailtoLink = `mailto:tsf.direction@gmail.com?subject=Demande de ${role === 'pro' ? 'professionnel' : 'bénévole'}
  //   &from=${userEmail}
  //   &from%20${userFirstname}%20${userLastname}
  //   &body=${userFirstname} ${userLastname}
  // Email: ${userEmail}
  // Téléphone: ${userPhoneNumber}
  // Profession: ${userOccupation}
  // Date de prise de contact souhaitée: le ${intlFormat(new Date(userDateChoice))} (${userDateTimeChoice})
  // Adhérent TSF: ${userAlreadyMember ? 'Oui' : 'Non'}
  // Forfait: ${userHasSubscription ? `Oui - ${userSubscription}` : `Non`}
  // Intéressé par un essai gratuit: ${userSubscriptionInterested ? 'Oui' : 'Non'}

  // ${userMessage}`;
  //     return encodeURI(mailtoLink);
  //   };

  //   const handleSendEmail = (event) => {
  //     event.preventDefault();
  //     const mailtoURL = generateMailtoLink();
  //     window.location.href = mailtoURL;
  //     confirmationMessage()
  //   };
  // END TEMPORARY SUMBITION FUNCTION


  const confirmationMessage = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2 tsf-text",
        cancelButton: "btn btn-secondary mx-2 tsf-text",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: 'CONFIRMER L\'ENVOI DE VOTRE DEMANDE',
      text: 'Notre équipe vous recontactera dans les plus brefs délais',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'JE N\'AI PAS ENVOYÉ',
      confirmButtonText: `JE CONFIRME`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setCurrentFormPage((prev) => prev + 1)
      } else {
        Swal.close()
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    loadObjectives()
  }, []);

  return (
    <div className='AppForm global-app-form'>
      <div className='app-form-header'>
        <Link className='app-form-header-return-btn' to='/app'>
          <IoMdArrowRoundBack />
          <span className='app-form-header-return-btn-text'>retour</span>
        </Link>
        <h2>
          {role === 'pro' ?
            <>
              <img src={logo_tsf_pro} alt='Thérapy Sans Frontières Pro' width={100} />
              Formulaire pour les professionnels de terrain
            </>
            :
            <>
              <img src={logo_tsf_benevolent} alt='Thérapy Sans Frontières Bénévole' width={100} />
              Formulaire pour les bénévoles
            </>
          }
        </h2>
      </div>
      <div className='app-form-container tsf-content-container'>
        {currentFormPage < 6 && <div className='app-form-top-page-indicator tsf-text'>
          {currentFormPage}/5
        </div>}
        {submitionLoading &&
          <div className='tsf-loader-container'>
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{ backgroundColor: 'black' }}
              wrapperClass=""
            />
          </div>
        }

        {/* PAGE 1 : PRESENTATION TSF */}
        {currentFormPage === 1 &&
          <div className='app-form-global app-form-1'>
            <h3>Présentation TSF</h3>
            <p><b>Therapy Sans Frontières</b> est une association (loi 1901), créée en 2021.<br /> L’association est née d’une volonté de promouvoir <b>l’accès à la santé mentale et de lutter pour une meilleure justice sociale.</b></p>
            <p>Nous nous engageons à défendre les droits humains et militons contre toutes les formes de violences et de discriminations.</p>
            <p>Les actions menées ont pour objectif de promouvoir, de sensibiliser, de former et de développer des projets qui portent sur la prévention, le diagnostic situationnel et personnel, le traitement et la prise en charge de personnes en détresse psychique et/ou sociale : celles-ci prennent en compte les besoins et les attentes du public vulnérable*.</p>
            <p className='app-form-presentation-info'>*TSF a développé un outil de diagnostic interne afin de définir des seuils de vulnérabilité</p>
          </div>
        }

        {/* PAGE 2 : VOS BESOINS / VOS DEMANDES | objectifs ; details de la demande */}
        {currentFormPage === 2 &&
          <div className='app-form-global app-form-2'>
            <h3>Vos besoins / vos demandes</h3>
            <Form>
              {/* BACKEND LOADED OBJECTIVES */}
              <div className='all-objectives-container'>
                {loadedObjectives.length > 0 && loadedObjectives.map((o, index) => (
                  <div onClick={() => handleObjectives((o.id).toString())} className={`objectives-input tsf-text ${userObjectives.includes((o.id).toString()) && 'active-objective'}`} key={index}>
                    {o.name}{o.on_request === 1 && '*'}
                  </div>
                ))}
              </div>
              <p className='objectives_on_request_info'>* Sur devis, programme sur mesure</p>
              {(missingFields && userObjectives.length === 0) && <Form.Text className="text-danger">
                Champ manquant requis
              </Form.Text>}
              <Form.Group className="mb-3" controlId="formMessage">
                {/* <Form.Label>Précisez vos besoins :</Form.Label> */}
                <Form.Label>Parlez nous de vos besoins :</Form.Label>
                <Form.Control as="textarea" rows={5} placeholder="Votre demande" name="message" className={`${missingFields && !userMessage ? 'input-wrong' : 'input-init'} form-input`} value={userMessage} onChange={(e) => setUserMessage(e.target.value)} />
                {(missingFields && !userMessage) && <Form.Text className="text-danger">
                  Message requis
                </Form.Text>}
              </Form.Group>
            </Form>
          </div>
        }

        {/*  PAGE 3 : PRISE DE RENDEZ-VOUS | Date de rdv ; créneau ; nom ; prenom ; email ; téléphone ; profession */}
        {currentFormPage === 3 &&
          <div className='app-form-global app-form-3'>
            <h3>Prise de rendez-vous</h3>
            <Form className='app-form-container'>
              <div className='app-profile-input-row'>
                <Form.Group className="app-profile-input-group" controlId="formDate">
                  <Form.Label>
                    <p>Choisissez une date :</p>
                  </Form.Label>
                  <input type="date"
                    className={`${!incorrectDate ? 'app-profile-date-input' : 'app-profile-date-input-wrong'}`}
                    onChange={(e) => handleDates(e.target.value)}
                  // value={userDateChoice && userDateChoice}
                  />
                  <p className={`app-profile-date-info ${!incorrectDate ? 'app-profile-date-info-correct' : 'app-profile-date-info-wrong'}`}><ImInfo /> Jours disponibles: lundi, mercredi (matin) et vendredi</p>
                  {(missingFields && !userDateChoice) && <Form.Text className="text-danger">
                    Date requise
                  </Form.Text>}
                </Form.Group>
                <Form.Group className="app-profile-input-group" controlId="formDateTimeContainer">
                  <Form.Label>
                    <p>Choisissez un créneau : </p>
                  </Form.Label>
                  <select id="formDateTime" className="form-select" aria-label="Default select example" value={userDateTimeChoice} onChange={(e) => handleDateTime(e.target.value)}>
                    <option defaultChecked value="">Votre créneau :</option>
                    <option value="matin">Matin</option>
                    <option value="après-midi">Après-midi</option>
                  </select>
                  {incorrectDateTime && <p className={`app-profile-date-time-info`}><ImInfo /> Seul le mercredi matin est possible</p>}
                  {(missingFields && !userDateTimeChoice) && <Form.Text className="text-danger">
                    Créneau requis
                  </Form.Text>}
                </Form.Group>
              </div>
              <div className='app-profile-input-row'>
                <Form.Group className="app-profile-input-group" controlId="formFirstname">
                  <Form.Label>Votre prénom :
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: Emma" className={`${missingFields && !userFirstname ? 'input-wrong' : 'input-init'} form-input`} value={userFirstname ? userFirstname : ""} onChange={(e) => setUserFirstname(e.target.value)} />
                  {(missingFields && !userFirstname) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
                <Form.Group className="app-profile-input-group" controlId="formLastname">
                  <Form.Label>Votre nom :
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: Dupont" className={`${missingFields && !userLastname ? 'input-wrong' : 'input-init'} form-input`} value={userLastname ? userLastname : ""} onChange={(e) => setUserLastname(e.target.value)} />
                  {(missingFields && !userLastname) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
              </div>
              <div className='app-profile-input-row'>
                <Form.Group className="app-profile-input-group" controlId="formEmail">
                  <Form.Label>Votre email :
                  </Form.Label>
                  <Form.Control type="email" placeholder="ex: emma.dupont@gmail.com" className={`${missingFields && !userEmail ? 'input-wrong' : 'input-init'} form-input`} value={userEmail ? userEmail : ""} onChange={(e) => setUserEmail(e.target.value)} />
                  {(missingFields && !userEmail) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
                <Form.Group className="mb-3 app-profile-input-group" controlId="formPhone">
                  <Form.Label>Votre téléphone :
                  </Form.Label>
                  <Form.Control type="number" placeholder="ex: 0601020304" className={`${missingFields && !userPhoneNumber ? 'input-wrong' : 'input-init'} form-input`} value={userPhoneNumber ? userPhoneNumber : ""} onChange={(e) => setUserPhoneNumber(e.target.value)} />
                  {(missingFields && !userPhoneNumber) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
              </div>
              <div className='app-profile-input-row'>
                <Form.Group className="mb-3 app-profile-input-group-full" controlId="formProfession">
                  <Form.Label>Votre profession
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: Médiateur" className={`${missingFields && !userOccupation ? 'input-wrong' : 'input-init'} form-input`} value={userOccupation ? userOccupation : ""} onChange={(e) => setUserOccupation(e.target.value)} />
                  {(missingFields && !userOccupation) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
              </div>
            </Form>
            <p className='recall-text-info tsf-text'>L'équipe de TSF vous recontactera dans les plus brefs délais</p>
          </div>
        }

        {/*  PAGE 4 : POUR FACILIER NOS ECHANGES | Déjà eu recours ? ; identifiant ; disposez vous d'un forfait ? ; forfait */}
        {currentFormPage === 4 &&
          <div className='app-form-global app-form-4'>
            <h3>Pour faciliter nos échanges</h3>
            <Form className='app-form-4-container'>
              <Form.Group className="mb-3" controlId="formAlreadyMember">
                <Form.Label>Êtes-vous adhérents à TSF ?
                </Form.Label>
                <div className='app-form-4-btns'>
                  <div onClick={() => setUserAlreadyMember(true)} className={`exchange-button tsf-text ${userAlreadyMember && 'exchange-button-active'}`}>Oui</div>
                  <div onClick={() => setUserAlreadyMember(false)} className={`exchange-button tsf-text ${userAlreadyMember === false && 'exchange-button-active'}`}>Non</div>
                </div>
                {(missingFields && userAlreadyMember === undefined) && <Form.Text className="text-danger">
                  Champ manquant requis
                </Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSubscriptions">
                <Form.Label>Disposez-vous d'un forfait ?
                </Form.Label>
                <div className='app-form-4-btns'>
                  <div onClick={() => setUserHasSubscription(true)} className={`exchange-button tsf-text ${userHasSubscription && 'exchange-button-active'}`}>Oui</div>
                  <div onClick={() => setUserHasSubscription(false)} className={`exchange-button tsf-text ${userHasSubscription === false && 'exchange-button-active'}`}>Non</div>
                </div>
                {(missingFields && userHasSubscription === undefined) && <Form.Text className="text-danger">
                  Champ manquant requis
                </Form.Text>}
              </Form.Group>
              {userHasSubscription ?
                <>
                  <select id="formSubscription" className="form-select app-form-select" aria-label="Default select example" value={userSubscription} onChange={(e) => setUserSubscription(e.target.value)}>
                    <option defaultChecked value="">Sélectionnez votre forfait :</option>
                    <option value="partenaire">Partenaire engagé</option>
                    <option value="mecene">Grand mécène</option>
                  </select>
                  {(missingFields && userSubscription === undefined) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </>
                :
                userHasSubscription === false ?
                  <>
                    <Form.Group className="mb-3 app-interested-container" controlId="formIsInterested">
                      <Form.Label>Êtes-vous intéressé par un forfait d'essai ?
                      </Form.Label>
                      <div className='app-form-4-btns'>
                        <div onClick={() => setUserSubscriptionInterested(true)} className={`exchange-button tsf-text ${userSubscriptionInterested && 'exchange-button-active'}`}>Oui</div>
                        <div onClick={() => setUserSubscriptionInterested(false)} className={`exchange-button tsf-text ${userSubscriptionInterested === false && 'exchange-button-active'}`}>Non</div>
                      </div>
                    </Form.Group>
                    <p className='app-form-4-interested'><ImInfo /> Vous êtes intéressé par un forfait d'essai ?<br />La démarche est <b>gratuite</b>, elle est ponctuelle ou mensuelle, afin de <b>simplifier l'accès à nos services.</b></p>
                    {(missingFields && userSubscriptionInterested === undefined) && <Form.Text className="text-danger">
                      Champ manquant requis
                    </Form.Text>}
                  </>
                  :
                  null
              }
            </Form>
          </div>
        }

        {/*  PAGE 5 : CONFIRMATION DE VOTRE DEMANDE | Déjà eu recours ? ; identifiant ; disposez vous d'un forfait ? ; forfait */}
        {currentFormPage === 5 &&
          <div className='app-form-global app-form-6'>
            <h4>Confirmer votre demande :</h4>
            <p>Nous vous recontacterons par téléphone dans les plus brefs délais</p>
            <table className='app-form-6-table'>
              <tbody>
                <tr>
                  <td>{userLastname}</td>
                  <td>{userFirstname}</td>
                </tr>
                <tr>
                  <td>{userEmail}</td>
                  <td>{userPhoneNumber}</td>
                </tr>
                <tr>
                  <td>{userOccupation}</td>
                </tr>
                <tr>
                  <td><i>Vos objectifs :</i><br />
                    <ul>
                      {userObjectives.map((objectif, index) =>
                        <li key={index}>{loadedObjectives[objectif - 1].name}</li>
                      )}
                    </ul>
                  </td>
                </tr>
                {/* <tr>
              </tr> */}
                <tr>
                  <td><i>Date du rendez-vous :</i><br />le {intlFormat(new Date(userDateChoice))}</td>
                  <td><i>Créneau choisi :</i><br /> {userDateTimeChoice}</td>
                </tr>
                <tr>
                  <td>{userMessage}</td>
                </tr>
                <tr>
                  <td>{userAlreadyMember ? 'Vous êtes adhérent' : 'Vous n\'êtes pas adhérent'}</td>
                  <td>{userHasSubscription ? `Vous avez un forfait de ${userSubscription}` : `Vous n'avez pas de forfait`}</td>
                </tr>
                {!userHasSubscription &&
                  <tr>
                    <td>{!userHasSubscription && userSubscriptionInterested ? `Vous êtes intéressé pour un essai gratuit` : `Vous n'êtes pas intéressé pour un essai gratuit`}</td>
                  </tr>
                }
              </tbody>
            </table>
            <div className='conditions-app-form'>
              <input
                type='checkbox'
                name='conditions'
                id='conditions'
                checked={conditionsValue}
                onChange={() => setConditionsValue(!conditionsValue)}
              />
              <label htmlFor='conditions'>
                En cochant cette case, vous acceptez que l’association Therapy Sans Frontières utilisent et conversent uniquement pour elle les informations figurant sur ce formulaire afin de répondre à votre demande dans les plus brefs délais. Vos informations ne sont en aucun cas transmises à des tiers.
              </label>
            </div>
          </div>
        }

        {/*  PAGE 6 : REMERCIEMENT | Confirmation, image, bouton de retour */}
        {currentFormPage === 6 &&
          <div className='app-form-global app-form-7'>
            <p>Un email de confirmation vous a été envoyé <IoMdCheckmark /></p>
            <div className='app-form-thankyou'>
              <img src={thank_you_logo} alt='thank you' />
            </div>
            <h5>Notre équipe vous recontactera par téléphone dans les plus brefs délais.</h5>
            <Link to='/'><div className='app-form-thankyou-btn tsf-button-shadow tsf-text'><IoMdArrowRoundBack className='app-form-thankyou-btn-icon' /> Retour au site</div></Link>
          </div>
        }
        {currentFormPage < 6 &&
          <>
            <div className='buttons-container'>
              <Button className='tsf-text' onClick={() => handlePage("-")} disabled={currentFormPage === 1}><IoMdArrowRoundBack /> Précédent</Button>
              {currentFormPage < 5 ?
                <Button className='tsf-text' onClick={() => handlePage("+")}>Suivant <IoMdArrowRoundForward /></Button>
                :
                currentFormPage === 5 ?
                  <Button disabled={!conditionsValue} className={`${conditionsValue ? 'btn-success' : 'btn-light'} tsf-text`} onClick={() => handleSubmit()}>Confirmer {conditionsValue && <IoMdCheckmark />}</Button>
                  // <Button disabled={!conditionsValue} className={`${conditionsValue ? 'btn-success' : 'btn-light'} tsf-text`} onClick={(e) => handleSendEmail(e)}>Confirmer {conditionsValue && <IoMdCheckmark />}</Button>
                  :
                  null
              }
            </div>
            <div className='progressbar-bg'>
              <div className={`progressbar progressbar-${currentFormPage}`}></div>
            </div>
            <div className='progressbar-bg-info-container tsf-text'>
              <p className='progressbar-bg-info'>{currentFormPage}/5 - {FormProgressbarList[currentFormPage - 1].text}</p>
            </div>
          </>
        }
      </div>
      <Footer />
    </div>
  )
}
