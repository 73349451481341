import { useEffect, useState } from 'react';

// assets
import app_logo from '../assets/images/TSF/TSF-menu-app-no-border-crop.png'
import logo from '../assets/images/TSF/logo.png'
import mobile_logo from '../assets/images/TSF/TSF_mobile_logo.png'

// icons
import { FaBars, FaFacebookSquare, FaHandHoldingHeart, FaInstagramSquare, FaLinkedin, FaPhoneAlt } from 'react-icons/fa';
import { IoIosClose, IoMdArrowDropdown, IoMdArrowDropup, IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { IoMail } from 'react-icons/io5';

// packages
import { Link, NavLink, useLocation } from 'react-router-dom';

// styles
import '../styles/Menu.css'

export default function Menu() {

  const { hash } = useLocation();

  const [menuMobileActive, setMenuMobileActive] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(null);
  const [showSecondSubMenu, setShowSecondSubMenu] = useState(null);

  const handleSubMenuToggle = (menuItem) => {
    setShowSubMenu(showSubMenu === menuItem ? null : menuItem);
  }

  const handleAllSubMenusClose = () => {
    setShowSubMenu('');
    setShowSecondSubMenu('');
  }

  const handleCloseAllMobile = () => {
    setMenuMobileActive(false);
    handleAllSubMenusClose();
  }

  const handleSecondSubMenuToggle = (menuItem) => {
    setShowSecondSubMenu(showSecondSubMenu === menuItem ? null : menuItem);
  }


  useEffect(() => {
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className='Menu'>

      {/***** DESKTOP MENU */}
      <div className='desktop-menu'>
        <div className='emergency-container'>
          <FaPhoneAlt className='emergency-icon' />
          <div className='emergency-numbers-container'>
            <p><b>En cas d'urgence : APPELEZ le 112</b></p>
          </div>
        </div>

        <div className='desktop-menu-container'>
          <Link className='desktop-menu-logo-container' to='/#top'>
            <img
              src={logo}
              className='desktop-menu-logo'
              alt='Therapy Sans Frontières logo'
            />
          </Link>

          <div className='desktop-menu-items'>

            {/***** QUI SOMMES NOUS ?  */}
            <div
              className={`desktop-menu-item ${showSubMenu === 'aboutUs' && 'active'}`}
              onMouseEnter={() => handleSubMenuToggle('aboutUs')}
              onMouseLeave={() => handleSubMenuToggle('')}
            >

              <Link to='/#HomePresentation' className='tsf-text desktop-menu-item-title'>Qui sommes nous ?<IoMdArrowDropdown /></Link>
              {showSubMenu === 'aboutUs' && (
                <div className='desktop-sub-menu'>
                  <Link to='/our-story' className='tsf-text desktop-sub-menu-item'>Notre histoire</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/our-values' className='tsf-text desktop-sub-menu-item'>Nos valeurs</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/#HomePresentation' className='tsf-text desktop-sub-menu-item'>Présentation</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/our-engagments' className='tsf-text desktop-sub-menu-item'>Nos engagements</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/members' className='tsf-text desktop-sub-menu-item'>Notre fonctionnement</Link>
                </div>
              )}
            </div>

            {/***** NOS SERVICES  */}
            <div
              className={`desktop-menu-item ${showSubMenu === 'ourServices' && 'active'}`}
              onMouseEnter={() => handleSubMenuToggle('ourServices')}
              onMouseLeave={() => handleSubMenuToggle('')}
            >
              <Link to='/our-services' className='tsf-text desktop-menu-item-title'>Qui accompagnons nous ?<IoMdArrowDropdown /></Link>
              {showSubMenu === 'ourServices' && (
                <div className='desktop-sub-menu'>
                  <Link to='/our-services/concerned-people' className='tsf-text desktop-sub-menu-item'>Personnes concernées</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/our-services/victimology' className='tsf-text desktop-sub-menu-item'>Service victimologie</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/our-services/criminology' className='tsf-text desktop-sub-menu-item'>Service criminologie</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/our-services/laws' className='tsf-text desktop-sub-menu-item'>Service juridique</Link>
                </div>
              )}
            </div>

            {/***** NOS ACTIONS  */}
            <div
              className={`desktop-menu-item ${showSubMenu === 'ourActions' && 'active'}`}
              onMouseEnter={() => handleSubMenuToggle('ourActions')}
              onMouseLeave={() => handleAllSubMenusClose()}
            >
              <Link to='/our-actions' className='tsf-text desktop-menu-item-title'>Nos actions<IoMdArrowDropdown /></Link>
              {showSubMenu === 'ourActions' && (
                <div className='desktop-sub-menu'
                >
                  <div
                    className={`desktop-sub-menu-item ${showSecondSubMenu === 'forRegular' && 'active'}`}
                    onClick={() => handleSecondSubMenuToggle('forRegular')}
                  >
                    <p onMouseEnter={() => handleSecondSubMenuToggle('')} className='tsf-text desktop-sub-menu-item-title'>Pour les personnes {showSecondSubMenu === 'forRegular' ? <IoMdArrowDropleft /> : <IoMdArrowDropright />}</p>
                    {showSecondSubMenu === 'forRegular' && (
                      <div className='desktop-second-sub-menu'>
                        <Link to='/our-actions/welcome-and-handle' className='tsf-text desktop-second-sub-menu-item'>Accueil et accompagnement</Link>
                        <div className='desktop-sub-menu-divider' />
                        <Link to='/our-actions/workshops' className='tsf-text desktop-second-sub-menu-item'>Ateliers collectifs</Link>
                        <div className='desktop-sub-menu-divider' />
                        <Link to='#' className='tsf-text desktop-second-sub-menu-item'>Groupes de parole</Link>
                        <div className='desktop-sub-menu-divider' />
                        <Link to='/our-actions/artivistes' className='tsf-text desktop-second-sub-menu-item'>Artivistes : atelier d'art</Link>
                      </div>
                    )}
                  </div>
                  <div className='desktop-sub-menu-divider' />
                  <div
                    className={`desktop-sub-menu-item ${showSecondSubMenu === 'forPro' && 'active'}`}
                    onClick={() => handleSecondSubMenuToggle('forPro')}
                  >
                    <p onMouseEnter={() => handleSecondSubMenuToggle('')} className={`tsf-text desktop-sub-menu-item-title ${showSecondSubMenu === 'forPro' && 'active'}`}>Pour les pros de terrain {showSecondSubMenu === 'forPro' ? <IoMdArrowDropleft /> : <IoMdArrowDropright />}</p>
                    {showSecondSubMenu === 'forPro' && (
                      <div className='desktop-second-sub-menu'>
                        <Link to='/our-actions/emergency-interventions' className='tsf-text desktop-second-sub-menu-item'>Interventions d'urgence TSF</Link>
                        <div className='desktop-sub-menu-divider' />
                        <Link to='/our-actions/united-projects' className='tsf-text desktop-second-sub-menu-item'>Projets solidaires</Link>
                        <div className='desktop-sub-menu-divider' />
                        <Link to='/our-actions/formations' className='tsf-text desktop-second-sub-menu-item'>Formez-vous</Link>
                      </div>
                    )}
                  </div>
                  <div className='desktop-sub-menu-divider' />
                  <Link onMouseEnter={() => handleSecondSubMenuToggle('')} to='/our-actions/formations' className='tsf-text desktop-sub-menu-item'>Nos méthodes / nos outils</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link onMouseEnter={() => handleSecondSubMenuToggle('')} to='/our-actions/programs' className='tsf-text desktop-sub-menu-item'>Nos programmes TSF</Link>
                </div>
              )}
            </div>



            {/***** RECHERCHES ET PLAIDOYERS  */}
            <div
              className={`desktop-menu-item ${showSubMenu === 'searchAndSpeech' && 'active'}`}
              onMouseEnter={() => handleSubMenuToggle('searchAndSpeech')}
              onMouseLeave={() => handleSubMenuToggle('')}
            >
              <Link to='/search-and-speech' className='tsf-text desktop-menu-item-title'>Recherches & plaidoyers<IoMdArrowDropdown /></Link>
              {showSubMenu === 'searchAndSpeech' && (
                <div className='desktop-sub-menu'>
                  <Link to='/search-and-speech/search-and-action' className='tsf-text desktop-sub-menu-item'>Recherches & actions</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/search-and-speech/scientific-mediation' className='tsf-text desktop-sub-menu-item'>Médiation scientifique</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/search-and-speech/tribunes' className='tsf-text desktop-sub-menu-item'>Tribunes TSF</Link>
                </div>
              )}
            </div>


            {/***** NOUS SOUTENIR  */}
            <div
              className={`desktop-menu-item ${showSubMenu === 'supportUs' && 'active'}`}
              onMouseEnter={() => handleSubMenuToggle('supportUs')}
              onMouseLeave={() => handleSubMenuToggle('')}
            >
              <Link to='/support-us' className='tsf-text desktop-menu-item-title'>Nous soutenir<IoMdArrowDropdown /></Link>
              {showSubMenu === 'supportUs' && (
                <div className='desktop-sub-menu'>
                  <Link to='/support-us/become-home' className='tsf-text desktop-sub-menu-item'>Devenir bénévole</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/support-us/donations' className='tsf-text desktop-sub-menu-item'>Faire un don</Link>
                  <div className='desktop-sub-menu-divider' />
                  <Link to='/support-us/legacy-and-donations' className='tsf-text desktop-sub-menu-item'>Legs et donations</Link>
                </div>
              )}
            </div>


            {/***** DEVENIR PARTENAIRE  */}
            <div className='desktop-menu-item' onMouseEnter={() => handleSubMenuToggle('supportUs')} onMouseLeave={() => handleSubMenuToggle('')}>
              <Link to='/become-partner' className='tsf-text desktop-menu-item-title'>Devenir partenaire engagé</Link>
            </div>
          </div>


          {/***** APP AND DONATION BUTTONS  */}
          <div className='desktop-menu-actions-buttons'>
            <NavLink to='/app' className='desktop-menu-button-img'>
              <img src={app_logo} alt='tsf app' />
            </NavLink>
            <NavLink to='https://www.helloasso.com/associations/therapy-sans-frontieres/formulaires/1' target='_blank'>
              <button className='desktop-menu-button-donations tsf-button-shadow tsf-text'>
                <span className='desktop-menu-donations-text'>Don </span><FaHandHoldingHeart className='desktop-menu-donations-icon-button' />
              </button>
            </NavLink>
          </div>
        </div>
      </div>



      {/***** MOBILE MENU */}
      <div className='mobile-menu'>
        {!menuMobileActive ?
          <div className='mobile-menu-closed'>
            <div className='mobile-menu-icon-container' onClick={() => setMenuMobileActive(true)}>
              <FaBars className='mobile-menu-icon' />
            </div>
            <NavLink to='/' className='mobile-menu-logo-container'>
              <img src={mobile_logo} className='mobile-menu-logo' />
            </NavLink>
            <NavLink onClick={() => handleCloseAllMobile()} to='/app' className='mobile-menu-actions-button-img-logo'>
              <img src={logo} alt='tsf app' /> <span className='tsf-text mobile-menu-actions-button-img-logo-text'>App</span>
            </NavLink>
          </div>
          :
          <>
            <div className='mobile-menu-layout' onClick={() => (handleCloseAllMobile(), handleSubMenuToggle(''))} />
            <div className='mobile-menu-container'>
              <IoIosClose className='mobile-menu-close-button' onClick={() => (handleCloseAllMobile(), handleSubMenuToggle(''))} />


              {/***** QUI SOMMES NOUS ?  */}
              <div
                className={`mobile-menu-item ${showSubMenu === 'aboutUs' && 'active'}`}
                onClick={() => handleSubMenuToggle(showSubMenu === 'aboutUs' ? '' : 'aboutUs')}
              >
                <p className='tsf-text mobile-menu-item-title'>Qui sommes nous ? {showSubMenu === 'aboutUs' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                {showSubMenu === 'aboutUs' && (
                  <div className='mobile-sub-menu'>
                    <Link onClick={() => handleCloseAllMobile()} to='/our-story' className='tsf-text mobile-sub-menu-item'>Notre histoire</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-values' className='tsf-text mobile-sub-menu-item'>Nos valeurs</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/#HomePresentation' className='tsf-text mobile-sub-menu-item'>Présentation</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-engagments' className='tsf-text mobile-sub-menu-item'>Nos engagements</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/members' className='tsf-text mobile-sub-menu-item'>Notre fonctionnement</Link>
                    <div className='mobile-sub-menu-divider' />
                  </div>
                )}
              </div>
              <div className='mobile-menu-divider' />


              {/***** NOS SERVICES  */}
              <div
                className={`mobile-menu-item ${showSubMenu === 'ourServices' && 'active'}`}
                onClick={() => handleSubMenuToggle(showSubMenu === 'ourServices' ? '' : 'ourServices')}
              >
                <p className='tsf-text mobile-menu-item-title'>Qui accompagnons nous ? {showSubMenu === 'ourServices' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                {showSubMenu === 'ourServices' && (
                  <div className='mobile-sub-menu'>
                    <Link onClick={() => handleCloseAllMobile()} to='/our-services/concerned-people' className='tsf-text mobile-sub-menu-item'>Personnes concernées</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-services/victimology' className='tsf-text mobile-sub-menu-item'>Service victimologie</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-services/criminology' className='tsf-text mobile-sub-menu-item'>Service criminologie</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-services/laws' className='tsf-text mobile-sub-menu-item'>Service juridique</Link>
                  </div>
                )}
              </div>
              <div className='mobile-menu-divider' />


              {/***** NOS ACTIONS  */}
              <div
                className={`mobile-menu-item ${showSubMenu === 'ourActions' && 'active'}`}
              >
                <p onClick={() => handleSubMenuToggle(showSubMenu === 'ourActions' ? '' : 'ourActions')} className='tsf-text mobile-menu-item-title'>Nos actions {showSubMenu === 'ourActions' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                {showSubMenu === 'ourActions' && (
                  <div className='mobile-sub-menu'>
                    <div
                      className={`mobile-sub-menu-item ${showSecondSubMenu === 'forRegular' && 'active'}`}
                      onClick={() => handleSecondSubMenuToggle(showSecondSubMenu === 'forRegular' ? '' : 'forRegular')}
                    >
                      <p className={`tsf-text mobile-menu-item-sub-title ${showSecondSubMenu === 'forRegular' && 'active'}`}>Pour les personnes {showSecondSubMenu === 'forRegular' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                      {showSecondSubMenu === 'forRegular' && (
                        <div className='mobile-second-sub-menu'>
                          <Link onClick={() => handleCloseAllMobile()} to='/our-actions/welcome-and-handle' className='tsf-text mobile-second-sub-menu-item'>Accueil et accompagnement</Link>
                          <div className='mobile-sub-menu-divider' />
                          <Link onClick={() => handleCloseAllMobile()} to='/our-actions/workshops' className='tsf-text mobile-second-sub-menu-item'>Ateliers collectifs</Link>
                          <div className='mobile-sub-menu-divider' />
                          <Link onClick={() => handleCloseAllMobile()} to='#' className='tsf-text mobile-second-sub-menu-item'>Groupes de parole</Link>
                          <div className='mobile-sub-menu-divider' />
                          <Link onClick={() => handleCloseAllMobile()} to='/our-actions/artivistes' className='tsf-text mobile-second-sub-menu-item'>Artivistes : atelier d'art</Link>
                        </div>
                      )}
                    </div>
                    <div className='mobile-sub-menu-divider' />
                    <div
                      className={`mobile-sub-menu-item ${showSecondSubMenu === 'forPro' && 'active'}`}
                      onClick={() => handleSecondSubMenuToggle(showSecondSubMenu === 'forPro' ? '' : 'forPro')}
                    >
                      <p className={`tsf-text mobile-menu-item-title ${showSecondSubMenu === 'forPro' && 'active'}`}>Pour les pros de terrain {showSecondSubMenu === 'forPro' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                      {showSecondSubMenu === 'forPro' && (
                        <div className='mobile-second-sub-menu'>
                          <Link onClick={() => handleCloseAllMobile()} to='/our-actions/emergency-interventions' className='tsf-text mobile-second-sub-menu-item'>Interventions d'urgence TSF</Link>
                          <div className='mobile-sub-menu-divider' />
                          <Link onClick={() => handleCloseAllMobile()} to='/our-actions/united-projects' className='tsf-text mobile-second-sub-menu-item'>Projets solidaires</Link>
                          <div className='mobile-sub-menu-divider' />
                          <Link onClick={() => handleCloseAllMobile()} to='/our-actions/formations' className='tsf-text mobile-second-sub-menu-item'>Formez-vous</Link>
                        </div>
                      )}
                    </div>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-actions/formations' className='tsf-text mobile-sub-menu-item'>Nos méthodes / nos outils</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/our-actions/programs' className='tsf-text mobile-sub-menu-item'>Nos programmes TSF</Link>
                  </div>
                )}
              </div>
              <div className='mobile-menu-divider' />

              {/***** RECHERCHES ET PLAIDOYERS  */}
              <div
                className={`mobile-menu-item ${showSubMenu === 'searchAndSpeech' && 'active'}`}
                onClick={() => handleSubMenuToggle(showSubMenu === 'searchAndSpeech' ? '' : 'searchAndSpeech')}
              >
                <p to='/search-and-speech' className='tsf-text mobile-menu-item-title'>Recherches & plaidoyers {showSubMenu === 'searchAndSpeech' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                {showSubMenu === 'searchAndSpeech' && (
                  <div className='mobile-sub-menu'>
                    <Link onClick={() => handleCloseAllMobile()} to='/search-and-speech' className='tsf-text mobile-sub-menu-item'>Recherches & plaidoyers</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/search-and-speech/search-and-action' className='tsf-text mobile-sub-menu-item'>Recherches & actions</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/search-and-speech/scientific-mediation' className='tsf-text mobile-sub-menu-item'>Médiation scientifique</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/search-and-speech/tribunes' className='tsf-text mobile-sub-menu-item'>Tribunes TSF</Link>
                    <div className='mobile-sub-menu-divider' />
                  </div>
                )}
              </div>
              <div className='mobile-menu-divider' />


              {/***** NOUS SOUTENIR  */}
              <div
                className={`mobile-menu-item ${showSubMenu === 'supportUs' && 'active'}`}
                onClick={() => handleSubMenuToggle(showSubMenu === 'supportUs' ? '' : 'supportUs')}
              >
                <p to='/support-us' className='tsf-text mobile-menu-item-title'>Nous soutenir {showSubMenu === 'supportUs' ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</p>
                {showSubMenu === 'supportUs' && (
                  <div className='mobile-sub-menu'>
                    <Link onClick={() => handleCloseAllMobile()} to='/support-us' className='tsf-text mobile-sub-menu-item'>Soutenez nous</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/support-us/become-home' className='tsf-text mobile-sub-menu-item'>Devenir bénévole</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/support-us/donations' className='tsf-text mobile-sub-menu-item'>Faire un don</Link>
                    <div className='mobile-sub-menu-divider' />
                    <Link onClick={() => handleCloseAllMobile()} to='/support-us/legacy-and-donations' className='tsf-text mobile-sub-menu-item'>Legs et donations</Link>
                    <div className='mobile-sub-menu-divider' />
                  </div>
                )}
              </div>
              <div className='mobile-menu-divider' />


              {/***** DEVENIR PARTENAIRE  */}
              <div className='mobile-menu-item' onClick={() => handleSubMenuToggle(showSubMenu === 'supportUs' ? '' : 'supportUs')}>
                <Link onClick={() => handleCloseAllMobile()} to='/become-partner' className='tsf-text mobile-menu-item-title'>Devenir partenaire engagé</Link>
              </div>


              {/***** APP AND DONATION BUTTONS  */}
              <div className='mobile-menu-actions-buttons'>
                <NavLink onClick={() => handleCloseAllMobile()} to='/app' className='mobile-menu-actions-button-img'>
                  <img src={app_logo} alt='tsf app' />
                </NavLink>
                <NavLink onClick={() => handleCloseAllMobile()} to='https://www.helloasso.com/associations/therapy-sans-frontieres/formulaires/1' target='_blank'>
                  <button className='mobile-menu-buttons mobile-menu-button-donation tsf-button-shadow tsf-text'>
                    <span className='mobile-menu-donations-text'>Faire un Don </span><FaHandHoldingHeart className='mobile-menu-donations-icon-button' />
                  </button>
                </NavLink>
              </div>
              <div className='mobile-menu-actions-buttons'>
                <NavLink onClick={() => handleCloseAllMobile()} to='/support-us/become-home'>
                  <button className='mobile-menu-buttons mobile-menu-button-benevolent tsf-button-shadow tsf-text'>
                    <span className='mobile-menu-donations-text'>Devenir bénévole </span>
                  </button>
                </NavLink>
                <NavLink to='/contact' title='Email'><div className='mobile-menu-social-element mobile-menu-social-element-mail tsf-button-shadow tsf-text' title='Notre email'>Nous contacter <IoMail /></div></NavLink>
              </div>

              {/***** SOCIALS AND CONTACT BUTTONS */}
              <div className='mobile-menu-socials-container'>
                <Link to='https://www.facebook.com/profile.php?id=61555222786942' target='_blank' className='mobile-menu-social-element mobile-menu-social-element-facebook tsf-button-shadow tsf-text' title='Notre Facebook'><FaFacebookSquare className='mobile-menu-social-element-icon' /></Link>
                <Link to='https://www.linkedin.com/company/therapy-sans-fronti%C3%A8res/' target='_blank' className='mobile-menu-social-element mobile-menu-social-element-linkedin tsf-button-shadow tsf-text' title='Notre Linkedin'><FaLinkedin className='mobile-menu-social-element-icon' /></Link>
                <Link to='https://www.instagram.com/therapysansfrontieres/?igshid=NzZlODBkYWE4Ng' target='_blank' className='mobile-menu-social-element mobile-menu-social-element-instagram tsf-button-shadow tsf-text' title='Notre instagram'><FaInstagramSquare className='mobile-menu-social-element-icon' /></Link>
              </div>
            </div>
          </>
        }
      </div>
    </div >
  )
}
