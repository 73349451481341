import React, { useEffect, useState } from 'react'

// components
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import Menu from '../components/Menu'
import { NewsTSFCard } from '../components/uikit/Cards'

// packages
import { RotatingLines } from 'react-loader-spinner'

// styles
import '../styles/News.css'

// utils
import { isMobileScreen } from '../utils/Sizes'

export default function News() {

  const [isLoading, setIsLoading] = useState(true)
  const [news, setNews] = useState([])

  const getNews = async () => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const query = `https://www.back.tsf.konecton.com/news-tsf/all`

      fetch(query, options)
        .then(response => response.json())
        .then(data => {
          setNews(data)
          setIsLoading(false)
        })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true)
    getNews()
  }, [])

  return (
    <div className='News tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='tsf-content-container news-container'>
        <h1>Nos Actualités</h1>
        {isLoading ?
          <div className='tsf-loader-container'>
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{ backgroundColor: 'black' }}
              wrapperClass=""
            />
          </div>
          :
          <div className='news-content-container'>
            {news && news.map(n => <NewsTSFCard {...n} isAdmin={false} isPage={true} key={n.id} />)}
          </div>}
      </div>
      <Footer />
    </div>
  )
}
