export const CVList = [
  {
    id: 1,
    date: '2021',
    type: `Article`,
    link: '',
    content: `Sous la direction scientifique de Testouri L. du Diplôme Universitaire d’Art-thérapie de la faculté de Médecine de Tours :
    Junet, S. (2021). Évaluation de l’impact de l’art-thérapie à dominante photographie auprès d’adolescents déficients visuels accompagnées en institut d’éducation sensorielle, DU Art-thérapie de la faculté de Médecine de Tours.`,
  },
  {
    id: 2,
    date: '2020',
    type: `Article scientifique`,
    link: '',
    content: `Dieu, E., Testouri, L., & Palaric, R. (2020). L’outil IVIE-tr sur les Investissements de VIE lors des trajectoires de radicalisation : une application pratique du modèle de l’Identité Temporelle TIM-E à destination des professionnels de terrain. Revue Internationale de Criminologie et de Police Technique et Scientifique, in press`,
  },
  {
    id: 3,
    date: '2019',
    type: `Contrats de recherche financés – Recherche Action`,
    link: '',
    content: `Poste d’Ingénieure d’étude : Testouri,L. & ARCA (2018-2019) Projet 3C2D Europe sur la radicalisation et le terrorisme, avec ARCA, CIPM, ANTIGONE, en milieu pénitentiaire Belgique, France, Italie avec les universités de Tours, Liège, Leuven. Projet financé par la commission européenne.`,
  },
  {
    id: 4,
    date: '2018',
    type: `Article scientifique`,
    link: '',
    content: `Dieu, E., Testouri, L., Sorel, O. (2018). Proposition d’une méthodologie d’évaluation de l’identité en voie de radicalisation, dans Revue Internationale de Criminologie et de Police Technique et Scientifique.`,
  },
  {
    id: 5,
    date: '2018',
    type: `Conference paper`,
    link: '',
    content: `Tirana. Dieu, E., Sorel, O., Testouri, L., Dandonneau, V. (2018). Innovative pratices of restorative justice in France – the contribution made by virtual reality. Conference: 10th international conference of the European Forum of Restorative Justice. `,
  },
  {
    id: 6,
    date: '2018',
    type: `Colloque-Intervention à une table ronde`,
    link: '',
    content: `Thématique : Colloque sur la desistance ? c’est-à-dire !!! Comment sortir de la délinquance ?  ENPJJ de Bordeaux. 
    Testouri, L. & ARCA (2018). Présentations des théories de desistance. Quelle(s) "pratique(s) et posture(s) desistantielle(s)" des professionnels sur les suivis des auteurs d’infractions, réinterroger la réponse pénale actuelle, École Nationale de protection judiciaire de la jeunesse.`,
  },
  {
    id: 7,
    date: '2018',
    type: `Conférence-débat`,
    link: '',
    content: `Testouri,L., Rebeix,S. (2018) Thématique : Les cahiers dynamiques de l’ENPJJ N°72 radicalisations : des jeunes sous influence ?, en présence et sous la direction de CHEVAL P. et de Guzniczak, B,. Bordeaux. `,
  },
  {
    id: 8,
    date: '2018',
    type: `Colloque-Intervention à une table ronde`,
    link: '',
    content: `Thématique : la justice restaurative
    Testouri, L. & ARCA (2018). Mise en pratique innovante de la justice restaurative, organisé par l’ACJM à St Lô.`,
  },
  {
    id: 9,
    date: '2018',
    type: `Colloque-Intervention`,
    link: '',
    content: `Testouri, L., (2018). Représentions des professionnels de la médiation pénale concernant la justice restaurative : freins et les leviers possibles, en référence au rapport ministériel rendu en 2016 sur les freins et leviers de la justice restaurative en France, sous la direction Abdellaoui, S., Amadio, N., et Colin, P. à l’univ.de Strasbourg.`,
  },
  {
    id: 10,
    date: '2017',
    type: `Interview paper`,
    link: 'https://www.cairn.info/revue-les-cahiers-dynamiques-2017-2.htm',
    content: `Bouzar, D., Testouri L. (2017). Radicalisations : des jeunes sous influence ? - Former les professionnels, p20-33. Les Cahiers Dynamiques (N° 72). Propos recueillis par Cheval, P., Guzniczak, B.`,
  },
  {
    id: 11,
    date: '2017',
    type: `Poster`,
    link: '',
    content: `Dieu, E., Testouri, L., Sorel, O. (2017). Préparer l'accompagnement restauratif dans le cadre de rencontre auteurs-victimes : apports des TCCE. Conférence : 45e Congrès annuel des Thérapies Comportementales et Cognitives (AFTCC).`,
  },
  {
    id: 12,
    date: '2017',
    type: `Colloque-Intervention`,
    link: '',
    content: `Testouri, L., & ARCA (2017). Présentation du protocole CESURE, un ensemble d’actions visant à évaluer et accompagner les personnes radicalisées ou en voie de radicalisation, 3ème journée de sensibilisation : Lutte contre la radicalisation violente, co-organisée par Citoyens & Justice, la CNAPE, CIPDR et le CNLAPS.`,
  },
  {
    id: 13,
    date: '2017',
    type: `Colloque-Intervention`,
    link: '',
    content: `Testouri, L., & ARCA (2017). Présentation de l’outil SPHERES : approche synchronique et diachronique du plan de vie d’une personne dite radicalisée, conférence nationale sur la radicalisation à Aisne.`,
  },
  {
    id: 14,
    date: '2017',
    type: `Communication`,
    link: '',
    content: `Testouri, L., & ARCA (2017). Les enjeux et perspective d’une recherche-action singulière, 2ème  conférence Européenne de Justice restaurative organisée par Citoyens & justice.`,
  },
  {
    id: 15,
    date: '2016',
    type: `Colloque-Intervention`,
    link: '',
    content: `Testouri, L., & ARCA (2016). Présentation des premiers éléments de la Recherche Action relative à la Justice restaurative au sein du réseau Citoyens et Justice, 1er conférence Européenne de Justice restaurative organisée par Citoyens & justice.`,
  },
  {
    id: 16,
    date: '2016',
    type: `Colloque-Intervention`,
    link: '',
    content: `Testouri, L., & ARCA (2016). Présentation de la Justice restaurative : approche et méthode de l’Arca, la Justice restaurative auprès du service pénitentiaire d’insertion et de probation du Cher et de l’Indre.`,
  },
  {
    id: 17,
    date: '2016',
    type: `Communication`,
    link: '',
    content: `Testouri, L., & ARCA (2016). Programme collectif CESURE : l’accompagnement TCCE de l’identité vulnérable rencontrant une radicalisation, Communication   au 44 e   Congrès annuel des   Thérapies   Comportementales   et Cognitives (AFTCC). Symposium 2 : Programmes méthodes et techniques innovantes en TCC, Paris.`,
  },
  {
    id: 18,
    date: '2016',
    type: `Colloque-Intervention à une table ronde`,
    link: '',
    content: `Thématique : Prévention de la radicalisation religieuse, organisée par la préfecture et le conseil départemental en Saône et Loire.
    Testouri,L. (2016). Présentation des processus de radicalisation, Plan de Lutte contre la Radicalisation (PLAT), en collaboration avec Domigo,B., et Abdellaoui, S.`,
  },
  {
    id: 19,
    date: '2016',
    type: `Recherche financée`,
    link: '',
    content: `Abdellaoui S., Amadio N., Colin P., (resp.sc.) Auzoult L., Blatier C., Sarg R., Testouri L. (2014-2016), Freins et leviers de la Justice restaurative en France, sous la direction Sid Abdellaoui,Nicolas Amadio et Patrick Colin.Avec Université de Strasbourg, Université de Lorraine, laboratoire Interpsy, CNRS, CERIS, Dyname et le soutien de Mission de Recherche Droit et Justice.`,
  },
  {
    id: 20,
    date: '2014',
    type: `Poster`,
    link: '',
    content: `Thématique : Violences, risques et transgressions : Entre représentations et pratiques : quelle place pour la Justice restaurative en France?, 14èmes Journées d’Études internationales, promotion A-M. Favard, à l’Univ. de Rennes 2.`,
  },
]