import React, { useEffect, useState } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'

// icons
import { MdArrowDropDown } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";

// styles
import '../../styles/Formations.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function Formations() {
  const [isOpen, setIsOpen] = useState(false)
  const [openId, setOpenId] = useState()

  const handleDetails = (id) => {
    if (isOpen) {
      setOpenId(id)
    } else {
      setIsOpen(true)
      setOpenId(id)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='Formations tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='formations-container tsf-content-container'>
        <h1>Nos méthodes et outils</h1>
        <p className='formations-content-description'>Retrouvez nos différentes méthodes et nos outils :</p>
        <div className='formations-content-container'>
          <div className='formations-content' onClick={() => handleDetails(1)}>
            <p className='formations-content-title tsf-text'>Victimologie</p>
            <p>
              <b>Méthode pratique EMDR</b><br />
            </p>
            <p className='formation-content-arrow'>voir plus {(isOpen && openId === 1) ? <IoMdArrowDropup /> : <MdArrowDropDown />}</p>
            {isOpen && openId === 1 &&
              <>
                <p>
                  {/* <b>Méthode pratique EMDR</b><br /> */}
                  <p>L’EMDR (Eye Movement Desensitization and Reprocessing) plus communément appelé en français “Désensibilisation et Retraitement par les Mouvements Oculaires” est une méthode découverte par Francine Shapiro -psychologue américaine- à la fin des années quatre-vingt.<br />
                    Il s’agit d’une thérapie concernant principalement le traitement du syndrôme de stress post-traumatique ainsi que les phobies.<br />
                    Les recherches actuelles mettent en avant que l’EMDR pourrait également être étendue à bien plus de problématiques.<br />
                    La thérapie EMDR est aujourd’hui reconnue par l’OMS (2013), la Haute Autorité de la Santé (2007) ainsi que par l’INSERM (2004 et 2015) dans la prise en charge du trouble de stress post-traumatique.<br />
                    <a href='https://www.who.int/fr/news/item/06-08-2013-who-releases-guidance-on-mental-health-care-after-trauma'>Méthode de guide sur la santé mentale après un traumatisme</a>
                  </p>
                </p>
              </>
            }
          </div>
          <div className='formations-content' onClick={() => handleDetails(2)}>
            <p className='formations-content-title tsf-text'>Psychologie</p>
            <p>
              <b>Méthode TCCE et Mindfulness & Pleine conscience</b><br />
            </p>
            <p className='formation-content-arrow'>voir plus {(isOpen && openId === 2) ? <IoMdArrowDropup /> : <MdArrowDropDown />}</p>
            {isOpen && openId === 2 &&
              <>
                <p>
                  <b>Méthode TCCE</b><br />
                  Les Thérapies Cognitivo-Comportementales et Émotionnelles (TCCE) sont recommandées par la Haute Autorité de Santé dans la prise en charge de nombreux troubles (dépression, TOC, TCA, addictologie, etc..).<br />
                  Elles prennent en compte trois dimensions psychiques : la cognition, le comportement et les émotions. Les TCCE sont introduites par les courants cognitivistes et béhavioristes et seront étendues par la suite au domaine émotionnel avec les psychologues Aaron Beck et Albert Ellis.<br />
                  Elles prennent en compte toutes les composantes d’un individu et partent du postulat que tout n’est qu’apprentissage.<br />
                  Par conséquent, la cognition, le comportement et les émotions ne sont pas figés dans le temps mais bel et bien ajustables et modifiables selon les besoins du patient.<br />
                  <a href='https://www-cairn-info.ezproxy.univ-orleans.fr/introduction-aux-therapies-comportementales-et-cog--9782100801206-page-11.htm'>Introduction aux therapies comportementales et cognitives</a>
                </p>
                <p>
                  <b>Mindfulness & Pleine conscience</b><br />
                  La Pleine conscience (Mindfulness) est la prise de conscience qui survient lorsqu’une personne porte une attention consciente et sans jugement à l’expérience du moment présent.<br />
                  La Mindfulness peut-être pratiquée de manière formelle ou informelle. C’est-à-dire qu’elle peut être développée via la méditation par exemple, mais aussi en portant son attention sur chaque instant du moment présent dans notre quotidien.<br />
                  La Pleine conscience trouve ses origines dans la tradition bouddhiste du Dharma il y a plus de 2600 ans. Le programme MBSR (Mindfulness-Based Stress Reduction) mis au point en 1979 par le Docteur Jon Kabat Zinn permettra de lui attribuer une application clinique en démontrant son efficacité contre le stress et ses conséquences.<br />
                  <a href='https://www.association-mindfulness.org/'>Association Mindfulness</a>
                </p>
              </>
            }
          </div>
          <div className='formations-content' onClick={() => handleDetails(3)}>
            <p className='formations-content-title tsf-text'>Criminologie</p>
            <p>
              <b>Méthode du Good life model GLM</b><br />
            </p>
            <p className='formation-content-arrow'>voir plus {(isOpen && openId === 3) ? <IoMdArrowDropup /> : <MdArrowDropDown />}</p>
            {isOpen && openId === 3 &&
              <>
                <p>...</p>
              </>
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
