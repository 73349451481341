// packages
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// components
import AboutUs from './screens/AboutUs';
import AdminBenevolentCompleteProfile from './admin/screens/AdminBenevolentCompleteProfile';
import AdminBenevolentRequests from './admin/screens/AdminBenevolentRequests';
import AdminConnection from './admin/screens/AdminConnection';
import AdminEmailsRequests from './admin/screens/AdminEmailsRequests';
import AdminHome from './admin/screens/AdminHome';
import AdminJoinUsRequests from './admin/screens/AdminJoinUsRequests';
import AdminNewsTSF from './admin/screens/AdminNewsTSF';
import AdminProCompleteProfile from './admin/screens/AdminProCompleteProfile';
import AdminProsRequests from './admin/screens/AdminProRequests';
import AdminStaff from './admin/screens/AdminStaff';
import AdminStatistics from './admin/screens/AdminStatistics';
import AppForm from './screens/App/AppForm';
import AppHome from './screens/App/AppHome';
import Artivistes from './screens/OurActions/Artivistes';
import Advocacy from './screens/Advocacy';
import BecomeMember from './screens/SupportUs/BecomeMember';
import BecomeHome from './screens/SupportUs/BecomeHome';
import BecomePartner from './screens/SupportUs/BecomePartner';
import ConcernedPeople from './screens/OurServices/ConcernedPeople';
import Confidential from './components/Confidential';
import Contact from './screens/Contact';
import Criminology from './screens/OurServices/Criminology';
import CV from './screens/CV';
import Donations from './screens/SupportUs/Donations';
import EmergencyInterventions from './screens/OurActions/EmergencyInterventions';
import ErrorPage from './screens/ErrorPage';
import FAQ from './components/FAQ';
import Formations from './screens/OurActions/Formations';
import JoinUs from './screens/SupportUs/JoinUs';
import Home from './screens/Home';
import Laws from './screens/OurServices/Laws';
import LegacyAndDonations from './screens/SupportUs/LegacyAndDonations';
import Legal from './screens/Legal';
import Members from './screens/Members';
import News from './screens/News';
import OurActions from './screens/OurActions/Actions';
import OurEngagments from './screens/OurEngagments';
import OurServices from './screens/OurServices/Services';
import OurStory from './screens/OurStory';
import OurValues from './screens/OurValues';
import Presentation from './screens/Presentation';
import Programs from './screens/OurActions/Programs';
import Restaurative from './screens/OurServices/Restaurative';
import ScientificMediation from './screens/SearchAndConferences/ScientificMediation';
import SearchAndSpeech from './screens/SearchAndConferences/SearchAndSpeech';
import SubmitProject from './screens/SupportUs/SubmitProject';
import SupportUs from './screens/SupportUs/SupportUs';
import Tribunes from './screens/SearchAndConferences/Tribunes';
import UnitedProjects from './screens/OurActions/UnitedProjects';
import Victimology from './screens/OurServices/Victimology';
import Workshops from './screens/OurActions/Workshops';

// styles
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css'
import './styles/uikit/Buttons.css'

// use
import { PrivateRoute } from './use/useSecureRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/advocacy' element={<Advocacy />} />
        <Route path='/app' element={<AppHome />} />
        <Route path='/App/form/:role' element={<AppForm />} />
        <Route path='/become-partner' element={<BecomePartner />} />
        <Route path='/confidential' element={<Confidential />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/cv' element={<CV />} />
        <Route path='/error' element={<ErrorPage />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/legal' element={<Legal />} />
        <Route path='/members' element={<Members />} />
        <Route path='/news' element={<News />} />
        <Route path='/our-actions' element={<OurActions />} />
        <Route path='/our-actions/artivistes' element={<Artivistes />} />
        <Route path='/our-actions/emergency-interventions' element={<EmergencyInterventions />} />
        <Route path='/our-actions/formations' element={<Formations />} />
        <Route path='/our-actions/programs' element={<Programs />} />
        <Route path='/our-actions/united-projects' element={<UnitedProjects />} />
        <Route path='/our-actions/welcome-and-handle' element={<UnitedProjects />} />
        <Route path='/our-actions/workshops' element={<Workshops />} />
        <Route path='/our-engagments' element={<OurEngagments />} />
        <Route path='/our-services' element={<OurServices />} />
        <Route path='/our-services/concerned-people' element={<ConcernedPeople />} />
        <Route path='/our-services/criminology' element={<Criminology />} />
        <Route path='/our-services/laws' element={<Laws />} />
        <Route path='/our-services/restaurative' element={<Restaurative />} />
        <Route path='/our-services/victimology' element={<Victimology />} />
        <Route path='/our-services' element={<OurServices />} />
        <Route path='/our-story' element={<OurStory />} />
        <Route path='/our-values' element={<OurValues />} />
        <Route path='/presentation' element={<Presentation />} />
        <Route path='/search-and-speech' element={<SearchAndSpeech />} />
        <Route path='/search-and-speech/scientific-mediation' element={<ScientificMediation />} />
        <Route path='/search-and-speech/search-and-action' element={<SearchAndSpeech />} />
        <Route path='/search-and-speech/tribunes' element={<Tribunes />} />
        <Route path='/support-us' element={<SupportUs />} />
        <Route path='/support-us/become-home' element={<BecomeHome />} />
        <Route path='/support-us/become-member' element={<BecomeMember />} />
        <Route path='/support-us/donations' element={<Donations />} />
        <Route path='/support-us/join-us' element={<JoinUs />} />
        <Route path='/support-us/legacy-and-donations' element={<LegacyAndDonations />} />
        <Route path='/support-us/submit-projects' element={<SubmitProject />} />
        <Route path='/tsf-admin/connection' element={<AdminConnection />} />
        <Route element={<PrivateRoute />}>
          <Route path='/tsf-admin/home' element={<AdminHome />} exact />
          <Route path='/tsf-admin/benevolent' element={<AdminBenevolentRequests />} exact />
          <Route path='/tsf-admin/benevolent/complete/:id' element={<AdminBenevolentCompleteProfile />} exact />
          <Route path='/tsf-admin/join-us' element={<AdminJoinUsRequests />} exact />
          <Route path='/tsf-admin/emails' element={<AdminEmailsRequests />} exact />
          <Route path='/tsf-admin/news-tsf' element={<AdminNewsTSF />} exact />
          <Route path='/tsf-admin/pro' element={<AdminProsRequests />} exact />
          <Route path='/tsf-admin/pro/complete/:id' element={<AdminProCompleteProfile />} exact />
          <Route path='/tsf-admin/staff' element={<AdminStaff />} exact />
          <Route path='/tsf-admin/stats' element={<AdminStatistics />} exact />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
