import React, { useEffect } from 'react'

// assets
import photo from '../assets/images/members-profile/linda_profile_3.png'

// components
import { CVCard } from '../components/uikit/Cards'
import { Divider } from '../components/uikit/Decorations'
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import Menu from '../components/Menu'

// icons
import { FaLinkedin } from 'react-icons/fa';

// packages
import { Link } from "react-router-dom"// packages

// styles
import '../styles/CV.css'

// utils
import { CVList } from '../utils/CVList'
import { isMobileScreen } from '../utils/Sizes'

export default function CV() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='CV tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='cv-container tsf-content-container'>
        <h1>Therapy sans Frontières</h1>
        <div className='cv-header-container'>
          <div className='cv-header-image'>
            <img src={photo} alt='Linda Testouri' />
          </div>
          <h2 className='cv-header-title tsf-text'>Linda Testouri,<br /> Présidente et Fondatrice de Therapy Sans Frontières</h2>
          <h3 className='cv-header-linkedin'><FaLinkedin /> <Link to='https://www.linkedin.com/in/linda-testouri-00a67384/' className='cv-header-link tsf-text'>Mon profil LinkedIn</Link></h3>
        </div>
        <Divider />
        <div className='cv-cards-container'>
          <h3 className='cv-cards-title'>Publications, communications scientifiques nationaux et internationaux :</h3>
          {CVList.map(e =>
            <CVCard {...e} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}
