export const isSmallScreen = () => {
  return window.innerWidth <= 768
}

export const isMobileScreen = () => {
  return window.innerWidth <= 480
}

export const isVerySmallScreen = () => {
  return window.innerWidth <= 375
}