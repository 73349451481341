import React from 'react'

// icons
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';

// packages
import { Link } from 'react-router-dom';

// styles
import('../styles/Footer.css')

export default function Footer() {
  return (
    <footer className='Footer'>
      <div className="footer-wrapper">
        <div className="footer-columns">
          <section className="footer-logo-column">
            <div>
              <h3>Therapy Sans Frontières</h3>
            </div>
          </section>
          <div className="other-columns">
            <section>
              <ul>
                <li>
                  <p className='footer-asso-info-title'>A propos de nous</p>
                  <p className='footer-asso-info-text'>Therapy Sans Frontières est une association  active depuis août 2023 (loi 1901) née d’une volonté depromouvoir l’accès à la santé mentale et de lutter pour une meilleure justice sociale.
                    Nous nous engageons à défendre les droits humains et militons contre toutes les formes de violences et de discriminations.</p>
                </li>
              </ul>
            </section>
            <section>
              <ul>
                <li>
                  <a href='/legal' title='Nos mentions légales'>Mentions légales</a>
                </li>
                <li>
                  <a href='/confidential' title='Notre politique de confidentialité'>Politique de confidentialité</a>
                </li>
              </ul>
            </section>
            <section>
              <ul>
                <li>
                  <a href='/faq' title='Foire aux questions'>Questions fréquentes</a>
                </li>
                <li>
                  <a href='/support-us/become-home' title='Rejoignez-nous'>Nous rejoindre</a>
                </li>
                <li>
                  <a href='/contact' title="Formulaire de contact">Nous contacter</a>
                </li>
                <li>
                  <Link to='https://www.facebook.com/profile.php?id=61555222786942' target='_blank' className='footer-social-logo' title='Notre Facebook'><FaFacebookSquare /></Link>
                  <Link to='https://www.linkedin.com/company/therapy-sans-fronti%C3%A8res/' target='_blank' className='footer-social-logo' title='Notre Linkedin'><FaLinkedin /></Link>
                  <Link to='https://www.instagram.com/therapysansfrontieres/?igshid=NzZlODBkYWE4Ng' target='_blank' className='footer-social-logo' title='Notre instagram'><FaInstagramSquare /></Link>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-wrapper">
          <span>Therapy Sans Frontières © <span id="year">2024</span> - Site internet réalisé par Sylvain Langot</span>
        </div>
      </div>
    </footer>
  )
}
