import React, { useEffect } from 'react'

// assets
import contact_img from '../assets/images/decorations/Mobile-rafiki.png'

// components
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import Menu from '../components/Menu'

// style
import '../styles/Contact.css'

// utils
import { isMobileScreen } from '../utils/Sizes'

export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='Contact tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='contact-container tsf-content-container'>
        <div className='contact-image-container'>
          <img src={contact_img} alt='contact image' />
        </div>
        <h1>Contactez nous</h1>
        <ContactForm />
      </div>
      <Footer />
    </div>
  )
}
