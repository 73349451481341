import React, { useState } from 'react'

// components
import AdminLateralSidebar from '../components/AdminLateralSidebar'

// styles
import '../styles/AdminHome.css'

export default function AdminHome() {

  const [menuOpen, setMenuOpen] = useState(true)

  return (
    <div className={`AdminHome ${menuOpen ? 'menuOpen' : 'menuClose'}`}>
      <AdminLateralSidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`admin-home-container`}>
        <h1>Backoffice Therapy Sans Frontières</h1>
      </div>
    </div >
  )
}
