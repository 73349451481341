import React, { useEffect, useState } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import WorkInProgress from '../../components/WorkInProgress'

// icons
import { MdArrowDropDown } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";

// styles
import '../../styles/Programs.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function Programs() {
  const [isOpen, setIsOpen] = useState(false)
  const [openId, setOpenId] = useState()

  const handleDetails = (id) => {
    if (isOpen) {
      setIsOpen(false)
      setOpenId(0)
    } else {
      setIsOpen(true)
      setOpenId(id)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Programs tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='tsf-content-container programs-container'>
        {/* <WorkInProgress /> */}
        <h1>Programmes TSF</h1>

        <div className='programs-content-container'>
          <div className='programs-content' onClick={() => handleDetails(1)}>
            <p className='programs-content-title tsf-text'>Programme Parcours de Soutien Psy (PSP)</p>
            <p>Accompagner les victimes/victimisation dans leur démarche concrète (gendarmerie, avocat, médecin ect) et suivi thérapeutique et social.<br />
            </p>
            <p className='programs-content-arrow'>voir plus {(isOpen && openId === 1) ? <IoMdArrowDropup /> : <MdArrowDropDown />}</p>
            {isOpen && openId === 1 &&
              <p>(...)<br />
                <a href='https://www.ameli.fr/assure/remboursements/rembourse/remboursement-seance-psychologue-mon-soutien-psy'>Plus d'info sur www.ameli.fr</a>
              </p>
            }
          </div>
          <div className='programs-content' onClick={() => handleDetails(2)}>
            <p className='programs-content-title tsf-text'>Maraude Thérapeutique</p>
            <p>
              La santé mentale dans la ville / campagne, équipe mobile. Lutter contre l’isolement social. Sans patient fixe.<br />
            </p>
            <p className='programs-content-arrow'>voir plus {(isOpen && openId === 2) ? <IoMdArrowDropup /> : <MdArrowDropDown />}</p>
            {isOpen && openId === 2 &&
              <p>(...)<br />
                <a href='#'>Lien ...</a>
              </p>
            }
          </div>
          <div className='programs-content' onClick={() => handleDetails(3)}>
            <p className='programs-content-title tsf-text'>Programme Accompagnement Social et Thérapeutique : AST</p>
            <p>
              Sous contrat entre le membre principal et les bénévoles tsf, pour aider à la sociabilité. Sortant de prison, psychiatrie, situation de rupture sociale etc.<br />
            </p>
            <p className='programs-content-arrow'>voir plus {(isOpen && openId === 3) ? <IoMdArrowDropup /> : <MdArrowDropDown />}</p>
            {isOpen && openId === 3 &&
              <p>(...)<br />
                <a href='#'>Lien ...</a>
              </p>
            }
          </div>
        </div>



      </div>
      <Footer />
    </div>
  )
}
