import React, { useContext, useState } from 'react'

// context
import { Context } from '../../context/Context'

// icons
import { IoMdArrowRoundBack } from "react-icons/io";

// styles
import '../styles/AdminConnection.css'

// packages
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

export default function AdminConnection() {

  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const { dispatch } = useContext(Context)

  const handleSubmit = async () => {

    try {

      const body = {
        log: identifier,
        password: password
      }
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      };

      const query = `https://www.back.tsf.konecton.com/auth`
      await fetch(query, options)
        .then(result => {

          if (result.ok) {
            return result.json()
          } else {
            Swal.fire({
              icon: 'error',
              title: "Nom d'utilisateur ou mot de passe incorrect",
              confirmButtonColor: 'red',
              timer: 1500
            })
          }
        }).then(data => {
          if (data) {
            dispatch({
              type: 'LOGIN_SUCCESS',
              payload: data.replace(/"/g, '')
            })
            Swal.fire({
              icon: 'success',
              title: 'Bienvenue',
              confirmButtonColor: 'green'
            })
            setTimeout(() => {
              window.location.replace('/tsf-admin/home')
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: "Erreur de connection",
              confirmButtonColor: 'red',
              timer: 1500
            })
          }
        })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={`AdminConnection`}>
      <div className={`admin-connection-container`}>
        <Link className='app-form-header-return-btn' to='/app'>
          <IoMdArrowRoundBack />
          retour au site
        </Link>
        <h1>Connection Therapy Sans Frontières</h1>
        <div className='admin-connection-form-container'>
          <input className='admin-connection-form-element' type='text' placeholder='Identifiant' value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
          <input className='admin-connection-form-element' type='password' placeholder='Mot de passe' value={password} onChange={(e) => setPassword(e.target.value)} />
          <button className='admin-connection-form-element admin-connection-form-button' onClick={() => handleSubmit()}>Se connecter</button>
        </div>
      </div>
    </div >
  )
}
