import React from 'react'

// assets
import ErrorImage from '../assets/images/decorations/404-error.png'

// components
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import Menu from '../components/Menu'

// styles
import '../styles/ErrorPage.css'

// utils
import { isMobileScreen } from '../utils/Sizes'

export default function ErrorPage() {
  return (
    <div className='ErrorPage tsf-global-screen'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='error-page-container tsf-content-container'>
        <h1>Page non trouvée</h1>
        <div className='error-page-image-container'>
          <img src={ErrorImage} alt='error 404' />
        </div>
      </div>
      <Footer />
    </div>
  )
}
