import React from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import WorkInProgress from '../../components/WorkInProgress'

// styles
// import '../styles/OurServices.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function OurServices() {
  return (
    <div className='OurServices tsf-global-screen'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='our-services-container'>
        <WorkInProgress />
      </div>
      <Footer />
    </div>
  )
}
