import React, { useEffect, useState } from 'react'

// assets
import donation_img from '../../assets/images/decorations/Saving money-amico.png'

// components
import { DonationCard } from '../../components/uikit/Cards';
import { Divider } from '../../components/uikit/Decorations';
import Footer from '../../components/Footer';
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'

// icons
import { FaArrowRightLong } from "react-icons/fa6";
import { FaHandHoldingHeart, FaHandPointRight } from "react-icons/fa";

// packages
import { NavLink } from 'react-router-dom';

// styles
import '../../styles/supportUs/Donations.css'

// utils
import { deductionCalculator, totalCalculator } from '../../utils/CalculatorsFunctions';
import { DonationsList } from '../../utils/DonationsList';
import { isMobileScreen } from '../../utils/Sizes'

export default function Donations() {

  const [simulatorAmount, setSimulatorAmount] = useState(null)
  const [simulatorDiscount, setSimulatorDiscount] = useState(0)
  const [simulatorTotal, setSimulatorTotal] = useState(0)
  const [simulatorPercentage, setSimulatorPercentage] = useState()

  useEffect(() => {
    if (simulatorAmount) {
      setSimulatorDiscount(deductionCalculator(simulatorAmount, simulatorPercentage, 2))
    } else {
      setSimulatorDiscount(0)
    }
  }, [simulatorPercentage, simulatorAmount])

  useEffect(() => {
    if (simulatorDiscount) {
      setSimulatorTotal(totalCalculator(simulatorAmount, simulatorDiscount, 2))
    } else {
      setSimulatorTotal(0)
    }
  }, [simulatorDiscount])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='Donations tsf-global-screen'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='donations-container tsf-content-container'>
        <div className='donations-header'>
          <h1>Faire un don</h1>
          <img src={donation_img} className='donations-header-img' alt='donation illustration' />
          <p>Créée en 2021, Therapy Sans Frontières est une association (loi 1901) née d’une volonté de promouvoir l’accès à la santé mentale et de lutter pour une meilleure justice sociale.</p>
          <p> Nous nous engageons à défendre les droits humains et militons contre toutes les formes de violences et de discriminations.</p>
        </div>
        <Divider />
        <div className='donations-info'>
          <h2>Déduction fiscale</h2>
          <p>Vous bénéficiez d'une part importante de déduction fiscale sur votre don</p>
          <div className='donations-info-comparison'>
            {DonationsList.map((don, index) =>
              <DonationCard {...don} key={index} />
            )}
          </div>
        </div>
        <Divider />
        <div className='donations-simulator'>
          <h3>Simuler votre don</h3>
          <p>Découvrez combien vous coûte réellement votre don avec notre similateur</p>
          <div className='donations-simulator-form'>
            <div className={`donations-simulator-choice ${(simulatorAmount && !simulatorPercentage) && 'donations-simulator-choice-missing'}`}>
              <p>Vous êtes :</p>
              <div className='donations-simulator-buttons-container'>
                {DonationsList.map((don, index) => (
                  <button key={index} onClick={() => setSimulatorPercentage(don.percentage)} className={`donations-simulator-buttons tsf-button-shadow tsf-text ${simulatorPercentage === don.percentage && 'donations-simulator-buttons-active'}`}>{don.btnTitle}</button>
                ))}
                {simulatorPercentage && <p className='donations-simulator-deduction-info'><FaHandPointRight /> Déduction de <span>{simulatorPercentage * 100}%</span></p>}
              </div>
            </div>
            <div className='donations-simulator-price'>
              <div className='donations-simulator-element'>
                <p className='donations-simulator-form-row-1 donations-simulator-form-text'>Je donne <FaArrowRightLong className='donations-simulator-form-text-icon' /></p>
                <div className='donations-simulator-form-row-2 donations-simulator-input-container'>
                  <input type="number" className='donations-simulator-input' onChange={(e) => setSimulatorAmount(e.target.value)} />
                  <span className='donations-simulator-currency'>€</span>
                </div>
              </div>
              <div className='donations-simulator-element'>
                <p className='donations-simulator-form-row-1 donations-simulator-form-text'>Déduction de <FaArrowRightLong className='donations-simulator-form-text-icon' /></p>
                <p className='donations-simulator-form-row-2 donations-simulator-form-discount'>{simulatorDiscount} € </p>
              </div>
              <div className='donations-simulator-element'>
                <p className='donations-simulator-form-row-1 donations-simulator-form-text'>Mon don ne me coûte réellement que </p>
                <p className='donations-simulator-form-row-2 donations-simulator-form-result'>{simulatorTotal} € </p>
              </div>
            </div>
          </div>
          {(simulatorAmount && !simulatorPercentage) && <div className='donations-error-indications'>
            Vous devez sélectionner si vous êtes un particulier ou une entreprise
          </div>}
        </div>
        <div className='donations-call-to-actions'>
          <NavLink to='https://www.helloasso.com/associations/therapy-sans-frontieres/formulaires/1' target='_blank'><button className='donations-call-to-action-button tsf-button-shadow tsf-text'><FaHandHoldingHeart className='donations-icon-button' /> Je fais mon don</button></NavLink>
        </div>
      </div>
      <Footer />
    </div>
  )
}
