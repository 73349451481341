import React from 'react'

// icons
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';

// packages
import { Link } from 'react-router-dom';

// styles
import '../styles/LateralSidebar.css'

export default function LateralSidebar() {

  return (
    <div className='LateralSidebar'>
      <Link to='https://www.facebook.com/profile.php?id=61555222786942' target='_blank' className='lateral-sidebar-element lateral-sidebar-element-facebook' title='Notre Facebook'><FaFacebookSquare /></Link>
      <Link to='https://www.linkedin.com/company/therapy-sans-fronti%C3%A8res/' target='_blank' className='lateral-sidebar-element lateral-sidebar-element-linkedin' title='Notre Linkedin'><FaLinkedin /></Link>
      <Link to='https://www.instagram.com/therapysansfrontieres/?igshid=NzZlODBkYWE4Ng' target='_blank' className='lateral-sidebar-element lateral-sidebar-element-instagram' title='Notre instagram'><FaInstagramSquare /></Link>
      <Link to='/contact' title='Email'><div className='lateral-sidebar-element lateral-sidebar-element-mail' title='Notre email'><IoMail /></div></Link>
    </div>
  )
}
