export const FormProgressbarList = [
  {
    id: 1,
    text: 'L\'association Therapy Sans Frontières'
  },
  {
    id: 2,
    text: 'Vos besoins et demandes'
  },
  {
    id: 3,
    text: 'Prise de rendez-vous'
  },
  {
    id: 4,
    text: 'Facilitons nos échanges'
  },
  {
    id: 5,
    text: 'Confirmation de votre demande'
  },
]

export const JoinUsFormProgressbarList = [
  {
    id: 1,
    text: 'L\'association Therapy Sans Frontières'
  },
  {
    id: 2,
    text: 'A propos de vous'
  },
  {
    id: 3,
    text: 'Comment voulez-vous nous aider'
  },
  {
    id: 4,
    text: 'Confirmation de votre demande'
  }
]