import React, { useEffect } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import WorkInProgress from '../../components/WorkInProgress'

// styles
import '../../styles/searchAndConferences/SearchAndSpeech.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function SearchAndSpeech() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='SearchAndSpeech tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='tsf-content-container search-and-speech-container'>
        {/* <WorkInProgress /> */}
        <h1>Recherche & Action</h1>
        <p>Cette section de recherche & action accompagne les projets de recherche universitaire (en stage, rédaction d’un mémoire, d’un article etc..) afin de contribuer à l’avancement de la recherche partagée.</p>
        <p>L’interaction et la co-construire avec les stagiaires et professionnels TSF permettent de développer l’apport théorique en apport pratique, en outils d'accompagnement concret en lien avec le public visé.</p>

        <div className='search-and-speech-button-container'>
          <button className='tsf-button tsf-button-primary tsf-button-shadow tsf-text'>Recrutement de stagiaire ici</button>
        </div>

      </div>
      <Footer />
    </div>
  )
}
