import React, { useEffect } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import WorkInProgress from '../../components/WorkInProgress'

// styles
import '../../styles/Tribunes.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function Tribunes() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Tribunes tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='tsf-content-container tribunes-container'>
        {/* <WorkInProgress /> */}
        <h1>Tribunes TSF</h1>
        <p>
          <b>Le Plaidoyer : Donner une Voix à la Cause</b>
        </p>
        <p>
          Le plaidoyer, au sein de Therapy sans Frontrières, est une manière puissante de sensibiliser, d'informer et d'influencer les décideurs et la société en général.
        </p>
        <p>
          <b>Mais qu'est-ce que c'est réellement ?</b> <br />
          Le plaidoyer est comme un porte-voix pour notre cause. Il consiste à exprimer nos convictions, à attirer l'attention sur des enjeux spécifiques liés à la santé mentale et à la psychocriminologie. C'est un moyen de faire entendre notre voix et de susciter des changements positifs.
        </p>
        <p>
          <p><b>À quoi Sert le Plaidoyer ?</b></p>
          <p>
            <u>Sensibilisation :</u> Le plaidoyer vise à sensibiliser le public aux défis rencontrés dans les domaines que nous défendons. Il permet d'éclairer sur des questions complexes de manière accessible, favorisant une meilleure compréhension.
          </p>
          <p>
            <u>Influence des Décideurs :</u> En plaidant, nous cherchons à influencer les politiques et les décisions gouvernementales. En présentant des arguments solides et des données convaincantes, nous encourageons les responsables à prendre des mesures positives en faveur de la santé mentale et des thèmes associés.
          </p>
          <p>
            <u>Changement Social :</u> Le plaidoyer est un catalyseur du changement social. En mobilisant la communauté autour de nos idéaux, nous visons à créer une société plus informée, empathique et proactive face aux problématiques que nous défendons.
          </p>
          <p>
            <u>Les Tribunes TSF :</u> Partageons nos Idées et Engageons le Dialogue<br />
            Les tribunes de l'association sont des espaces où nous exprimons nos opinions, partageons nos idées et engageons un dialogue ouvert avec la communauté. Mais comment cela fonctionne-t-il de manière simple ?
            Les tribunes sont comme des plateformes virtuelles où nous prenons la parole. C'est là que nous publions des articles, des témoignages, des analyses et des opinions sur des sujets liés à nos domaines d'action. C'est notre façon d'ouvrir une fenêtre sur notre monde et d'inviter le public à réfléchir avec nous.
          </p>
        </p>
        <p>
          <p><b>À quoi Servent les Tribunes ?</b></p>
          <p>
            <p><u>Partage d'Idées :</u> Les tribunes permettent à notre association de partager des idées novatrices, des réflexions approfondies et des expertises. C'est un espace pour présenter des solutions et susciter des discussions constructives.</p>
            <p><u>Témoignages et Expériences :</u> À travers les tribunes, nous mettons en lumière des témoignages et des expériences significatives. Cela contribue à humaniser nos actions et à montrer l'impact concret de notre travail.</p>
            <p><u>Engagement avec la Communauté :</u> Les tribunes sont une invitation au dialogue. Nous encourageons le public à réagir, poser des questions, et partager leurs propres perspectives. C'est un moyen de créer une communauté engagée et collaborative.</p>
            <p>En somme, le plaidoyer et les tribunes sont des outils essentiels qui donnent vie à notre mission. Ils nous permettent d'élever la voix, de partager des connaissances, et d'inspirer le changement dans le domaine de la santé mentale et de la psychocriminologie. Rejoignez-nous dans cette démarche collective vers un avenir meilleur.</p>
          </p>
        </p>
      </div>
      <Footer />
    </div >
  )
}
