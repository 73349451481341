import React from 'react'

export default function JoinUsBenevolent({
  actions,
  comment,
  commitments,
  handleArrayActions,
  handleTextActions,
  project
}) {
  return (
    <div className='JoinUsBenevolent'>
      <h3 className='join-us-component-title'>Bénévolat</h3>
      <div className='join-us-content-container'>
        <div className='join-us-actions-container'>
          <p className='join-us-actions-title'>Nos actions :</p>
          <p className='join-us-actions-sub-title'>Les actions permettent de sensibiliser un large public tant dans l’accès aux soins liés à la santé mentale qu’une prise en charge directe et sans condition. Cette action répond à l’objectif n°3 “Bonne Santé et Bien-être” des Objectifs de Développement Durable et de solidarité (horizon 2030 ONU)</p>
          <div className='join-us-buttons-container'>

            <button className={`join-us-button tsf-button-shadow  ${actions?.includes(('Maraude Thérapeutique (MT)')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('actions', 'Maraude Thérapeutique (MT)')}>
              Maraude Thérapeutique (MT)
            </button>
            <button className={`join-us-button tsf-button-shadow ${actions.includes(('Accompagnement Thérapeutique et Social (ATS)')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('actions', 'Accompagnement Thérapeutique et Social (ATS)')}>
              Accompagnement Thérapeutique et Social (ATS)
            </button>
            <button className={`join-us-button tsf-button-shadow ${actions?.includes(('Ateliers collectifs (AC)')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('actions', 'Ateliers collectifs (AC)')}>
              Ateliers collectifs (AC)
            </button>
          </div>
        </div>
        <div className='join-us-divider' />
        <div className='join-us-commitments-container'>
          <p className='join-us-commitments-title'>Nos engagements :</p>
          <div className='join-us-buttons-container'>
            <button className={`join-us-button tsf-button-shadow ${commitments?.includes(('Recherche et plaidoyer')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('commitments', 'Recherche et plaidoyer')}>
              Recherche et plaidoyer
            </button>
            <button className={`join-us-button tsf-button-shadow ${commitments?.includes(('Artiviste')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('commitments', 'Artiviste')}>
              Artiviste
            </button>
            <button className={`join-us-button tsf-button-shadow ${commitments?.includes(('Éducation populaire')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('commitments', 'Éducation populaire')}>
              Éducation populaire
            </button>
            <button className={`join-us-button tsf-button-shadow ${commitments?.includes(('VSS (Violences Sexistes et Sexuelles)')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('commitments', 'VSS (Violences Sexistes et Sexuelles)')}>
              VSS (Violences Sexistes et Sexuelles)
            </button>
            <button className={`join-us-button tsf-button-shadow ${commitments?.includes(('Féminisme et intersexualité')) && 'join-us-button-active'}`} onClick={() => handleArrayActions('commitments', 'Féminisme et intersexualité')}>
              Féminisme et intersexualité
            </button>
          </div>
        </div>

        <div className='join-us-textarea-container'>
          <label htmlFor='project'>Si vous avez un projet parlez-nous en :</label>
          <textarea id='project' onChange={(e) => handleTextActions('project', e.target.value)} value={project} placeholder='Parlez-nous de votre projet ...'></textarea>
        </div>

        <div className='join-us-textarea-container'>
          <label htmlFor='comment'>Commentaire :</label>
          <textarea id='comment' onChange={(e) => handleTextActions('comment', e.target.value)} value={comment} placeholder='Dites-nous en plus ...'></textarea>
        </div>
      </div>
    </div>
  )
}
