import React from 'react'

export default function JoinUsInterns({
  comment,
  cursus,
  endDate,
  handleDates,
  handleTextActions,
  handleBooleanActions,
  hasConvention,
  school,
  startDate,
}) {
  return (
    <div className='JoinUsInterns'>
      <h3 className='join-us-component-title'>Stage</h3>
      <div className='join-us-content-container'>
        <div className='join-us-dates-container'>
          <div className='join-us-date-container'>
            <label htmlFor='startDate'>Date de début :</label>
            <input type='date' id='startDate' onChange={(e) => handleDates('startDate', e.target.value)} value={startDate} />
          </div>
          <div className='join-us-date-container'>
            <label htmlFor='endDate'>Date de fin :</label>
            <input type='date' id='endDate' onChange={(e) => handleDates('endDate', e.target.value)} value={endDate} />
          </div>
        </div>
        <div className='join-us-text-container'>
          <label htmlFor='school'>Dans quel établissement êtes-vous inscrit.e ?</label>
          <input type='text' id='school' onChange={(e) => handleTextActions('school', e.target.value)} value={school} placeholder='ex: Université de Tours' />
        </div>
        <div className='join-us-text-container'>
          <label htmlFor='cursus'>Quel cursus suivez-vous ?</label>
          <input type='text' id='cursus' onChange={(e) => handleTextActions('cursus', e.target.value)} value={cursus} placeholder='ex: Licence en psychologie' />
        </div>
        <div className='join-us-has-convention-container'>
          <p className='join-us-regular-title'>Avez-vous une convention de stage ?</p>
          <div className='join-us-buttons-left-container'>
            <button className={`join-us-regular-button ${hasConvention === true && 'join-us-regular-button-active'}`} onClick={() => handleBooleanActions('hasConvention', true)}>
              Oui
            </button>
            <button className={`join-us-regular-button ${hasConvention === false && 'join-us-regular-button-active'}`} onClick={() => handleBooleanActions('hasConvention', false)}>
              Non
            </button>
          </div>
        </div>

        <div className='join-us-textarea-container'>
          <label htmlFor='comment'>Commentaire :</label>
          <textarea id='comment' onChange={(e) => handleTextActions('comment', e.target.value)} value={comment} placeholder='Dites-nous en plus ...'></textarea>
        </div>
      </div>
    </div>
  )
}
