import React, { useEffect, useState } from 'react'

// components
import HomeHelpCard from './HomeHelpCard'

// icons
import { FaHandHoldingHeart } from "react-icons/fa";

// styles
import '../../styles/home/HomeHelp.css'

// utils
import { monthlyHelpList, oneTimeHelpList } from '../../utils/HelpList'
import { isMobileScreen, isVerySmallScreen } from '../../utils/Sizes';


export default function HomeHelp() {

  const [activeHelpBtn, setActiveHelpBtn] = useState(isMobileScreen() ? null : 'left')
  const [currentHelpList, setCurrentHelpList] = useState()

  useEffect(() => {
    if (activeHelpBtn === 'left') {
      setCurrentHelpList(monthlyHelpList)
    } else if (activeHelpBtn === 'right') {
      setCurrentHelpList(oneTimeHelpList)
    }
  }, [activeHelpBtn])

  return (
    <div className='tsf-home-page-wb HomeHelp'>
      <div className='tsf-home-page-content home-help-container'>
        <h2>Agissons ensemble <FaHandHoldingHeart /></h2>
        <div className='home-help-header-container'>
          <p className='home-help-header-title tsf-text'>Je peux aider</p>
          {isVerySmallScreen() ?
            <div className='home-help-header-buttons-min-container'>
              <button className={`home-help-button-min tsf-text ${activeHelpBtn === 'left' && 'home-help-button-min-active'}`} onClick={() => setActiveHelpBtn('left')}>Mensuellement</button>
              {!currentHelpList && <div className='home-help-buttons-decoration-default-decoration' />}
              <button className={`home-help-button-min tsf-text ${activeHelpBtn === 'right' && 'home-help-button-min-active'}`} onClick={() => setActiveHelpBtn('right')}>Ponctuellement</button>
            </div>
            :
            <div className='home-help-header-buttons-container'>
              <div className={`home-help-buttons-decoration ${activeHelpBtn === 'left' ? 'home-help-buttons-decoration-left' : activeHelpBtn === 'right' ? 'home-help-buttons-decoration-right' : 'home-help-buttons-decoration-default'}`} />
              <button className={`home-help-button tsf-text ${activeHelpBtn === 'left' && 'home-help-button-active'}`} onClick={() => setActiveHelpBtn('left')}>Mensuellement</button>
              {!currentHelpList && <div className='home-help-buttons-decoration-default-decoration' />}
              <button className={`home-help-button tsf-text ${activeHelpBtn === 'right' && 'home-help-button-active'}`} onClick={() => setActiveHelpBtn('right')}>Ponctuellement</button>
            </div>
          }
        </div>
        <div className='home-help-content-list'>
          {
            currentHelpList?.map((e, index) => (
              <HomeHelpCard {...e} key={index} />
            ))
          }
        </div>
      </div>
    </div>
  )
}
