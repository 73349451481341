import React, { useEffect } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import WorkInProgress from '../../components/WorkInProgress'

// styles
import '../../styles/ourServices/Laws.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function Laws() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Laws tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='tsf-content-container laws-container'>
        {/* <WorkInProgress /> */}
        <h1>Service accueil et prise en charge</h1>

        <p>
          "Notre engagement en faveur de la santé mentale s'accompagne d'une démarche responsable et transparente, intégrant un volet juridique essentiel pour garantir la qualité de nos actions et services.<br />
          Découvrez ci-dessous les principales facettes de notre Service Juridique.
        </p>
        <ul>
          <li>Qu'est-ce que le Service Juridique de Therapy sans Frontrières ?</li>
          <li>Les rôles du service juridique <br />
            <ul>
              <li>assistance juridique : ...</li>
              <li>orientation juridique : ...</li>
              <li>importance du service : ...</li>
              <li>prescription et viabilité des dossiers : ...</li>
              <li>non-lieux : ...</li>
            </ul>
          </li>
        </ul>

        <p>
          "Nous sommes déterminés à intégrer une approche juridique éthique et rigoureuse dans toutes nos actions, affirmant ainsi notre engagement envers" la solidarité au service de la santé mentale.<br />
          "Pour toute question ou démarche juridique, n'hésitez pas à nous contacter. Votre tranquillité d'esprit est notre priorité."
        </p>

        <div className='laws-button-container'>
          <button className='tsf-button tsf-button-primary tsf-button-shadow tsf-text'>Retrouvez nos programmes TSF</button>
        </div>

      </div>
      <Footer />
    </div>
  )
}
