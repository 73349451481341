import React from 'react'

// assets
import solidarity_image from '../../assets/images/decorations/Solidarity-rafiki.png'

// packages
import { Link } from 'react-router-dom'

// styles
import '../../styles/home/HomePartners.css'

// utils
import { partnersList } from '../../utils/PartnersList'

export default function HomePartners() {

  return (
    <div className='HomePartners tsf-home-page-nb'>
      <div className='home-partners-container tsf-home-page-content'>
        <img src={solidarity_image} className='home-partners-header-logo' alt='partners solidarity' />
        <h3>Avec le soutien du réseau partenaire</h3>
        <div className='partners-logo-container'>
          {partnersList.map((e, index) => (
            <Link to={e.link} key={index} >
              <figure className='partners-logo tsf-text'>
                <img src={e.image} alt={`${e.name} logo`} />
                <figcaption>{e.name}</figcaption>
              </figure>
            </Link>
          ))}
        </div>
        <Link to='/become-partner'><button className='home-partners-button tsf-button-shadow tsf-text'>Devenir partenaire</button></Link>
      </div>
    </div>
  )
}
