import React from 'react'

// styles
import '../styles/PartnerBonus.css'

export default function PartnerBonus({ id, image, title, message, alt }) {
  return (
    <div className='partner-bonus-container' key={id}>
      <img src={image} alt={alt} />
      <div className='partner-bonus-info'>
        <h4>{title}</h4>
        <p>{message}</p>
      </div>
    </div>
  )
}
