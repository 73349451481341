import React from 'react'

// icons
import { CiLogin, CiLogout } from "react-icons/ci";
import { FaRegHandshake } from 'react-icons/fa6';
import { IoNewspaperOutline, IoPeopleOutline, IoStatsChartOutline } from "react-icons/io5";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { PiSuitcaseSimple } from "react-icons/pi";
import { RiAdminLine, RiCloseCircleLine } from "react-icons/ri";

// packages
import { Link, useNavigate } from 'react-router-dom';

// styles
import '../styles/AdminLateralSidebar.css'

export default function AdminLateralSidebar({ menuOpen, setMenuOpen }) {
  const userStorage = localStorage.getItem('admin_session')
  const navigate = useNavigate()

  const handleDisconnect = () => {
    localStorage.removeItem('admin_session')
    localStorage.removeItem('admin_session')
    navigate('/')
  }

  return (
    <div className='AdminLateralSidebar'>
      <button className='admin-lateral-sidebar-window-button' onClick={() => setMenuOpen(!menuOpen)}>{menuOpen ? <CiLogout /> : <CiLogin />}</button>
      <div className={`admin-lateral-sidebar-container ${menuOpen ? 'admin-lateral-sidebar-container-open' : 'admin-lateral-sidebar-container-close'}`}>
        <Link to='/tsf-admin/join-us' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <FaRegHandshake className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Demandes Adhérents</span>
        </Link>
        <Link to='/tsf-admin/benevolent' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <IoPeopleOutline className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Demandes Bénévoles</span>
        </Link>
        <Link to='/tsf-admin/emails' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <MdOutlineMarkEmailUnread className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Demandes Emails</span>
        </Link>
        <Link to='/tsf-admin/pro' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <PiSuitcaseSimple className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Demandes Pro</span>
        </Link>
        <Link to='/tsf-admin/news-tsf' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <IoNewspaperOutline className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Gérer les news TSF</span>
        </Link>
        <Link to='/tsf-admin/stats' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <IoStatsChartOutline className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Statistiques</span>
        </Link>
        <Link to='/tsf-admin/staff' className={`admin-lateral-sidebar-link ${menuOpen ? 'admin-lateral-sidebar-link-left' : 'admin-lateral-sidebar-link-center'}`}>
          <RiAdminLine className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Utilisateurs</span>
        </Link>
        <button onClick={() => handleDisconnect()} className='admin-lateral-sidebar-logout'><RiCloseCircleLine className={`admin-lateral-sidebar-icon' ${menuOpen ? 'icon-small' : 'icon-big'}`} /><span className={`admin-lateral-sidebar-text ${menuOpen ? 'text-visible' : 'text-hidden'}`}>Déconnection</span></button>
      </div>
    </div>
  )
}
