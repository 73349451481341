import React from 'react'

// components
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import Menu from '../components/Menu'
import WorkInProgress from '../components/WorkInProgress'

// styles
// import '../styles/OurValues.css'

// utils
import { isMobileScreen } from '../utils/Sizes'

export default function OurValues() {
  return (
    <div className='OurValues tsf-global-screen'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='our-values-container'>
        <WorkInProgress />
      </div>
      <Footer />
    </div>
  )
}
