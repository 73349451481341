import React from 'react'

// assets
import img1 from '../../assets/images/carousel/TSF-about-us-img-v2-min.png'

// styles
import '../../styles/home/HomePresentation.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function HomePresentation() {

  return (
    <div className='tsf-home-page-wb HomePresentation' id='HomePresentation'>
      <div className='tsf-home-page-content home-presentation-container' style={{ width: '80%' }}>
        <div className='home-presentation-content'>
          <div className='home-presentation-carousel'>
            <img src={img1} alt='TSF presentation' />
          </div>
          <div className='home-presentation-text'>
            <h2>A propos de nous :</h2>
            <p><strong>Therapy Sans Frontières</strong> est une association  active depuis août 2023 (loi 1901) née d’une volonté de <b>promouvoir l’accès à la santé mentale</b> et de lutter pour une <b>meilleure justice sociale</b>.
              Nous nous engageons à <b>défendre les droits humains</b> et militons <b>contre toutes les formes de violences et de discriminations</b>.
            </p>
          </div>

        </div>
      </div>
    </div >
  )
}
