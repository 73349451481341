import React, { useEffect } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'

// styles
import { BecomeHomeCard } from '../../components/uikit/Cards'
import '../../styles/supportUs/BecomeHome.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function BecomeHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='BecomeHome tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='become-home-container tsf-content-container'>
        <h1>Rejoignez-nous</h1>
        <div className='become-home-cards-container'>
          <BecomeHomeCard text='adhérent' link='https://www.helloasso.com/associations/therapy-sans-frontieres/adhesions/bulletin-d-adhesion-tsf' color='#E7093D' inApp={false} />
          <BecomeHomeCard text='bénévole' link='/support-us/join-us' color='#008000' inApp={true} />
        </div>
      </div>
      <Footer />
    </div>
  )
}
