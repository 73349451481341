import React, { useEffect } from 'react'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import { SupportUsCard } from '../../components/uikit/Cards'

// utils
import { SupportUsCardList } from '../../utils/DonationsList'

// styles
import '../../styles/supportUs/SupportUs.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function SupportUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='SupportUs tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='support-us-container'>
        <h1>Soutenez nous</h1>
        <div className='support-us-cards-container'>
          {SupportUsCardList.map((card, index) => (
            <SupportUsCard {...card} key={index} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
