import { createContext, useReducer, useEffect, useState } from 'react'
import Reducer from './Reducer'

export const Context = createContext()

const I_STATE = {
  user: JSON.parse(localStorage.getItem('admin_session')) || null,
  error: null
}

export const AdminProvider = props => {
  const [state, dispatch] = useReducer(Reducer, I_STATE)
  const [isShortcut, setIsShortcut] = useState(() => {
    const localData = localStorage.getItem('isShortcut');
    return localData ? JSON.parse(localData) : 'empty';
  });

  const handleShortcut = (choice) => {
    localStorage.setItem('isShortcut', JSON.stringify(choice));
    setIsShortcut(choice);
  }

  useEffect(() => {
    localStorage.setItem('admin_session', JSON.stringify(state.user))
  }, [state.user])

  return (
    <Context.Provider
      value={{
        user: state.user,
        error: state.error,
        dispatch,
        isShortcut,
        handleShortcut
      }}
    >
      {props.children}
    </Context.Provider >
  )
}
