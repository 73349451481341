import React from 'react'

// packages
import { Link } from 'react-router-dom'

// styles
import '../../styles/home/HomeHelpCard.css'

export default function HomeHelpCard({ id, price, image, message, type }) {

  const noPrice = price === 'ce que je veux'

  return (
    <Link to='https://www.helloasso.com/associations/therapy-sans-frontieres/formulaires/1' target='_blank'><div className={`home-help-card home-help-card-${id}`}>
      <p className='home-help-card-subtitle'>JE DONNE</p>
      <div className={`home-help-card-price-row `}>
        <p className='home-help-card-price-container'><span className={`home-help-card-price tsf-text ${noPrice && 'home-help-card-free'}`}>{noPrice ? price : `${price}€`}</span>{type === 'monthly' && <i>/mois</i>}</p>
      </div>
      <img src={image} alt={`don de ${price} euros`} className='home-help-card-image' />
    </div></Link>
  )
}
