import React, { useEffect, useState } from 'react'

// components
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import { BenevolentMemberCard, MemberCard } from '../components/uikit/Cards'
import Menu from '../components/Menu'

// packages
import { Link } from 'react-router-dom'

// styles
import '../styles/Members.css'

// utils
import { BenevolentsList, MembersList } from '../utils/MembersList'
import { isMobileScreen } from '../utils/Sizes'

export default function Members() {

  const [cardDetails, setCardDetails] = useState()
  const [showDetails, setShowDetails] = useState(false)

  const DetailsCard = ({ firstname, image, lastname, longDescription, occupation }) => {
    return (
      <div className='benevolent-members-card-details' onClick={() => setShowDetails(false)}>
        <button className='benevolent-members-card-details-close-button'>X</button>
        <img src={image} alt={`${firstname} ${lastname}`} className='benevolent-members-card-details-image' />
        <h3 className='benevolent-members-card-details-title'>{`${firstname} ${lastname}`}</h3>
        <p className='benevolent-members-card-details-occupation'>{occupation}</p>
        <p className='benevolent-members-card-details-content'>{longDescription}</p>
      </div>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='Members tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='members-container tsf-content-container'>
        <h1 style={{ marginBottom: '30px' }}>Organigramme</h1>
        <h2 style={{ marginBottom: '30px' }}>Membres de l'association</h2>
        {MembersList.map((member) => (
          <MemberCard {...member} />
        ))}
        <div className='members-benevolents-container'>
          <h2 style={{ marginBottom: '30px', marginTop: '30px' }}>Bénévoles de l'association</h2>
          {showDetails && <DetailsCard {...cardDetails} key={cardDetails.id} />}
          <div className='members-benevolents-list'>
            {BenevolentsList.map((benevolent) => (
              <>
                <BenevolentMemberCard {...benevolent} setShowDetails={setShowDetails} setCardDetails={setCardDetails} />
              </>
            ))}
          </div>
        </div>
        <div className='members-join-button-container'>
          <Link to='/support-us' className='members-join-button tsf-button-shadow tsf-text'>
            Devenir bénévole
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}
