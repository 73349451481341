import React, { useState, useEffect, useRef } from 'react'

// assets
import carouselImgSlide1Main from '../assets/images/carousel/young-traveler-with-tsf-2-min.jpg'
import carouselImgSlide2Main from '../assets/images/carousel/map-of-the-world-429784_1920.jpg'
import carouselImgSlide2SecondMobile from '../assets/images/carousel/TSF_odd_v5_transparent.png'
import carouselImgSlide2SecondDesktop from '../assets/images/carousel/TSF_odd_v3_transparent.png'

// components
import Footer from '../components/Footer';
import HomeHelp from '../components/Home/HomeHelp';
import HomeJoinUs from '../components/Home/HomeJoinUs';
import HomeNews from '../components/Home/HomeNews';
import HomePartners from '../components/Home/HomePartners';
import HomePresentation from '../components/Home/HomePresentation';
import LateralSidebar from '../components/LateralSidebar';
import Menu from '../components/Menu';

// packages
import { Carousel } from 'react-responsive-carousel';
import AOS from 'aos';

// style
import 'aos/dist/aos.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/home/Home.css'
import '../styles/home/HomeFooter.css'

// utils
import { isMobileScreen } from '../utils/Sizes'
import { isSmallScreen } from '../utils/Sizes'

export default function Home() {

  // Images credits: 
  // Image par <a href="https://pixabay.com/fr/users/drkiranhania-22033/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=82272">Kiran Hania</a> de <a href="https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=82272">Pixabay</a>
  // Image par <a href="https://pixabay.com/fr/users/pcdazero-2615/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2901017">Gianni Crestani</a> de <a href="https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2901017">Pixabay</a>
  // <a href="https://www.freepik.com/free-photo/young-traveler_3709459.htm#page=2&query=travel%20woman%20forest&position=30&from_view=search&track=ais&uuid=a6859713-9a91-4414-80b6-e1d6178d8f6e">Image by jcomp</a> on Freepik
  // Image de <a href="https://fr.freepik.com/photos-gratuite/gens-se-tenant-main-se-bouchent_20825619.htm#page=6&query=solidarit%C3%A9&position=19&from_view=search&track=sph&uuid=46621f85-5db5-43db-9544-d19d7fd5afb3">Freepik</a>
  // <a href="https://fr.freepik.com/photos-gratuite/concept-reussite-commerciale-vue-dessus-table-bois-mains-protegeant-figures-bois-personnes_9426580.htm#query=help&position=8&from_view=search&track=sph&uuid=1578dcc6-d46e-4286-98d2-dd57a01e8c2a">Image de 8photo</a> sur Freepik
  // Image par <a href="https://pixabay.com/fr/users/pcdazero-2615/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2901017">Gianni Crestani</a> de <a href="https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2901017">Pixabay</a>
  // Photo de Porapak Apichodilok: https://www.pexels.com/fr-fr/photo/globe-sur-sable-346696/
  // Image par <a href="https://pixabay.com/fr/users/stokpic-692575/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=600497">stokpic</a> de <a href="https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=600497">Pixabay</a>
  // Image par <a href="https://pixabay.com/fr/users/schaeffler-395701/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=429784">schaeffler</a> de <a href="https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=429784">Pixabay</a>
  // Photo de mali maeder: https://www.pexels.com/fr-fr/photo/chemin-entre-les-arbres-a-feuilles-vertes-109391/

  const [isScrolled, setIsScrolled] = useState(false);
  const ref = useRef(null)

  const mobileDevice = isSmallScreen()

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsScrolled(scrollPosition > 0);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Menu isHomePage={true} isScrolled={isScrolled} />
      <div className="Home tsf-global-screen" id='top'>
        <div id='home-carousel' data-aos={isSmallScreen ? null : "fade-up"}>
          <Carousel interval={10000} autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
            <div className='carousel-image-container'>
              <img src={carouselImgSlide1Main} />
              <p className="carousel-image-legend tsf-text"><i>«Nous souhaitons développer un modèle de psychologie itinérant, <span className='carousel-legend-bold'>accessible</span> et transposable en faveur de la <span className='carousel-legend-bold'>santé mentale</span> et de la <span className='carousel-legend-bold'>justice sociale</span> pour toutes et tous»</i><br /><span className='carousel-legend-author'> Fondatrice Linda Testouri</span></p>
            </div>
            {mobileDevice ?
              <div className='carousel-image-container carousel-mobile-image'>
                <img src={carouselImgSlide2Main} className='carousel-image-main' />
                <img src={carouselImgSlide2SecondMobile} className='carousel-image-odd' />
              </div>
              :
              <div className='carousel-image-container carousel-desktop-image'>
                <img src={carouselImgSlide2Main} className='carousel-image-main' />
                <img src={carouselImgSlide2SecondDesktop} className='carousel-image-odd' />
              </div>
            }
          </Carousel>
        </div>
        <div data-aos={isSmallScreen ? null : "fade-up"} id='HomePresentation'>
          <HomePresentation />
        </div>
        <div data-aos={isSmallScreen ? null : "fade-up"}>
          <HomeJoinUs />
        </div>
        <div data-aos={isSmallScreen ? null : "fade-up"}>
          <HomeHelp />
        </div>
        <div data-aos={isSmallScreen ? null : "fade-up"}>
          <HomePartners />
        </div>
        <div data-aos={isSmallScreen ? null : "fade-up"}>
          <HomeNews />
        </div>
        {/* <div data-aos={isSmallScreen ? null : "fade-up"}>
          <span ref={ref}>
            <div className='homeContactBloc'>
              {/* <h3>Nous contacter</h3> * /}
        {/* <div className='homeContactContent'> * /}
        <div className='homeContactInfo'>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent faucibus, elit eget ullamcorper rhoncus, leo erat egestas ante, ut vulputate arcu nisi sit amet enim.
          </p><p>
            In varius risus sed eleifend rutrum. Cras vehicula ex ut accumsan venenatis. Etiam ultrices massa eget nibh cursus vehicula. Donec euismod dolor diam, id ullamcorper arcu ultricies ac.</p>

        </div>
        {/* </div> * /}
        <ContactForm size={50} />
      </div>
    </span >
        </div > */}
        {!isMobileScreen() && <LateralSidebar scrollTo={handleClick} />}
        <div data-aos={isSmallScreen ? null : "fade-up"} id='footer'>
          <Footer />
        </div>
      </div >
    </>
  )
}
