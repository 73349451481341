import React, { useEffect, useState } from 'react'

// assets
import logo_tsf_benevolent from '../../assets/images/TSF/TSF-Texte-Logo-Benevole.png'
import thank_you_logo from '../../assets/images/TSF/TSF_thank_you_logo.gif'

// components
import Footer from '../../components/Footer';
import JoinUsBenevolent from '../../components/JoinUs/JoinUsBenevolent';
import JoinUsCivic from '../../components/JoinUs/JoinUsCivic';
import JoinUsInterns from '../../components/JoinUs/JoinUsInterns';
import LateralSidebar from '../../components/LateralSidebar';
import Menu from '../../components/Menu';

// icons
import { IoMdArrowRoundBack, IoMdArrowRoundForward, IoMdCheckmark } from "react-icons/io";

// packages
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner'
import Swal from 'sweetalert2'

// styles
import '../../styles/joinus/JoinUs.css'
import '../../styles/joinus/JoinUsComponents.css'

// utils
import { JoinUsFormProgressbarList } from '../../utils/FormProgressbar';
import { isMobileScreen } from '../../utils/Sizes'

export default function JoinUs() {

  const { intlFormat } = require("date-fns");

  // GENERAL STATES
  const [conditionsValue, setConditionsValue] = useState(false)
  const [currentFormPage, setCurrentFormPage] = useState(1)
  const [missingFields, setMissingFields] = useState(false)
  const [submitionLoading, setSubmitionLoading] = useState(false)

  // FORM INPUTS STATES
  const [benevolentActions, setBenevolentActions] = useState([])
  const [benevolentAge, setBenevolentAge] = useState("")
  const [benevolentComment, setBenevolentComment] = useState("")
  const [benevolentCommitments, setBenevolentCommitments] = useState([])
  const [benevolentCursus, setBenevolentCursus] = useState("")
  const [benevolentEmail, setBenevolentEmail] = useState("")
  const [benevolentEndDate, setBenevolentEndDate] = useState("")
  const [benevolentHasConvention, setBenevolentHasConvention] = useState("")
  const [benevolentFirstname, setBenevolentFirstname] = useState("")
  const [benevolentLastname, setBenevolentLastname] = useState("")
  const [benevolentMission, setBenevolentMission] = useState("")
  const [benevolentPhoneNumber, setBenevolentPhoneNumber] = useState("")
  const [benevolentRequest, setBenevolentRequest] = useState("")
  const [benevolentProject, setBenevolentProject] = useState("")
  const [benevolentSchool, setBenevolentSchool] = useState("")
  const [benevolentStartDate, setBenevolentStartDate] = useState("")

  const handlePage = (action) => {
    const nextPage = handleRequiredPage()
    if (action === "-") {
      if (currentFormPage > 1) {
        setCurrentFormPage((prev) => prev - 1)
      }
    } else {
      if (nextPage) {
        setMissingFields(false)
        setCurrentFormPage((prev) => prev + 1)
      } else {
        setMissingFields(true)
      }
    }
  }

  const handleRequiredPage = () => {
    let filled = false
    if (currentFormPage === 1) {
      filled = true
    } else if (currentFormPage === 2) {
      filled = benevolentLastname && benevolentFirstname && benevolentAge && benevolentEmail && benevolentPhoneNumber && benevolentRequest
    } else if (currentFormPage === 3) {
      filled = true
    } else if (currentFormPage === 4) {
      filled = true
    } else if (currentFormPage === 5) {
      filled = true
    }
    return filled
  }

  const handleRequestChange = (request) => {
    resetRequestInputs()
    setBenevolentRequest(request)
  }

  const handleArrayActions = (array, value) => {
    if (array === 'actions') {
      const isSelected = benevolentActions.includes(value);
      if (isSelected) {
        setBenevolentActions(benevolentActions.filter((item) => item !== value));
      } else {
        setBenevolentActions([...benevolentActions, value]);
      }
    } else if (array === 'commitments') {
      const isSelected = benevolentCommitments.includes(value);
      if (isSelected) {
        setBenevolentCommitments(benevolentCommitments.filter((item) => item !== value));
      } else {
        setBenevolentCommitments([...benevolentCommitments, value]);
      }
    }
  }

  const handleDates = (type, value) => {
    if (type === 'startDate') {
      setBenevolentStartDate(value)
    } else if (type === 'endDate') {
      setBenevolentEndDate(value)
    }
  }

  const handleTextActions = (type, value) => {
    if (type === 'comment') {
      setBenevolentComment(value)
    } else if (type === 'cursus') {
      setBenevolentCursus(value)
    } else if (type === 'mission') {
      setBenevolentMission(value)
    } else if (type === 'school') {
      setBenevolentSchool(value)
    } else if (type === 'request') {
      setBenevolentRequest(value)
    } else if (type === 'project') {
      setBenevolentProject(value)
    }
  }

  const handleBooleanActions = (type, value) => {
    if (type === 'hasConvention') {
      setBenevolentHasConvention(value)
    }
  }

  const resetProfilInputs = () => {
    setBenevolentAge("")
    setBenevolentEmail("")
    setBenevolentFirstname("")
    setBenevolentLastname("")
    setBenevolentPhoneNumber("")
  }

  const resetRequestInputs = () => {
    setBenevolentActions([])
    setBenevolentComment("")
    setBenevolentCommitments([])
    setBenevolentCursus("")
    setBenevolentEndDate("")
    setBenevolentHasConvention("")
    setBenevolentMission("")
    setBenevolentRequest("")
    setBenevolentProject("")
    setBenevolentSchool("")
    setBenevolentStartDate("")
    setConditionsValue(false)
  }

  // BACKEND SUMBITION FUNCTION

  const handleSubmit = async (e) => {
    e.preventDefault()
    const body = {
      firstname: benevolentFirstname,
      lastname: benevolentLastname,
      age: benevolentAge,
      email: benevolentEmail,
      phone: benevolentPhoneNumber,
      request: benevolentRequest,
      school: benevolentSchool,
      cursus: benevolentCursus,
      has_convention: benevolentHasConvention,
      mission: benevolentMission,
      actions: benevolentActions.toString(),
      commitments: benevolentCommitments.toString(),
      project: benevolentProject,
      comment: benevolentComment,
      start_date: benevolentStartDate,
      end_date: benevolentEndDate
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }

    const bodyNotification = {
      email: benevolentEmail,
    }
    const optionsNotification = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyNotification)
    };

    fetch(`https://www.back.tsf.konecton.com/join-us-inquiries/new-inquiry`, options)
      .then(response => {
        response.json()
        if (response.status === 200 || response.status === 201) {
          return fetch(`https://www.back.tsf.konecton.com/join-us-inquiries/mail/notifications`, optionsNotification)
        } else {
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Une erreur est survenue lors de l\'envoi de votre demande',
            timer: 1500
          });
          return Promise.reject('Erreur lors de l\'envoi de la demande')
        }
      }).then(response2 => {
        response2.json()
        if (response2.status === 200) {

          resetProfilInputs()
          resetRequestInputs()
          handlePage("+")
          Swal.fire({
            icon: 'success',
            title: 'Votre demande a bien été envoyée',
            timer: 1500
          })
        } else {
          setSubmitionLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Une erreur est survenue lors de l\'envoi de votre demande',
            timer: 1500
          });
        }
      })
      .catch(err =>
        console.error(err)
      )
  }

  // TEMPORARY SUMBITION FUNCTION
  // const generateMailtoLink = () => {

  //   // Mail model for benevolent request
  //   const mailtoLinkBenevolent = `mailto:tsf.direction@gmail.com?subject=Demande de ${benevolentRequest}
  //   &from=${benevolentEmail}
  //   &from%20${benevolentFirstname}%20${benevolentLastname} (${benevolentAge} ans)
  //   &body=
  // ${benevolentFirstname} ${benevolentLastname}
  // Email: ${benevolentEmail}
  // Téléphone: ${benevolentPhoneNumber}

  // Type de demande: ${benevolentRequest}

  // ${benevolentActions.length > 0 ? `Actions qui vous intéressent: ${benevolentActions.join(', ')}` : 'Actions qui vous intéressent: aucune'}
  // ${benevolentCommitments.length > 0 ? `Engagements qui vous intéressent: ${benevolentCommitments.join(', ')}` : 'Engagements qui vous intéressent: aucun'}
  // ${benevolentProject !== "" ? `Votre projet: ${benevolentProject.replace('&', 'et')}` : 'Votre projet: non renseigné'}

  // ${benevolentComment !== "" ? `Commentaire: ${benevolentComment.replace('&', 'et')}` : 'Commentaire: non renseigné'}
  // `;

  //   // Mail model for internship request
  //   const mailtoLinkInternship = `mailto:tsf.direction@gmail.com?subject=Demande de ${benevolentRequest}
  //   &from=${benevolentEmail}
  //   &from%20${benevolentFirstname}%20${benevolentLastname} (${benevolentAge} ans)
  //   &body=
  // ${benevolentFirstname} ${benevolentLastname}
  // Email: ${benevolentEmail}
  // Téléphone: ${benevolentPhoneNumber}

  // Type de demande: ${benevolentRequest}

  // ${benevolentStartDate !== "" ? `Date de début souhaitée: le ${intlFormat(new Date(benevolentStartDate))}` : 'Date de début souhaitée: non renseignée'}
  // ${benevolentEndDate !== "" ? `Date de fin souhaitée: le ${intlFormat(new Date(benevolentEndDate))}` : 'Date de fin souhaitée: non renseignée'}

  // ${benevolentSchool ? `Ecole: ${benevolentSchool}` : 'Ecole: non renseignée'}
  // ${benevolentCursus ? `Cursus: ${benevolentCursus}` : 'Cursus: non renseigné'}
  // ${benevolentHasConvention !== "" && `Convention de stage: ${benevolentHasConvention ? 'Oui' : 'Non'}`}

  // ${benevolentComment !== "" ? `Commentaire: ${benevolentComment.replace('&', 'et')}` : 'Commentaire: non renseigné'}`;

  //   // Mail model for civic service request
  //   const mailtoLinkCivic = `mailto:tsf.direction@gmail.com?subject=Demande de ${benevolentRequest}
  //   &from=${benevolentEmail}
  //   &from%20${benevolentFirstname}%20${benevolentLastname} (${benevolentAge} ans)
  //   &body=
  // ${benevolentFirstname} ${benevolentLastname}
  // Email: ${benevolentEmail}
  // Téléphone: ${benevolentPhoneNumber}

  // Type de demande: ${benevolentRequest}

  // ${benevolentStartDate !== "" ? `Date de début souhaitée: le ${intlFormat(new Date(benevolentStartDate))}` : 'Date de début souhaitée: non renseignée'}
  // ${benevolentEndDate !== "" ? `Date de fin souhaitée: le ${intlFormat(new Date(benevolentEndDate))}` : 'Date de fin souhaitée: non renseignée'}
  // ${benevolentMission !== "" ? `Mission du service civique: ${benevolentMission}` : 'Mission du service civique: non renseignée'}

  // ${benevolentComment !== "" ? `Commentaire: ${benevolentComment.replace('&', 'et')}` : 'Commentaire: non renseigné'}`;

  //   if (benevolentRequest === 'bénévolat') {
  //     return encodeURI(mailtoLinkBenevolent);
  //   } else if (benevolentRequest === 'stage') {
  //     return encodeURI(mailtoLinkInternship);
  //   } else if (benevolentRequest === 'service civique') {
  //     return encodeURI(mailtoLinkCivic);
  //   }
  // };

  // const handleSendEmail = (event) => {
  //   event.preventDefault();
  //   const mailtoURL = generateMailtoLink();
  //   window.location.href = mailtoURL;
  //   confirmationMessage()
  // };
  // END TEMPORARY SUMBITION FUNCTION

  const confirmationMessage = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2 tsf-text",
        cancelButton: "btn btn-secondary mx-2 tsf-text",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: 'CONFIRMER L\'ENVOI DE VOTRE DEMANDE',
      text: 'Notre équipe vous recontactera dans les plus brefs délais',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'JE N\'AI PAS ENVOYÉ',
      confirmButtonText: `JE CONFIRME`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handlePage("+")
      } else {
        Swal.close()
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='JoinUsForm tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='tsf-content-container join-us-container'>
        {currentFormPage < 5 && <div className='join-us-form-top-page-indicator tsf-text'>
          {currentFormPage}/4
        </div>}
        {submitionLoading &&
          <div className='tsf-loader-container'>
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{ backgroundColor: 'black' }}
              wrapperClass=""
            />
          </div>
        }
        {/* PAGE 1 : PRESENTATION TSF BENEVOLENT */}
        {currentFormPage === 1 &&
          <div className='join-us-form-global'>
            <div className='join-us-form-header-image'>
              <img src={logo_tsf_benevolent} alt='TSF' />
            </div>
            <p><b>Therapy Sans Frontières</b> est une association (loi 1901), créée en 2021.<br /> L’association est née d’une volonté de promouvoir <b>l’accès à la santé mentale et de lutter pour une meilleure justice sociale.</b></p>
            <p>Nous nous engageons à défendre les droits humains et militons contre toutes les formes de violences et de discriminations.</p>
            <p>Les actions menées ont pour objectif de promouvoir, de sensibiliser, de former et de développer des projets qui portent sur la prévention, le diagnostic situationnel et personnel, le traitement et la prise en charge de personnes en détresse psychique et/ou sociale : celles-ci prennent en compte les besoins et les attentes du public vulnérable*.</p>
            <p className='app-form-presentation-info'>*TSF a développé un outil de diagnostic interne afin de définir des seuils de vulnérabilité</p>
          </div>
        }

        {/*  PAGE 2 : nom ; prenom ; email ; téléphone ; age ; position */}
        {currentFormPage === 2 &&
          <div className='join-us-form-global app-form-2'>
            <h3>A propos de vous :</h3>
            <Form className='join-us-form-container'>
              <div className='join-us-profile-input-row'>
                <Form.Group className="join-us-profile-input-group" controlId="benevolentFormFirstname">
                  <Form.Label>Votre prénom :
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: Emma" className={`${missingFields && !benevolentFirstname ? 'input-wrong' : 'input-init'} form-input`} value={benevolentFirstname ? benevolentFirstname : ""} onChange={(e) => setBenevolentFirstname(e.target.value)} />
                  {(missingFields && !benevolentFirstname) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
                <Form.Group className="join-us-profile-input-group" controlId="benevolentFormLastname">
                  <Form.Label>Votre nom :
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: Dupont" className={`${missingFields && !benevolentLastname ? 'input-wrong' : 'input-init'} form-input`} value={benevolentLastname ? benevolentLastname : ""} onChange={(e) => setBenevolentLastname(e.target.value)} />
                  {(missingFields && !benevolentLastname) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
              </div>
              <div className='join-us-profile-input-row'>
                <Form.Group className="join-us-profile-input-group" controlId="benevolentFormAge">
                  <Form.Label>Votre age :
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: 27 ans" className={`${missingFields && !benevolentAge ? 'input-wrong' : 'input-init'} form-input`} value={benevolentAge ? benevolentAge : ""} onChange={(e) => setBenevolentAge(e.target.value)} />
                  {(missingFields && !benevolentAge) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
                <Form.Group className="mb-3 join-us-profile-input-group" controlId="benevolentFormPhone">
                  <Form.Label>Votre téléphone :
                  </Form.Label>
                  <Form.Control type="text" placeholder="ex: 0601020304" className={`${missingFields && !benevolentPhoneNumber ? 'input-wrong' : 'input-init'} form-input`} value={benevolentPhoneNumber ? benevolentPhoneNumber : ""} onChange={(e) => setBenevolentPhoneNumber(e.target.value)} />
                  {(missingFields && !benevolentPhoneNumber) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
              </div>
              <div className='join-us-profile-input-row'>
                <Form.Group className="join-us-profile-input-group-full" controlId="benevolentFormEmail">
                  <Form.Label>Votre email :
                  </Form.Label>
                  <Form.Control type="email" placeholder="ex: emma.dupont@gmail.com" className={`${missingFields && !benevolentEmail ? 'input-wrong' : 'input-init'} form-input`} value={benevolentEmail ? benevolentEmail : ""} onChange={(e) => setBenevolentEmail(e.target.value)} />
                  {(missingFields && !benevolentEmail) && <Form.Text className="text-danger">
                    Champ manquant requis
                  </Form.Text>}
                </Form.Group>
              </div>
              <div className='join-us-profile-input-column'>
                <Form.Label>De quelle manière souhaitez vous nous rejoindre ?
                </Form.Label>
                <Form.Group className="join-us-profile-radio-group-full" controlId="benevolentFormAge">
                  <div className='join-us-profile-input-row-radio'>
                    <div
                      className={`join-us-profile-radio-group-element tsf-text ${benevolentRequest === 'bénévolat' && 'join-us-profile-radio-group-element-active'}`}
                      onClick={() => handleRequestChange('bénévolat')}
                    >
                      Bénévolat
                    </div>
                    <div
                      className={`join-us-profile-radio-group-element tsf-text ${benevolentRequest === 'stage' && 'join-us-profile-radio-group-element-active'}`}
                      onClick={() => handleRequestChange('stage')}
                    >
                      Stage
                    </div>
                    <div
                      className={`join-us-profile-radio-group-element tsf-text ${benevolentRequest === 'service civique' && 'join-us-profile-radio-group-element-active'}`}
                      onClick={() => handleRequestChange('service civique')}
                    >
                      Service civique
                    </div>
                  </div>
                </Form.Group>
                {(missingFields && !benevolentRequest) && <Form.Text className="text-danger">
                  Sélection requise
                </Form.Text>}
              </div>
            </Form>
          </div>
        }

        {currentFormPage === 3 &&
          <div className='join-us-form-global'>
            {benevolentRequest === 'bénévolat' ?
              <JoinUsBenevolent
                actions={benevolentActions}
                commitments={benevolentCommitments}
                comment={benevolentComment}
                handleArrayActions={handleArrayActions}
                handleTextActions={handleTextActions}
                project={benevolentProject}
              />
              :
              benevolentRequest === 'stage' ?
                <JoinUsInterns
                  comment={benevolentComment}
                  cursus={benevolentCursus}
                  endDate={benevolentEndDate}
                  handleBooleanActions={handleBooleanActions}
                  handleDates={handleDates}
                  handleTextActions={handleTextActions}
                  hasConvention={benevolentHasConvention}
                  school={benevolentSchool}
                  startDate={benevolentStartDate}
                />
                :
                <JoinUsCivic
                  comment={benevolentComment}
                  endDate={benevolentEndDate}
                  handleDates={handleDates}
                  handleTextActions={handleTextActions}
                  mission={benevolentMission}
                  startDate={benevolentStartDate}
                />
            }
          </div>
        }

        {currentFormPage === 4 &&
          <div className='join-us-form-global'>
            <table className='join-form-summary-table'>
              <thead>
                <tr>
                  <td className='tsf-text join-us-summary-title'>Récapitulatif de votre demande :</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{benevolentLastname}</td>
                  <td>{benevolentFirstname}</td>
                </tr>
                <tr>
                  <td>{benevolentEmail}</td>
                  <td>{benevolentPhoneNumber}</td>
                </tr>
                <tr>
                  <td>{benevolentAge} ans</td>
                  <td>{benevolentRequest}</td>
                </tr>
                {benevolentRequest === 'bénévolat' ?
                  <>
                    <tr>
                      <td><i>Nos actions qui vous intéressent :</i><br />
                        <ul>
                          {benevolentActions.map((action, index) =>
                            <li key={index}>{action}</li>)}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><i>Nos engagements qui vous intéressent :</i><br />
                        <ul>
                          {benevolentCommitments.map((commitment, index) =>
                            <li key={index}>{commitment}</li>)}
                        </ul>
                      </td>
                    </tr>
                    {benevolentProject &&
                      <tr>
                        <td><i>Votre projet :</i><br />
                          {benevolentProject}
                        </td>
                      </tr>
                    }
                  </>
                  :
                  benevolentRequest === 'stage' ?
                    <>
                      <tr>
                        <td><i>Date de début souhaitée :</i><br /> {benevolentStartDate ? intlFormat(new Date(benevolentStartDate)) : 'Non renseignée'}</td>
                        <td><i>Date de fin :</i><br /> {benevolentEndDate ? intlFormat(new Date(benevolentEndDate)) : 'Non renseignée'}</td>
                      </tr>
                      <tr>
                        <td>{benevolentSchool ? benevolentSchool : 'École non renseignée'}</td>
                        <td>{benevolentCursus ? benevolentCursus : 'Cursus non renseigné'}</td>
                      </tr>
                      <tr>
                        <td><i>{benevolentHasConvention ? 'Vous avez une convention de stage' : `Vous n'avez pas de convention de stage`}</i></td>
                      </tr>
                    </>
                    :
                    <>
                      <tr>
                        <td><i>Date de début souhaitée :</i><br /> {benevolentStartDate ? intlFormat(new Date(benevolentStartDate)) : 'Non renseignée'}</td>
                        <td><i>Date de fin :</i><br /> {benevolentEndDate ? intlFormat(new Date(benevolentEndDate)) : 'Non renseignée'}</td>
                      </tr>
                      <tr>
                        <td><i>Mission du service civique : </i><br />{benevolentMission ? benevolentMission : 'Non renseigné'}</td>
                      </tr>
                    </>
                }
                {benevolentComment &&
                  <tr>
                    <td><i>Commentaire:</i><br /></td>
                    <td>{benevolentComment ? benevolentComment : 'Non renseigné'}</td>
                  </tr>
                }
              </tbody>
            </table>
            <div className='conditions-app-form'>
              <input
                type='checkbox'
                name='conditions'
                id='conditions'
                checked={conditionsValue}
                onChange={() => setConditionsValue(!conditionsValue)}
              />
              <label htmlFor='conditions'>
                En cochant cette case, vous acceptez que l’association Therapy Sans Frontières utilisent et conversent uniquement pour elle les informations figurant sur ce formulaire afin de répondre à votre demande dans les plus brefs délais. Vos informations ne sont en aucun cas transmises à des tiers.
              </label>
            </div>
          </div>
        }

        {/*  PAGE 5 : REMERCIEMENT | Confirmation, image, bouton de retour */}
        {currentFormPage === 5 &&
          <div className='app-form-global app-form-7'>
            {/* <p>Un email de confirmation vous a été envoyé <IoMdCheckmark /></p> */}
            <div className='app-form-thankyou'>
              <img src={thank_you_logo} alt='thank you' />
            </div>
            <h5>Notre équipe vous recontactera prochainement.</h5>
            <Link to='/'><div className='app-form-thankyou-btn tsf-button-shadow tsf-text'><IoMdArrowRoundBack className='app-form-thankyou-btn-icon' /> Retour au site</div></Link>
          </div>
        }
        {currentFormPage < 5 &&
          <>
            <div className='buttons-container'>
              <Button className='tsf-text' onClick={() => handlePage("-")} disabled={currentFormPage === 1}><IoMdArrowRoundBack /> Précédent</Button>
              {currentFormPage < 4 ?
                <Button className='tsf-text' onClick={() => handlePage("+")}>Suivant <IoMdArrowRoundForward /></Button>
                :
                currentFormPage === 4 ?
                  <Button className='btn-success tsf-text' disabled={!conditionsValue} onClick={(e) => handleSubmit(e)}>Confirmer <IoMdCheckmark /></Button>
                  // <Button className='btn-success tsf-text' disabled={!conditionsValue} onClick={(e) => handleSendEmail(e)}>Confirmer <IoMdCheckmark /></Button>
                  :
                  null
              }
            </div>
            {
              currentFormPage < 5 && <>
                <div className='progressbar-bg'>
                  <div className={`progressbar progressbar-benevolent-${currentFormPage}`}></div>
                </div>
                <div className='progressbar-bg-info-container tsf-text'>
                  <p className='progressbar-bg-info'>{currentFormPage}/4 - {JoinUsFormProgressbarList[currentFormPage - 1].text}</p>
                </div>
              </>
            }
          </>
        }
      </div>
      <Footer />
    </div>
  )
}
