import React, { useEffect } from 'react'

// assets
import hands_img from '../../assets/images/decorations/high-five-hands-rafiki.png'
import success_img from '../../assets/images/decorations/Powerful-pana.png'

// components
import { BecomePartnerCard } from '../../components/uikit/Cards';
import { Divider } from '../../components/uikit/Decorations';
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import PartnerBonus from '../../components/PartnerBonus';

// styles
import '../../styles/supportUs/BecomePartner.css'

// utils
import { BecomeParnerList, BecomePartnerBonusList } from '../../utils/BecomePartnerList';
import { isMobileScreen } from '../../utils/Sizes'

export default function BecomePartner() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='BecomePartner tsf-global-screen'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='become-partner-container tsf-content-container'>
        <div className='become-partner-header'>
          <h1>Devenez Partenaire Engagé ou Grand Mécène</h1>
          <img src={hands_img} alt='high five' />
          {/* <p>Sunt anim exercitation veniam occaecat reprehenderit eu. Fugiat magna in aliquip veniam nisi exercitation reprehenderit est enim ut. Esse deserunt mollit labore sint minim excepteur cupidatat. Anim cillum Lorem laboris anim duis labore reprehenderit sunt eiusmod. Consequat ipsum minim dolore id voluptate velit irure sunt ad velit mollit esse incididunt exercitation. Qui veniam laboris eiusmod in et dolore mollit do laboris.</p> */}
        </div>
        <Divider />
        <div className='become-partner-content-container'>
          <div className='become-partner-content-header-container'>
            <div className='become-partner-content-header'>
              <h2>ENGAGEZ-VOUS POUR L'ACCÈS À LA SANTÉ MENTALE</h2>
            </div>
            <div className='become-partner-content-values-list'>
              {BecomePartnerBonusList?.map((e) => (
                <PartnerBonus {...e} />
              ))}
            </div>
            <div className='become-partner-offers-list'>
              {BecomeParnerList.map((partner) =>
                <BecomePartnerCard {...partner} />
              )}
            </div>
          </div>
        </div>
        <div className='become-partner-bottom-image'>
          <img src={success_img} alt='success people' />
        </div>
      </div>
      <Footer />
    </div >
  )
}
