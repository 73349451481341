export const monthlyHelpList = [
  {
    id: 1,
    price: 8,
    image: require('../assets/images/decorations/Powerful-pana.png'),
    message: 'En soutien à nos actions',
    type: 'monthly'
  },
  {
    id: 2,
    price: 15,
    image: require('../assets/images/decorations/Self-confidence-pana-1.png'),
    message: 'En soutien à nos actions',
    type: 'monthly'
  },
  {
    id: 3,
    price: 20,
    image: require('../assets/images/decorations/Goal-pana.png'),
    message: 'En soutien à nos actions',
    type: 'monthly'
  },
  {
    id: 4,
    price: 50,
    image: require('../assets/images/decorations/Powerful-cuate.png'),
    message: 'En soutien à nos actions',
    type: 'monthly'
  }
]

export const oneTimeHelpList = [
  {
    id: 1,
    price: 20,
    image: require('../assets/images/decorations/Goal-pana.png'),
    message: 'En soutien à nos actions',
    type: 'one-time'
  },
  {
    id: 2,
    price: 50,
    image: require('../assets/images/decorations/Powerful-pana.png'),
    message: 'En soutien à nos actions',
    type: 'one-time'
  },
  {
    id: 3,
    price: 100,
    image: require('../assets/images/decorations/Powerful-cuate.png'),
    message: 'En soutien à nos actions',
    type: 'one-time'
  },
  {
    id: 4,
    price: 'ce que je veux',
    image: require('../assets/images/decorations/Self-confidence-pana-1.png'),
    message: 'En soutien à nos actions',
    type: 'one-time'
  }
]
