import React, { useState } from 'react'

// icons
import { FaHandPointRight } from "react-icons/fa";
import { FaLinkedin, FaStarOfLife } from "react-icons/fa6"
import { IoCalendarNumberOutline, IoTrashBinOutline } from "react-icons/io5";
import { IoMdCheckmark, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"

// packages
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'

//styles 
import '../../styles/uikit/Cards.css'

// uikit
import { TSFButton } from './Buttons'

// utils
import { deductionCalculator } from "../../utils/CalculatorsFunctions"
import { SupportUsDonationsCardList } from "../../utils/DonationsList";
import { isSmallScreen } from '../../utils/Sizes';

export const BecomePartnerCard = ({ id, title, priceTitle, price, percentage, bonus, link, color, actionBtn }) => (
  <div key={id} className={`become-partner-card-container become-partner-card-${color}`}>
    <div className='become-partner-card-header'>
      <div className='become-partner-card-header-title-container'>
        <span className='become-partner-card-header-subtitle'>Devenez</span>
        <p className='become-partner-card-header-title tsf-text'>{title}</p>
      </div>
      <div className='become-partner-card-header-info-container'>
        <p className='become-partner-card-header-price'>{priceTitle}</p>
        <p className='become-partner-card-header-info'>soit {deductionCalculator(price, percentage)}€ après déduction d'impôts</p>
      </div>
    </div>
    <div className='become-partner-card-content-container'>
      <ul>
        {bonus.map((b, index) => (

          <li key={index}><span className='become-partner-card-list-type'><FaStarOfLife /> </span><span dangerouslySetInnerHTML={{ __html: b }}></span></li>
        ))}
      </ul>
      <Link to={link} target='_blank'><button className='become-partner-card-button tsf-button-shadow tsf-text'>{actionBtn}</button></Link>
    </div>
  </div>
)

export const DonationCard = ({ title, text1, text2, image, altImage, color }) => (
  <div className={`donation-card donation-card-${color}`}>
    <h3>{title}</h3>
    <p>{text1}</p>
    <p>{text2}</p>
    <img src={image} className='donation-card-img' alt={altImage} />
  </div>
)

export const BecomeHomeCard = ({ text, link, color, inApp }) => {
  return (
    <>
      {inApp ?
        <Link to={link} className='become-home-card tsf-text tsf-button-shadow' style={{ backgroundColor: color, color: 'white' }}>
          {text}
        </Link>
        :
        <Link target='_blank' to={link} className='become-home-card tsf-text tsf-button-shadow' style={{ backgroundColor: color, color: 'white' }}>
          {text}
        </Link>
      }
    </>
  )
}

export const AdminRequestsCard = ({
  booking_date = null,
  booking_time = null,
  email,
  done_date = null,
  firstname,
  id,
  is_interested_subscription = null,
  is_member = null,
  lastname,
  message,
  newsletter_authorized = null,
  occupation = null,
  phone_number,
  subscription = null,
  status,
  submitted_date,
  type }) => {
  const { intlFormat } = require("date-fns");

  const updateEmailRequest = async (emailId) => {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`https://www.back.tsf.konecton.com/email-inquiries/${emailId}`, options)
        .then(response => response.json())
        .then(data => {
          Swal.fire({
            title: 'Demande traitée',
          }).then(() => {
            window.location.reload()
          })
        })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <div className={`admin-requests-card-container ${status ? 'admin-requests-card-done' : 'admin-requests-card-ongoing'}`}>
        <div className='admin-requests-card-row'>
          <p className='admin-requests-card-row-element'>{lastname}</p>
          <p className='admin-requests-card-row-element'>{firstname}</p>
          <p className='admin-requests-card-row-element'>{email}</p>
        </div>
        <div className='admin-requests-card-row'>
          <p className='admin-requests-card-row-element'>{phone_number}</p>
          <p className='admin-requests-card-row-element'>Reçue le : {intlFormat(submitted_date)}</p>
          <p className='admin-requests-card-row-element'><b>{status ? `Traitée le ${done_date && intlFormat(done_date)}` : 'En cours'}</b></p>
        </div>

        {/* Newsletter from email form */}
        {newsletter_authorized !== null &&
          <div className='admin-requests-card-row admin-requests-card-row-message'>
            <p className='admin-requests-card-row-element'>{newsletter_authorized ? 'A accepté les newsletters' : 'N\'a pas accepté les newslettres'}</p>
          </div>
        }

        {/* Benevolent or Pro request */}
        {booking_date !== null && booking_time !== null &&
          <div className='admin-requests-card-row'>
            <p className='admin-requests-card-row-element'>Date de RDV demandée : le {intlFormat(booking_date)}</p>
            <p className='admin-requests-card-row-element'>Créneau demandé : {booking_time}</p>
          </div>
        }

        {occupation !== null && is_member !== null &&
          <div className='admin-requests-card-row'>
            <p className='admin-requests-card-row-element'>{occupation}</p>
            <p className='admin-requests-card-row-element'>{is_member ? 'Est adhérent' : 'N\'est pas adhérent'}</p>
          </div>
        }

        {(subscription !== null || is_interested_subscription !== null) &&
          <div className='admin-requests-card-row'>
            <p className='admin-requests-card-row-element'>{subscription ? `Forfait ${subscription}` : 'Ne dispose pas de forfait'}</p>
            {!subscription && <p className='admin-requests-card-row-element'>{is_interested_subscription ? 'Est intéressé par un forfait d\'essai gratuit' : 'N\'est pas directement intéressé par un essai gratuit'}</p>}
          </div>
        }

        <div className='admin-requests-card-row admin-requests-card-row-message'>
          <p className='admin-requests-card-row-element'><i>Message :</i><br />{message}</p>
        </div>

        {/* Link to complete the profile for email and pro requests */}
        {(type === 'pro' || type === 'benevolent') &&
          <div className='admin-requests-card-handle-button-container'>
            <Link to={`/tsf-admin/${type}/complete/${id}`} className='admin-requests-card-handle-button tsf-text tsf-button-shadow' onClick={(e) => e.preventDefault}>{status === 1 ? 'Modifier le profil' : 'Compléter le profil'}</Link>
          </div>
        }

        {/* Button to update the email request status to done */}
        {(type === 'emails' && status === 0) &&
          <div className='admin-requests-card-handle-button-container'>
            <button className='admin-requests-card-handle-button tsf-text tsf-button-shadow' onClick={() => updateEmailRequest(id)}>Cloturer la demande <IoMdCheckmark /></button>
          </div>
        }
      </div>
    </>
  )
}

export const AdminJoinUsRequestsCard = (props) => {
  const { intlFormat } = require("date-fns");
  const commitmentsList = props.commitments.split(',')
  const actionsList = props.actions.split(',')

  const handleRequestDone = async (id) => {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`https://www.back.tsf.konecton.com/join-us-inquiries/${id}`, options)
        .then(response => response.json())
        .then(() => {
          Swal.fire({
            title: 'Demande terminée',
          }).then(() => {
            window.location.reload()
          })
        })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={`admin-requests-card-container ${props.status ? 'admin-requests-card-done' : 'admin-requests-card-ongoing'}`}>
      <div className='admin-requests-card-row'>
        <p className='admin-requests-card-row-element'>{props.lastname}</p>
        <p className='admin-requests-card-row-element'>{props.firstname}</p>
        <p className='admin-requests-card-row-element'>{props.email}</p>
      </div>
      <div className='admin-requests-card-row'>
        <p className='admin-requests-card-row-element'>{props.phone_number}</p>
        <p className='admin-requests-card-row-element'><i>Reçue le :</i> {intlFormat(props?.submitted_date)}</p>
        <p className='admin-requests-card-row-element'><b>{props.request}</b></p>
        <p className='admin-requests-card-row-element'><b>{props.status ? 'Traitée' : 'En cours'}</b></p>
      </div>
      {props.request === 'stage' ?
        <>
          <div className='admin-requests-card-row'>
            <p className='admin-requests-card-row-element'><i>Date de début :</i> {intlFormat(props.start_date)}</p>
            <p className='admin-requests-card-row-element'><i>Date de fin :</i> {intlFormat(props.end_date)}</p>
            <p className='admin-requests-card-row-element'><i>Convention de stage :</i> {props.has_convention ? 'Oui' : 'Non'}</p>
          </div>
          <div className='admin-requests-card-row'>
            <p className='admin-requests-card-row-element'><i>Établissement :</i> {props?.school}</p>
            <p className='admin-requests-card-row-element'><i>Cursus :</i> {props.cursus}</p>
          </div>
        </>
        :
        props.request === 'bénévolat' ?
          <>
            <div className='admin-requests-card-row-element admin-requests-card-row-message'>
              <p><i>Nos Actions qui l'intéressent :</i></p>
              <ul>
                {actionsList.map((e, index) => <li key={index}>{e}</li>)}
              </ul>
            </div>
            <div className='admin-requests-card-row-element  admin-requests-card-row-message'>
              <p><i>Nos Engagements désirés :</i></p>
              <ul>
                {commitmentsList.map((e, index) => <li key={index}>{e}</li>)}
              </ul>
            </div>
            <div className='admin-requests-card-row  admin-requests-card-row-message'>
              <p className='admin-requests-card-row-element'>
                <i>Précision du projet :</i><br />
                {props.project ? props.project : 'Non précisé'}
              </p>
            </div>
          </>
          :
          <>
            <div className='admin-requests-card-row'>
              <p className='admin-requests-card-row-element'><i>Date de début :</i> {props.startDate}</p>
              <p className='admin-requests-card-row-element'><i>Date de fin :</i> {props.endDate}</p>
            </div>
            <div className='admin-requests-card-row admin-requests-card-row-message'>
              <p className='admin-requests-card-row-element'><i>Mission :</i><br /> {props.mission}</p>
            </div>
          </>
      }
      <div className='admin-requests-card-row admin-requests-card-row-message'>
        <p className='admin-requests-card-row-element'><i>Message :</i><br />{props.comment ? props.comment : 'Pas de message'}</p>
      </div>
      {!props.status &&
        <div className='admin-requests-card-handle-button-container'>
          <button className='admin-requests-card-handle-button tsf-text tsf-button-shadow' onClick={() => handleRequestDone(props.id)}>Cloturer la demande  <IoMdCheckmark /></button>
        </div>
      }
    </div>
  )
}

export const NewsTSFCard = ({ content, date_news, date_submitted, id, image, link, title, isAdmin = false, isPage = false }) => {
  const { intlFormat } = require("date-fns");
  const isMobile = isSmallScreen()
  const removeNews = async (id) => {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const query = `https://www.back.tsf.konecton.com/news-tsf/delete/${id}`

      fetch(query, options)
        .then(response => response.json())
        .then(data => {
          Swal.fire({
            icon: 'success',
            title: 'News supprimée',
            timer: 1500
          });
          window.location.reload()
        })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {isMobile ?
        <div>
          <div className='news-tsf-card-container-mobile'>
            <p className='news-tsf-card-title'>{title}</p>
            <div className='news-tsf-card-image-container-mobile'>
              {image && <img src={image} alt='news' className='news-tsf-card-image-mobile' />}
            </div>
            <div className='news-tsf-card-text-container-mobile'>
              <p className='news-tsf-card-date'><IoCalendarNumberOutline className='news-tsf-card-date-icon' />{intlFormat(date_news)}</p>
              <Link to={link} target='_blank' className='news-tsf-card-link'><FaLinkedin className='news-tsf-card-link-icon' /><span className='news-tsf-card-link-title'>Voir le post sur LinkedIn</span></Link>
              <div className='tsf-divider' />
              {content && <div className='news-tsf-card-content-mobile' dangerouslySetInnerHTML={{ __html: content }}></div>}
            </div>
          </div>
        </div>

        :

        <div className='news-tsf-card-container'>
          <div className='news-tsf-card-left-container'>
            {image && <img src={image} alt='news' className='news-tsf-card-image' />}
          </div>
          <div className='news-tsf-card-right-container'>
            <p className='news-tsf-card-title'>{title}</p>
            <p className='news-tsf-card-date'><IoCalendarNumberOutline className='news-tsf-card-date-icon' />{intlFormat(date_news)}</p>
            <Link to={link} target='_blank' className='news-tsf-card-link'><FaLinkedin className='news-tsf-card-link-icon' /><span className='news-tsf-card-link-title'>Voir le post sur LinkedIn</span></Link>
            <div className='tsf-small-divider' />
            {content && <div className='news-tsf-card-content' dangerouslySetInnerHTML={{ __html: content }}></div>}
          </div>
        </div>
      }
    </>
  )
}

export const CVCard = ({ content, date, id, link, type }) => {
  const { intlFormat } = require("date-fns");

  return (
    <div className='cv-article-container' key={id}>
      <div className='cv-article-date'>
        {intlFormat(date)}
      </div>
      <div className='cv-article-type'>
        {type}
      </div>
      <div className='cv-article-content'>
        {content}
      </div>
      {link && <div className='cv-article-link'>{link}</div>}
    </div>
  )
}

export const SupportUsCard = ({ btnTitle, image, link, text, title }) => {

  return (
    <div className='support-us-card'>
      <h2 className='support-us-card-title'>{title}</h2>
      <div className='support-us-card-image-container'>
        <img src={image} alt={title} />
      </div>
      {title === 'Faire un don' &&
        <div className='support-us-card-donations-info-container'>
          {SupportUsDonationsCardList.map((donation, index) => (
            <div key={index} className='support-us-card-donations-info' style={{ backgroundColor: donation.color }}>
              <h3 className='support-us-card-donations-subtitle'>{donation.subtitle1}</h3>
              <h4 className='support-us-card-donations-subtitle-2'>{donation.subtitle2}</h4>
              <p className='support-us-card-donations-text'>{donation.text}</p>
            </div>
          ))}
        </div>
      }
      <p className='support-us-card-text'>{text}</p>
      <Link to={link} className='support-us-card-button tsf-button-shadow'>{btnTitle}</Link>
    </div>
  )
}

export const MemberCard = ({ cv, firstname, id, image, lastname, longDescription, occupation, shortDescription }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <>
      <div className={`members-card-container`} key={id}>
        <div className='members-card-top'>
          <div className='members-card-image'>
            <img src={image} alt={`${firstname} ${lastname}`} />
          </div>
          <div className='members-card-top-info'>
            <span className='members-card-top-name tsf-text'>{firstname} {lastname}</span>
            <span className='members-card-top-occupation'>{occupation}</span>
          </div>
        </div>
        <div className='members-card-description'>
          {shortDescription}<br />
          {showDetails && longDescription}
          <button onClick={() => setShowDetails(!showDetails)} className='tsf-button tsf-button-shadow members-card-more-button'>Voir {showDetails ? <>moins <IoMdArrowDropup /></> : <>plus <IoMdArrowDropdown /></>}</button>
        </div>
        {cv &&
          <Link to={cv} className='members-card-cv-link'><FaHandPointRight className='members-card-cv-icon' />Mon parcours</Link>
        }
      </div>
      <div className='members-card-divider' />
    </>
  )
}

export const BenevolentMemberCard = ({ firstname, id, image, lastname, longDescription, occupation, setCardDetails, setShowDetails, shortDescription }) => {

  const handleClicked = () => {
    setCardDetails({ firstname: firstname, id: id, image: image, lastname: lastname, longDescription: longDescription, occupation: occupation })
    setShowDetails(true)
  }

  return (
    <>
      <div className={`benevolent-members-card-container`} key={id}>
        <div className='benevolent-members-card-top'>
          <div className='benevolent-members-card-image'>
            <img src={image} alt={`${firstname} ${lastname}`} />
          </div>
          <div className='benevolent-members-card-top-info'>
            <span className='benevolent-members-card-top-name tsf-text'>{firstname} {lastname}</span>
            <span className='benevolent-members-card-top-occupation'>{occupation}</span>
          </div>
        </div>
        <div className='benevolent-members-card-divider' />
        <div className='benevolent-members-card-description'>
          {shortDescription}
        </div>
        <button className='tsf-button tsf-button-shadow benevolent-members-card-button' onClick={() => handleClicked()}>Voir plus</button>
        {/* <TSFButton color='third' text='Voir plus' onClick={() => handleClicked()} /> */}
        {/* <button className='tsf-button tsf-button-shadow' onClick={() => handleClicked()}>Voir plus</button> */}
      </div>
    </>
  )
}
