import React, { useState } from 'react'

//components
import AdminLateralSidebar from '../components/AdminLateralSidebar'
import WorkInProgress from '../../components/WorkInProgress'

export default function AdminStaff() {
  const [menuOpen, setMenuOpen] = useState(true)

  return (
    <div className={`AdminStaff ${menuOpen ? 'menuOpen' : 'menuClose'}`}>
      <AdminLateralSidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <WorkInProgress />
    </div>
  )
}
