import React from 'react'

// assets
import teamWork from '../../assets/images/decorations/Team-work-puzzle.png'

// components
import { BecomeHomeCard } from '../uikit/Cards'

// styles
import '../../styles/home/HomeJoinUs.css'

export default function HomeJoinUs() {

  return (
    <div className='tsf-home-page-nb HomeJoinUs'>
      <div className='tsf-home-page-content home-join-us-container'>
        <img src={teamWork} alt='join us' className='home-join-us-image' />
        <h2>Rejoignez-nous</h2>
        <div className='home-join-us-cards-container'>
          <BecomeHomeCard text='faites un don' link='https://www.helloasso.com/associations/therapy-sans-frontieres/formulaires/1' color='#E7093D' inApp={false} />
          <BecomeHomeCard text='devenez bénévole' link='https://www.helloasso.com/associations/therapy-sans-frontieres/adhesions/bulletin-d-adhesion-tsf' color='#008000' inApp={true} />
        </div>
      </div>
      {/* </div> */}

    </div>
  )
}
