import React, { useEffect, useState } from 'react'

// assets
import help_img from '../../assets/images/news/news-help.jpg'

// components
import AdminLateralSidebar from '../components/AdminLateralSidebar'
import { NewsTSFCard } from '../../components/uikit/Cards'

// icons
import { FaPlus, FaRegHandPointUp } from "react-icons/fa6";
import { IoIosClose } from 'react-icons/io';

// packages
import { RotatingLines } from 'react-loader-spinner'
import Swal from 'sweetalert2'

// styles
import '../styles/globalAdmin.css'

export default function AdminNewsTSF() {
  const [filterChoice, setFilterChoice] = useState(null)
  const [helpModal, setHelpModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [menuOpen, setMenuOpen] = useState(true)
  const [news, setNews] = useState()
  const [newsContent, setNewsContent] = useState()
  const [newsDate, setNewsDate] = useState()
  const [newsImage, setNewsImage] = useState()
  const [newsLink, setNewsLink] = useState()
  const [newsTitle, setNewsTitle] = useState()
  const [showModal, setShowModal] = useState(false)

  const getNews = async (choice) => {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const query = `https://www.back.tsf.konecton.com/news-tsf/${choice}`

      const response = await fetch(query, options)
      if (!response.ok) {
        throw new Error('HTTP ERROR !!!!')
      }

      const data = await response.json()
      setNews(data)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      window.location.href = '/error'
    }
  }

  const postNews = () => {
    const body = {
      title: newsTitle,
      image: newsImage,
      content: newsContent,
      link: newsLink,
      date_news: newsDate
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    };

    fetch(`https://www.back.tsf.konecton.com/news-tsf/add/`, options)
      .then(response => response.json())
      .then(data => {
        Swal.fire({
          icon: 'success',
          title: 'News bien ajoutée',
          timer: 1500
        });
        window.location.reload()
      })
      .catch(error => console.error(error));
  }

  const handleFilters = (choice) => {
    const newsFiltered = [...news]

    if (choice === 'new') {
      newsFiltered.sort((a, b) => new Date(b.posted_date) - new Date(a.posted_date))
    } else if (choice === 'old') {
      newsFiltered.sort((a, b) => new Date(a.posted_date) - new Date(b.posted_date))
    }
    setNews(newsFiltered)
  }

  const handlePostNews = () => {
    if (newsLink && newsContent && newsTitle) {
      postNews()
    }
  }

  useEffect(() => {
    getNews('all')
  }, [])

  useEffect(() => {
    if (news) {
      handleFilters(filterChoice)
    }
  }, [filterChoice])

  const addNewsModal = () => {
    return (
      <div className='admin-news-tsf-modal-container'>
        <div className='admin-news-tsf-modal-layout' onClick={() => setShowModal(false)} />
        <div className='admin-news-tsf-modal-inputs-container'>
          <button className='admin-news-tsf-modal-close-button' onClick={() => setShowModal(false)}><IoIosClose /></button>
          <h5>Ajouter une news</h5>
          <div className='admin-news-tsf-modal-input-container' onClick={(e) => e.preventDefault()}>
            <label htmlFor='addNewsTitle'>Titre :</label>
            <input id='addNewsTitle' type='text' placeholder='ex: Assemblée générale' onChange={(e) => setNewsTitle(e.target.value)} />
          </div>
          <div className='admin-news-tsf-modal-input-container' onClick={(e) => e.preventDefault()}>
            <label htmlFor='addNewsImage'>Lien image :</label>
            <input id='addNewsImage' type='text' placeholder='ex: http://lien.com' onChange={(e) => setNewsImage(e.target.value)} />
            <button className='admin-news-tsf-modal-help-button' onClick={() => setHelpModal(!helpModal)}>Besoin d'aide ?</button>
          </div>
          {helpModal &&
            <>
              <ol className='admin-news-tsf-modal-help-text'>
                <li>
                  Aller sur le post LinkedIn
                </li>
                <li>
                  Faites un clic droit sur l'image
                </li>
                <li>
                  Copier l'adresse de l'image
                </li>
                <li>
                  Coller l'adresse dans le champ ci-dessus <FaRegHandPointUp />
                </li>
              </ol>
              <img className='admin-news-tsf-modal-help-image' src={help_img} alt='aide ajout image' />
            </>
          }
          <div className='admin-news-tsf-modal-input-container' onClick={(e) => e.preventDefault()}>
            <label htmlFor='addNewsDate'>Date de l'actualité :</label>
            <input id='addNewsDate' type='date' placeholder='ex: 12/05/2024' onChange={(e) => setNewsDate(e.target.value)} />
          </div>
          <div className='admin-news-tsf-modal-input-container'>
            <label htmlFor='addNewsContent'>Contenu :</label>
            <a className='admin-news-tsf-modal-link' href='https://wordtohtml.net' target='_blank' rel='noreferrer'>Créer le contenu stylisé</a>
            <textarea id='addNewsContent' className='admin-news-tsf-modal-textarea' placeholder='ex: Le code stylisé à coller ici' onChange={(e) => setNewsContent(e.target.value)}></textarea>
          </div>
          <div className='admin-news-tsf-modal-input-container'>
            <label htmlFor='addNews'>Lien du post LinkedIn :</label>
            <input id='addNews' type='text' placeholder='ex: http://lien.com' onChange={(e) => setNewsLink(e.target.value)} />
          </div>
          <button className='admin-news-tsf-modal-button tsf-text tsf-button-shadow' onClick={() => handlePostNews()}>Poster la news</button>
        </div>
      </div>
    )
  }

  return (
    <div className={`AdminNews ${menuOpen ? 'menuOpen' : 'menuClose'}`}>
      <AdminLateralSidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {isLoading ?
        <div className='tsf-loader-container'>
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{ backgroundColor: 'black' }}
            wrapperClass=""
          />
        </div>
        :
        <>
          {showModal && addNewsModal()}
          <div className='admin-requests-container'>
            <h1>News de TSF</h1>
            <div className='admin-requests-controllers-container'>
              <button className={`admin-requests-controllers`} onClick={() => setShowModal(true)}><FaPlus /> Ajouter une news</button>
              <select className='admin-requests-controllers-select' onChange={(e) => setFilterChoice(e.target.value)}>
                <option defaultChecked>Filtre dates :</option>
                <option value='new'>Du plus récent</option>
                <option value='old'>Du plus ancien</option>
              </select>
            </div>
            <div className='admin-news-lists'>
              {news && news.map((n) => (
                <NewsTSFCard {...n} isAdmin={true} key={n.id} />
              ))}
            </div>
          </div>
        </>
      }
    </div>
  )
}
