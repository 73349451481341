import React from 'react'

// assets
import construction from '../assets/images/decorations/Construction worker-rafiki.png'

// styles
import '../styles/WorkInProgress.css'

export default function WorkInProgress() {
  // <a href="https://storyset.com/work">Work illustrations by Storyset</a>
  return (
    <div className='WorkInProgress tsf-content-container'>
      <div className='work-in-progress-container'>
        <h1>Page en cours de construction</h1>
        <div className='work-in-progress-image'>
          <img src={construction} alt='work in progress' />
        </div>
      </div>
    </div>
  )
}
