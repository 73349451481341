export const MembersList = [
  {
    id: 1,
    lastname: 'Testouri',
    firstname: 'Linda',
    cv: '/cv',
    occupation: 'Fondatrice et Présidente de TSF',
    image: require('../assets/images/members-profile/linda_profile_3.png'),
    linkedin: 'https://www.linkedin.com/in/linda-testouri-3a3a3a1b/',
    shortDescription: `Psychologue clinicienne spécialisée en psycho-criminologie et victimologie, praticienne en psychotrauma.`,
    longDescription: `Facilitatrice en justice restaurative. Chercheure et chargée d’enseignement à l’université de Tours.`
  },
  {
    id: 2,
    lastname: 'Rabelle',
    firstname: 'Caroline',
    cv: null,
    occupation: 'Secrétaire générale et Chargée de communication de TSF',
    image: require('../assets/images/members-profile/caroline-rabelle-profil-500px.png'),
    linkedin: 'https://www.linkedin.com/in/caroline-rabelle-3a3a3a1b/',
    shortDescription: `Accompagnante en développement personnel et collectif certifiée. Elle travaille au sein des entreprises et des collectifs à faire des individualités, des collectifs participatifs, singuliers et puissants.`,
    longDescription: `À titre individuel, elle accompagne des personnes à se réaliser. Elle a intégré l\'association TSF dans le cadre de la formation, la sensibilisation et l\'accompagnement particulier des personnes en vulnérabilités.`
  },
  {
    id: 3,
    lastname: 'Langot',
    firstname: 'Sylvain',
    cv: null,
    occupation: 'Développeur du site de TSF',
    image: require('../assets/images/members-profile/sylvain-langot-profil.jpeg'),
    linkedin: 'https://www.linkedin.com/in/sylvain-langot-3a3a3a1b/',
    shortDescription: ``,
    longDescription: `Développeur FullStack Web et Mobile`
  },
]

export const BenevolentsList = [
  {
    id: 1,
    lastname: 'Rodriguez',
    firstname: 'Antonia',
    cv: null,
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/antonia-500px.png'),
    shortDescription: `Je suis chilienne, j'ai grandi au Chili et à mes 12 ans je suis venue en France. Ayant vu de près les difficultés sur place dans un pays qui ne garantit pas les mêmes droits aux enfants`,
    longDescription: ` Ayant vu de près les difficultés sur place dans un pays qui ne garantit pas les mêmes droits aux enfants, mais aussi les défis dans l'immigration, je fais de cela m'a force pour pouvoir aider et surtout j'étudie pour un jour changer les choses. Actuellement, j'ai 18 ans, militante, élue lycéenne, bénévole.. j'utilise mon expérience afin de mener au mieux mes missions au sein de ce merveilleux projet TSF. 
    Ma principale motivation est le bien être des enfants et jeunes, c'est ma mission au sein de cette association. Mais aussi des adultes venant d'une génération où la santé mentale n'est pas toujours accessible. "nous agirons à notre échelle, mais nous agirons".`,
  },
  {
    id: 2,
    lastname: 'Watson',
    firstname: 'Emma',
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/avatar-woman.png'),
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue',
    longDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue. Fusce pellentesque tincidunt nulla, sed feugiat nisl eleifend at. Nullam at eros non diam bibendum tristique. Maecenas mi velit, aliquam in eros id, lacinia tincidunt sem. Ut non est erat. Mauris porta elit at sapien sodales, sit amet semper dolor malesuada. Suspendisse non leo vel ex volutpat pretium. Nullam volutpat in tellus id auctor. Proin non porta ante. Mauris congue iaculis erat, id tincidunt urna pellentesque quis. Nullam bibendum ante a efficitur tempor. Nam posuere interdum diam dictum finibus.`
  },
  {
    id: 3,
    lastname: 'Grint',
    firstname: 'Ruppert',
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/avatar-man.png'),
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue',
    longDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue. Fusce pellentesque tincidunt nulla, sed feugiat nisl eleifend at. Nullam at eros non diam bibendum tristique. Maecenas mi velit, aliquam in eros id, lacinia tincidunt sem. Ut non est erat. Mauris porta elit at sapien sodales, sit amet semper dolor malesuada. Suspendisse non leo vel ex volutpat pretium. Nullam volutpat in tellus id auctor. Proin non porta ante. Mauris congue iaculis erat, id tincidunt urna pellentesque quis. Nullam bibendum ante a efficitur tempor. Nam posuere interdum diam dictum finibus.`
  },
  {
    id: 4,
    lastname: 'Radcliffe',
    firstname: 'Daniel',
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/avatar-man.png'),
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue',
    longDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue. Fusce pellentesque tincidunt nulla, sed feugiat nisl eleifend at. Nullam at eros non diam bibendum tristique. Maecenas mi velit, aliquam in eros id, lacinia tincidunt sem. Ut non est erat. Mauris porta elit at sapien sodales, sit amet semper dolor malesuada. Suspendisse non leo vel ex volutpat pretium. Nullam volutpat in tellus id auctor. Proin non porta ante. Mauris congue iaculis erat, id tincidunt urna pellentesque quis. Nullam bibendum ante a efficitur tempor. Nam posuere interdum diam dictum finibus.`
  },
  {
    id: 5,
    lastname: 'Wright',
    firstname: 'Bonnie',
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/avatar-woman.png'),
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue',
    longDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue. Fusce pellentesque tincidunt nulla, sed feugiat nisl eleifend at. Nullam at eros non diam bibendum tristique. Maecenas mi velit, aliquam in eros id, lacinia tincidunt sem. Ut non est erat. Mauris porta elit at sapien sodales, sit amet semper dolor malesuada. Suspendisse non leo vel ex volutpat pretium. Nullam volutpat in tellus id auctor. Proin non porta ante. Mauris congue iaculis erat, id tincidunt urna pellentesque quis. Nullam bibendum ante a efficitur tempor. Nam posuere interdum diam dictum finibus.`
  },
  {
    id: 6,
    lastname: 'Felton',
    firstname: 'Tom',
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/avatar-man.png'),
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue',
    longDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue. Fusce pellentesque tincidunt nulla, sed feugiat nisl eleifend at. Nullam at eros non diam bibendum tristique. Maecenas mi velit, aliquam in eros id, lacinia tincidunt sem. Ut non est erat. Mauris porta elit at sapien sodales, sit amet semper dolor malesuada. Suspendisse non leo vel ex volutpat pretium. Nullam volutpat in tellus id auctor. Proin non porta ante. Mauris congue iaculis erat, id tincidunt urna pellentesque quis. Nullam bibendum ante a efficitur tempor. Nam posuere interdum diam dictum finibus.`
  },
  {
    id: 7,
    lastname: 'Bonham Carter',
    firstname: 'Helena',
    occupation: 'Bénévole',
    image: require('../assets/images/members-profile/avatar-woman.png'),
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue',
    longDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce neque ante, accumsan eu erat nec, finibus interdum augue. Fusce pellentesque tincidunt nulla, sed feugiat nisl eleifend at. Nullam at eros non diam bibendum tristique. Maecenas mi velit, aliquam in eros id, lacinia tincidunt sem. Ut non est erat. Mauris porta elit at sapien sodales, sit amet semper dolor malesuada. Suspendisse non leo vel ex volutpat pretium. Nullam volutpat in tellus id auctor. Proin non porta ante. Mauris congue iaculis erat, id tincidunt urna pellentesque quis. Nullam bibendum ante a efficitur tempor. Nam posuere interdum diam dictum finibus.`
  },
]