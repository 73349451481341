import React, { useEffect } from 'react'

// components
import Footer from '../components/Footer'
import LateralSidebar from '../components/LateralSidebar'
import Menu from '../components/Menu'
import WorkInProgress from '../components/WorkInProgress'

// styles
import '../styles/Legal.css'

// utils
import { isMobileScreen } from '../utils/Sizes'

export default function Legal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Legal tsf-global-screen'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='legal-container tsf-content-container'>
        {/* <WorkInProgress /> */}
        <h1>Mention légales</h1>
        <section>
          <article>
            <h2 className='legal-container-subtitle'>ÉDITEUR DU SITE INTERNET</h2>
            <p>Therapy Sans Frontières</p>
            <p>Association loi 1901, déclarée en Préfecture, sous le numéro XXXXXXXXX<br />
              Publication au Journal Officiel du 3 novembre 1989</p>
            <p>N° SIRET : XXXXXXXXX</p>
            <p>
              Siège social<br />
              6 Rue Paul Langevin, 37130 Cinq-Mars-la-Pile<br />
              Tél. 06 36 08 42 25<br />
              Email : tsf.direction@gmail.com<br /><br />
              Rédaction: Linda Testouri - Fondatrice de Therapy Sans Frontières
            </p>
          </article>
          <article>
            <h2 className='legal-container-subtitle'>HEBERGEUR</h2>
            <p>XXXXXXXXXX
              {/* OVH

2 rue Kellermann – 59100 Roubaix – France
Site internet : www.ovhcloud.com */}
            </p>
          </article>
          <article>
            <h2 className='legal-container-subtitle'>CONCEPTEUR</h2>
            <p>
              Le site internet de l’association Therapy Sans Frontières a été réalisé par Sylvain Langot.<br />
              61 boulevard Saint Exupéry, 13140 Miramas - France<br />
              Tél. : 06 70 69 65 61
              Site internet : www.site.fr
            </p>
          </article>
          <article>
            <h2 className='legal-container-subtitle'>CRÉDIT PHOTOS</h2>
            <p>Image par <a href='https://pixabay.com/fr/users/drkiranhania-22033/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=82272'>Kiran Hania</a> de <a href='https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=82272'>Pixabay</a></p>
            <p><a href='https://www.freepik.com/free-photo/young-traveler_3709459.htm#page=2&query=travel%20woman%20forest&position=30&from_view=search&track=ais&uuid=a6859713-9a91-4414-80b6-e1d6178d8f6e'>Image by jcomp</a> on Freepik</p>
            <p>Image de <a href='https://fr.freepik.com/photos-gratuite/gens-se-tenant-main-se-bouchent_20825619.htm#page=6&query=solidarit%C3%A9&position=19&from_view=search&track=sph&uuid=46621f85-5db5-43db-9544-d19d7fd5afb3'>Freepik</a></p>
            <p><a href='https://fr.freepik.com/photos-gratuite/concept-reussite-commerciale-vue-dessus-table-bois-mains-protegeant-figures-bois-personnes_9426580.htm#query=help&position=8&from_view=search&track=sph&uuid=1578dcc6-d46e-4286-98d2-dd57a01e8c2a'>Image de 8photo</a> sur Freepik</p>
            <p>Image par <a href='https://pixabay.com/fr/users/pcdazero-2615/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2901017'>Gianni Crestani</a> de <a href='https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2901017'>Pixabay</a></p>
            <p><a href='https://www.pexels.com/fr-fr/photo/globe-sur-sable-346696/'>Photo de Porapak Apichodilok</a></p>
            <p>Image par <a href='https://pixabay.com/fr/users/stokpic-692575/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=600497'>stokpic</a> de <a href='https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=600497'>Pixabay</a></p>
            <p>Image par <a href='https://pixabay.com/fr/users/schaeffler-395701/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=429784'>schaeffler</a> de <a href='https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=429784'>Pixabay</a></p>
            <p><a href='https://www.pexels.com/fr-fr/photo/chemin-entre-les-arbres-a-feuilles-vertes-109391/'>Photo de mali maeder</a></p>
            <p><a href='https://storyset.com/online'>Online illustrations by Storyset</a></p>
            <p><a href='https://storyset.com/event'>Event illustrations by Storyset</a></p>
            <p><a href='https://storyset.com/business'>Business illustrations by Storyset</a></p>
            <p><a href='https://storyset.com/people'>People illustrations by Storyset</a></p>
            <p><a href='https://storyset.com/work'>Work illustrations by Storyset</a></p>
            <p><a href='https://www.freepng.fr/png-pvc238/'>Image de profil homme par Speecegiapelm</a></p>
            <p><a href='https://www.freepng.fr/png-r6ek11/'>Image de profil femme par Kerrikesi</a></p>
          </article>
        </section>

        CRÉDIT PHOTOS
        Adobe Stock, Fotolia
      </div>
      <Footer />
    </div>
  )
}
