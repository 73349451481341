import { useContext, useEffect, useState } from 'react'

// context
import { Context } from '../context/Context'

// packages
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { RotatingLines } from 'react-loader-spinner'

export const PrivateRoute = ({ element }) => {
  const { dispatch } = useContext(Context)
  const userStorage = localStorage.getItem('admin_session')
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const testAuth = async () => {
      const options = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${userStorage?.replace(/"/g, '')}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        await fetch(`https://www.back.tsf.konecton.com/auth/protected`, options)
          .then(result => {
            if (!result.ok) {
              dispatch({ type: 'LOGIN_FAILURE' })
              localStorage.removeItem('admin_session')
              setLoader(false)
              navigate('/')
            }
            setLoader(false)
          })
      } catch (error) {
        console.error(error)
      }
    }
    testAuth()
  }, [dispatch, navigate])

  return (
    <>
      {loader ?
        <div className='tsf-loader-container'>
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{ backgroundColor: 'black' }}
            wrapperClass=""
          />
        </div>
        :
        userStorage ? <Outlet /> : <Navigate to='/' />}
    </>
  )
}
