export const BecomeParnerList = [
  {
    id: 1,
    title: 'Partenaire engagé',
    priceTitle: '200€/mois',
    price: 200,
    percentage: 0.6,
    bonus: [
      'Bénéficiez d’une <b>réduction fiscale à hauteur de 60%</b> de mon don',
      'Soyez <b>présent sur les supports de communication</b> de Therapy Sans frontières',
      'Ayez un <b>accès facilité</b> à nos services',
    ],
    link: 'https://www.helloasso.com/associations/therapy-sans-frontieres/adhesions/bulletin-d-adhesion-tsf',
    color: 'primary',
    actionBtn: 's\'engager'
  },
  {
    id: 2,
    title: 'Grand mécène',
    priceTitle: '850€/mois',
    price: 850,
    percentage: 0.6,
    bonus: [
      'Bénéficiez d’une <b>réduction fiscale à hauteur de 60%</b> de mon don',
      'Soyez <b>présent sur les supports de communication</b> de Therapy Sans frontières',
      'Ayez un <b>accès facilité</b> à nos services',
      'Affectez vos dons <b>aux actions de votre choix</b>',
      'Recevez un <b>intervenant directement</b> dans votre entreprise',
      'Vous êtes <b>convié aux évènements réunissant les membres</b> de l\'association'
    ],
    link: 'https://www.helloasso.com/associations/therapy-sans-frontieres/adhesions/bulletin-d-adhesion-tsf',
    color: 'forth',
    actionBtn: 'devenir mécène'
  }
]

export const BecomePartnerBonusList = [
  {
    id: 1,
    image: require('../assets/images/decorations/Tax-pana.png'),
    title: 'Déduisez vos impôts',
    message: 'Un don vous permet obtenir une déduction d\'impôts de 60%',
    alt: 'déduction d\'impôts',
  },
  {
    id: 2,
    image: require('../assets/images/decorations/brand-creation-amico.png'),
    title: 'Valorisez votre image',
    message: 'Renforcer votre image de marque en nous soutenant',
    alt: 'valorisez votre image',
  },
  {
    id: 3,
    image: require('../assets/images/decorations/SEO-pana.png'),
    title: 'Soyez visible',
    message: 'Mise en avant sur les différents supports de TSF',
    alt: 'soyez visible',
  },
  {
    id: 4,
    image: require('../assets/images/decorations/Connected-world-rafiki-third.png'),
    title: 'Rejoignez un réseau d\'entreprises',
    message: 'Vous rejoindrez notre réseau de partenaires et de mécènes',
    alt: 'réseau d\'entreprises',
  },
]