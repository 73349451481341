import React from 'react'

export default function JoinUsCivic({
  comment,
  endDate,
  handleDates,
  handleTextActions,
  mission,
  startDate
}) {
  return (
    <div className='JoinUsCivic'>
      <h3 className='join-us-component-title'>Service Civique</h3>
      <div className='join-us-content-container'>
        <div className='join-us-dates-container'>
          <div className='join-us-date-container'>
            <label htmlFor='startDate'>Date de début :</label>
            <input type='date' id='startDate' onChange={(e) => handleDates('startDate', e.target.value)} value={startDate} />
          </div>
          <div className='join-us-date-container'>
            <label htmlFor='endDate'>Date de fin :</label>
            <input type='date' id='endDate' onChange={(e) => handleDates('endDate', e.target.value)} value={endDate} />
          </div>
        </div>
        <div className='join-us-divider' />
        <div className='join-us-mission-container'>
          <p className='join-us-commitments-title'>Quelle mission service civique souhaites tu réaliser dans notre association ? :</p>
          <div className='join-us-buttons-container'>
            <button className={`join-us-button ${mission === 'Volontariat de solidarité internationale (VSI)' && 'join-us-button-active'}`} onClick={() => handleTextActions('mission', 'Volontariat de solidarité internationale (VSI)')}>
              Volontariat de solidarité internationale (VSI)
            </button>
            <button className={`join-us-button ${mission === 'Corps européen de solidarité (CES)' && 'join-us-button-active'}`} onClick={() => handleTextActions('mission', 'Corps européen de solidarité (CES)')}>
              Corps européen de solidarité (CES)
            </button>
            <button className={`join-us-button ${mission === 'Engagement de service civique' && 'join-us-button-active'}`} onClick={() => handleTextActions('mission', 'Engagement de service civique')}>
              Engagement de service civique
            </button>
          </div>
        </div>
        <div className='join-us-textarea-container'>
          <label htmlFor='comment'>Commentaire :</label>
          <textarea id='comment' onChange={(e) => handleTextActions('comment', e.target.value)} placeholder='Dites-nous en plus ...' value={comment}></textarea>
        </div>
      </div>
    </div>
  )
}
