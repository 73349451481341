export const DonationsList = [
  {
    id: 1,
    title: 'POUR LES PARTICULIERS 66%',
    text1: 'de vos dons sont déductibles de vos impôts sur le revenu.',
    text2: 'Dans la limite de 20% de vos revenus.',
    image: require('../assets/images/decorations/Ok-bro-2.png'),
    altImage: 'individual donation',
    btnTitle: 'un particulier',
    percentage: 0.66,
    color: 'primary'
  },
  {
    id: 2,
    title: 'POUR LES ENTREPRISES 60%',
    text1: 'de vos dons sont déductibles.',
    text2: 'Dans la limite de 5‰ du chiffre d\'affaires. L\'excédent étant reportable sur les cinq années suivantes.',
    image: require('../assets/images/decorations/Company-rafiki.png'),
    altImage: 'company donation',
    btnTitle: 'une entreprise',
    percentage: 0.6,
    color: 'forth'
  }
]

export const SupportUsCardList = [
  {
    id: 1,
    title: 'Faire un don',
    text: 'Faites un don pour nous soutenir et nous permettre de développer l\'association et aider le plus de monde possible',
    link: '/support-us/donations',
    image: require('../assets/images/decorations/gif/charity.gif'),
    altImage: 'faire un don',
    btnTitle: 'Faire un don',
  },
  {
    id: 2,
    title: 'Legs et donations',
    text: 'Vous pouvez nous supportez en nous faisans des legs et des donations',
    link: '/support-us/legacy-and-donations',
    image: require('../assets/images/decorations/gif/gift.gif'),
    altImage: 'legs et donation',
    btnTitle: 'Faire un leg',
  },
  {
    id: 3,
    title: 'Devenir adhérent',
    text: 'Devenez adhérent et soutenez nous dans nos projets',
    link: '/support-us/become-home',
    image: require('../assets/images/decorations/gif/team.gif'),
    altImage: 'devenez adhérent',
    btnTitle: 'Devenez adhérent',
  },
  {
    id: 4,
    title: 'Rejoindre l\'équipe',
    text: 'Envie de rejoindre l\'équipe TSF ? Venez nous rejoindre pour faire de grandes choses ensemble',
    link: '/support-us/join-us',
    image: require('../assets/images/decorations/gif/join-team.gif'),
    altImage: 'nous rejoindre',
    btnTitle: 'Nous rejoindre',
  },
  {
    id: 5,
    title: 'Proposer un projet',
    text: 'Vous avez un projet qui peut nous intéresser ? Venez nous en faire part',
    link: '/support-us/submit-projects',
    image: require('../assets/images/decorations/gif/project.gif'),
    altImage: 'proposer un projet',
    btnTitle: 'Proposer un projet',
  },
]

export const SupportUsDonationsCardList = [
  {
    id: 1,
    subtitle1: 'PARTICULIERS',
    subtitle2: '66%',
    text: 'de vos dons sont déductibles.',
    color: 'primary'
  },
  {
    id: 2,
    subtitle1: 'ENTREPRISES',
    subtitle2: '60%',
    text: 'de vos dons sont déductibles.',
    color: 'forth'
  }
]