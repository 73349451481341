import React, { useContext, useEffect, useState } from 'react'

// assets
import logo_tsf_app from '../../assets/images/TSF/TSF_menu_app_2.png'
import logo_tsf_pro from '../../assets/images/TSF/TSF_Texte_Logo_Pro_transparent.png'
import logo_tsf_benevolent from '../../assets/images/TSF/TSF-Texte-Logo-Benevole.png'

// context
import { Context } from '../../context/Context'

// icons
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";

// packages
import { Link } from 'react-router-dom';

// styles
import '../../styles/app/AppForm.css'
import '../../styles/app/globalApp.css'

export default function AppHome() {
  const { isShortcut, handleShortcut } = useContext(Context);
  const [showShortcutModal, setShowShortcutModal] = useState(false);

  const handleCloseAndHideShortcut = () => {
    setShowShortcutModal(false)
    handleShortcut('refused')
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isShortcut === 'empty') {
      setShowShortcutModal(true)
      const isInWebAppiOS = (window.navigator.standalone === true);
      const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
      if (isInWebAppiOS || isInWebAppChrome) {
        handleShortcut('accepted')
      }
    }
  }, [isShortcut])

  const ShortcutModal = () => {
    return (
      <div className='shortcut-modal'>
        <div className='shortcut-modal-content'>
          <button className='shortcut-modal-close' onClick={() => setShowShortcutModal(false)}><IoClose /></button>
          <h2>Raccourcis TSF</h2>
          <p><i>Le saviez-vous :</i></p>
          <p>Vous pouvez ajouter l'application TSF sur l'écran d'accueil de votre appareil.</p>
          <button className='shortcut-modal-ok tsf-text' onClick={() => setShowShortcutModal(false)}>OK</button>
          <button className='shortcut-modal-hide' onClick={() => handleCloseAndHideShortcut()}>Ne plus afficher</button>
        </div>
      </div>
    )
  }

  return (
    <div className='AppHome'>
      {showShortcutModal && <ShortcutModal />}
      <div className='app-header'>
        <Link className='app-header-return-btn' to='/'>
          <IoMdArrowRoundBack />
          retour au site
        </Link>
        <h2>
          <img src={logo_tsf_app} alt='Thérapy Sans Frontières' width={100} style={{ marginRight: 20 }} />
        </h2>
      </div>
      <div className='app-container tsf-content-container'>
        <Link to='/App/form/benevolent' className='app-button-container app-button-benevolent tsf-button-shadow'>
          <h2>Application</h2>
          <img src={logo_tsf_benevolent} className='app-button' alt='tsf benevolent app' />
        </Link>
        <Link to='/App/form/pro' className='app-button-container app-button-pro tsf-button-shadow'>
          <h2>Application</h2>
          <img src={logo_tsf_pro} className='app-button' alt='tsf pro app' />
        </Link>
      </div>
    </div>
  )
}
