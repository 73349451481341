import React, { useEffect } from 'react'

// assets
import project from '../../assets/images/decorations/gif/project-chat.gif'

// components
import Footer from '../../components/Footer'
import LateralSidebar from '../../components/LateralSidebar'
import Menu from '../../components/Menu'
import WorkInProgress from '../../components/WorkInProgress'
import { Link } from 'react-router-dom'

// styles
import '../../styles/ourActions/Artivistes.css'

// utils
import { isMobileScreen } from '../../utils/Sizes'

export default function Artivistes() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Artivistes tsf-global-screen tsf-global-screen-wb'>
      <Menu />
      {!isMobileScreen() && <LateralSidebar />}
      <div className='artivistes-container tsf-content-container'>
        {/* <WorkInProgress /> */}
        <h1>Artivistes</h1>
        <div className='artivistes-content'>
          <div className='artivistes-image-container'>
            <img src={project} alt='project' />
          </div>
          <p>Artiviste est une section qui soutient et accompagne les projets artistiques et militants à taille humaine.</p>
          <p>Cela contribue à la transformation d’expérience vécue (de domination, d’exploitation, d’aliénation sociale, de discrimination), en savoir partageable par une action collective à travers l’art. </p>
          <Link to='/contact' className='artivistes-button'><button className='tsf-button tsf-button-primary tsf-button-shadow tsf-text'>Parlez nous de votre projet</button></Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}
